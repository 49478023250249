import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { OmniLocation } from 'src/_shared/_old/schemas/typings'

export interface LatLng {
	lat: number
	lng: number
}

export interface Place {
	place_id: string
	structured_formatting: {
		main_text: string
	}
	description: string
	latitude: number
	longitude: number
}

export interface LocationWithCoordinates extends Place {
	latitude: number
	longitude: number
}

export const MAP_TYPES = ['roadmap', 'hybrid', 'terrain']

export const latLng2Point = (latLng: Coordinates, map: google.maps.Map, zoom: number) => {
	// if map is not initialized yet, projection is undefined
	const projection = map.getProjection()
	const bounds = map.getBounds()
	if (!projection || !bounds) {
		return null
	}
	const topRight = projection.fromLatLngToPoint(bounds.getNorthEast()) ?? { x: 0, y: 0 }
	const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest()) ?? { x: 0, y: 0 }
	const scale = Math.pow(2, zoom)
	const worldPoint = projection.fromLatLngToPoint(latLng) ?? { x: 0, y: 0 }
	return new google.maps.Point(
		(worldPoint.x - bottomLeft.x) * scale,
		(worldPoint.y - topRight.y) * scale
	)
}

export const point2LatLng = (
	point: { x: number; y: number },
	map: google.maps.Map,
	zoom: number
) => {
	const projection = map.getProjection()
	const bounds = map.getBounds()
	if (!projection || !bounds) {
		return null
	}
	const topRight = projection.fromLatLngToPoint(bounds.getNorthEast()) ?? { x: 0, y: 0 }
	const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest()) ?? { x: 0, y: 0 }
	const scale = Math.pow(2, zoom)
	const bottomLeftX: number = bottomLeft.x
	const topRightY: number = topRight.y
	const worldPoint = new google.maps.Point(
		point.x / scale + bottomLeftX,
		point.y / scale + topRightY
	)
	return projection.fromPointToLatLng(worldPoint)
}

// transition timeout is the delay in time to pan to the next mini step
// steps is the number of partitions between the current latlng to the intended latlng
export const miniPanTo = (
	panQueue: LatLng[],
	panPath: LatLng[],
	latLangPair: LatLng,
	map: google.maps.Map,
	steps: number,
	transitionTimeout: number
) => {
	if (panPath.length > 0) {
		panQueue.push(latLangPair)
	} else {
		// dummy value if this is the first function call
		panPath.push({ lng: 0, lat: 0 })
		const { lat: newLat, lng: newLng } = latLangPair
		const center = map.getCenter()
		const curLat = center?.lat() ?? 0
		const curLng = center?.lng() ?? 0

		// deltaLatPerStep is the latitude delta would be moved with every animation frame
		// deltaLngPerStep is the longitude delta would be moved with every animation frame
		const deltaLatPerStep = (newLat - curLat) / steps
		const deltaLngPerStep = (newLng - curLng) / steps

		for (let i = 0; i < steps; i++) {
			panPath.push({
				lat: curLat + deltaLatPerStep * i,
				lng: curLng + deltaLngPerStep * i
			})
		}
		panPath.push(latLangPair)
		panPath.shift()
		setTimeout(() => {
			doPan(panPath, panQueue, map, steps, transitionTimeout)
		}, transitionTimeout)
	}
}

const doPan = (
	panPath: LatLng[],
	panQueue: LatLng[],
	map: google.maps.Map,
	steps: number,
	transitionTimeout: number
) => {
	const next = panPath.shift()
	if (next) {
		map.panTo({ lat: next.lat, lng: next.lng })
		setTimeout(() => {
			doPan(panPath, panQueue, map, steps, transitionTimeout)
		}, transitionTimeout)
	} else {
		// we are finished with this pan - check if there are any queued locations to pan to
		const queued = panQueue.shift()
		if (queued) {
			miniPanTo(panQueue, panPath, queued, map, steps, transitionTimeout)
		}
	}
}

export const formatDataToGeoJsonPoints = (
	locations: OmniLocation[]
): GeoJSON.Feature<GeoJSON.Point>[] => {
	return locations.map((location) => ({
		type: 'Feature',
		geometry: {
			type: 'Point',
			coordinates: [
				parseFloat(location.coordinates.longitude),
				parseFloat(location.coordinates.latitude)
			]
		},
		properties: {
			cluster: false,
			location
		}
	}))
}

// this function is to allow users to search for nearby POIs
export const search = async (
	map: google.maps.Map,
	maps: typeof google.maps,
	debouncedKeyword: string
): Promise<Place[]> => {
	if (!debouncedKeyword) {
		return []
	}

	const autoCompleteService = new maps.places.AutocompleteService()

	const request = {
		input: debouncedKeyword,
		fields: ['geometry', 'place_id'],
		componentRestrictions: { country: 'sg' }
	}

	try {
		const results = (await autoCompleteService.getPlacePredictions(request)).predictions
		const placesService = new maps.places.PlacesService(map)

		const placeDetailsRequests = results.map(async (result) => {
			return await new Promise<Place | null>((resolve) => {
				const detailsRequest = {
					placeId: result.place_id,
					fields: ['geometry']
				}
				placesService.getDetails(detailsRequest, (place, status) => {
					if (status === maps.places.PlacesServiceStatus.OK && place) {
						const locationWithCoordinates: LocationWithCoordinates = {
							...result,
							latitude: place.geometry?.location?.lat() ?? 0,
							longitude: place.geometry?.location?.lng() ?? 0
						}
						resolve(locationWithCoordinates)
					} else {
						resolve(null)
					}
				})
			})
		})
		const locationWithCoordinates = await Promise.all(placeDetailsRequests)
		const validLocations = locationWithCoordinates.filter(Boolean) as Place[]
		return validLocations
	} catch (error) {
		return []
	}
}
