import { useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import { PASSWORD_REQUIREMENTS_TEXT } from 'src/_shared/constants/account'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserResetPasswordMutation } from 'src/_shared/mutations/auth'
import { ROOT_USER_QUERY_KEY, UserQueryKey } from 'src/_shared/queries/user'
import { arePasswordsMatching, isPasswordComplexityMet } from 'src/_shared/utils/account'
import { delay } from 'src/_shared/utils/time'

import InputPassword from '../../_shared/components/InputPassword'
import { FormFieldKey } from './enums'
import { FormField } from './types'

const AccountProfileChangePasswordScreen = (): JSX.Element => {
	const [formValues, setFormValues] = useState<Record<FormFieldKey, string>>({
		[FormFieldKey.OldPassword]: '',
		[FormFieldKey.NewPassword]: '',
		[FormFieldKey.ConfirmNewPassword]: ''
	})

	const { accessToken } = useAuthContext()

	const queryClient = useQueryClient()

	const navigate = useRouterNavigate()

	const {
		error: userResetPasswordError,
		isError: isUserResetPasswordError,
		isPending: isUserResetPasswordPending,
		isSuccess: isUserResetPasswordSuccess,
		mutateAsync: resetUserPasssord,
		reset: resetUserResetPasswordMutation
	} = useUserResetPasswordMutation()

	// Note: Pending more granular error messages from CPO BE.
	const formFields = useMemo((): FormField[] => {
		const newPassword = formValues[FormFieldKey.NewPassword]

		const confirmNewPassword = formValues[FormFieldKey.ConfirmNewPassword]

		const oldPasswordFormField: FormField = {
			key: FormFieldKey.OldPassword,
			label: 'Old Password',
			errorMessage: ((): string | undefined => {
				// Handle 4XX/5XX status errors thrown with messages.
				if (isUserResetPasswordError) {
					const errorMessage = userResetPasswordError.response?.data.message
					if (errorMessage?.includes('provided password')) {
						return errorMessage
					}
				}
			})()
		}

		const newPasswordFormField: FormField = {
			key: FormFieldKey.NewPassword,
			label: 'New Password',
			errorMessage: ((): string | undefined => {
				if (newPassword.length > 0 && !isPasswordComplexityMet(newPassword)) {
					return 'Your password does not meet the complexity requirements'
				}
			})()
		}

		const confirmNewPasswordFormField: FormField = {
			key: FormFieldKey.ConfirmNewPassword,
			label: 'Confirm New Password',
			errorMessage: ((): string | undefined => {
				if (!arePasswordsMatching(newPassword, confirmNewPassword)) {
					return 'Your password does not match'
				}
				// Handle 4XX/5XX status errors thrown with messages.
				if (isUserResetPasswordError && !oldPasswordFormField.errorMessage) {
					const errorMessage = userResetPasswordError.response?.data.message
					return errorMessage ? errorMessage : 'Oops! Something went wrong'
				}
			})()
		}

		return [oldPasswordFormField, newPasswordFormField, confirmNewPasswordFormField]
	}, [formValues, isUserResetPasswordError, userResetPasswordError])

	/**
	 * Checks for missing form values or an error present in any field.
	 */
	const isFormInvalid = useMemo((): boolean => {
		return (
			Object.values(formValues).some((value): boolean => !value) ||
			formFields.some((field): boolean => !!field.errorMessage)
		)
	}, [formFields, formValues])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement>): void => {
				const value = event.currentTarget.value.replace(/\s/g, '')
				setFormValues((values) => ({
					...values,
					[key]: value
				}))
				if (isUserResetPasswordError) {
					resetUserResetPasswordMutation()
				}
			},
		[isUserResetPasswordError, resetUserResetPasswordMutation]
	)

	const handleSaveNewPasswordClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (!isFormInvalid) {
				const { oldPassword, newPassword } = formValues
				void resetUserPasssord(
					{ oldPassword, newPassword },
					{
						onSuccess: (): void => {
							const handleSuccess = async (): Promise<void> => {
								await delay(2500) // Delay briefly show to the user whether or not their new password was saved.
								await queryClient.invalidateQueries({
									queryKey: [ROOT_USER_QUERY_KEY, UserQueryKey.UserInfo, { accessToken }]
								})
								resetUserResetPasswordMutation()
								navigate(ScreenRoutePath.AccountProfile, { replace: true })
							}
							void handleSuccess()
						}
					}
				)
			}
		},
		[
			accessToken,
			formValues,
			isFormInvalid,
			queryClient,
			navigate,
			resetUserPasssord,
			resetUserResetPasswordMutation
		]
	)

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			topBarProps={{
				centerRender: <h1>Authentication</h1>
			}}
			hideBottomBar
		>
			<form className="flex flex-grow flex-col" onSubmit={handleSaveNewPasswordClick}>
				<div className="flex-grow">
					<div className="mb-8 flex flex-col">
						<h1 className="mb-5 text-typography-primary">Create New Password</h1>
						<div className="flex flex-col space-y-5">
							{formFields.map(({ key, label, errorMessage }, index): JSX.Element => {
								return (
									<InputPassword
										key={index}
										autoComplete="off"
										id={key}
										name={key}
										label={label}
										value={formValues[key]}
										disabled={isUserResetPasswordPending}
										error={!!errorMessage}
										description={
											errorMessage && <p className="first-letter:uppercase">{errorMessage}</p>
										}
										onChange={handleFormChange(key)}
									/>
								)
							})}
						</div>
						<p className="caption-3-medium mt-1 text-typography-secondary">
							{PASSWORD_REQUIREMENTS_TEXT}
						</p>
					</div>
				</div>
				<div className="flex flex-col space-y-2">
					<Button
						className="w-full"
						disabled={isFormInvalid || isUserResetPasswordPending}
						loading={isUserResetPasswordPending}
					>
						Save
					</Button>
				</div>
			</form>
			{/* Success Modal */}
			<Modal open={isUserResetPasswordSuccess}>
				<ModalCard className="flex flex-col items-center !pb-10 !pt-8">
					<CheckCircleFilledIcon className="mb-4 h-12 w-12 text-success-400" />
					<h1 className="mb-2 text-center">Success</h1>
					<p className="body-2-normal text-center">
						Your new password has been saved successfully.
					</p>
				</ModalCard>
			</Modal>
		</ScreenContainer>
	)
}

export default AccountProfileChangePasswordScreen
