import { memo } from 'react'
import { useRouteError } from 'react-router-dom'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'

const ErrorElement = (): JSX.Element => {
	const error = useRouteError()
	return (
		<ScreenContainer hideBottomBar>
			<Notice
				type="error"
				header="Oops! Something Went Wrong"
				description={error ? String(error) : 'An error occurred while rendering the page.'}
			/>
		</ScreenContainer>
	)
}

const MemoisedErrorElement = memo(ErrorElement)

export default MemoisedErrorElement
