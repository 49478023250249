import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { PropsWithChildren, ReactNode } from 'react'
import Rollbar from 'rollbar'
import { APP_VERSION, ROLLBAR_ACCESS_TOKEN } from 'src/_shared/constants/env'

const ROLLBAR = new Rollbar({
	accessToken: ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	enabled: false,
	environment: location.host,
	payload: {
		client: {
			javascript: {
				code_version: APP_VERSION,
				source_map_enabled: true
			}
		}
	}
})

/**
 * Configured Rollbar with logging information.
 */
const RollbarWrapper = ({ children }: PropsWithChildren): ReactNode => {
	return (
		<RollbarProvider instance={ROLLBAR}>
			<ErrorBoundary level="warn">{children}</ErrorBoundary>
		</RollbarProvider>
	)
}

export default RollbarWrapper
