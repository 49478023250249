import { PowerType, SubscriptionType } from 'src/_shared/_old/enums'
import { OmniEVSE } from 'src/_shared/_old/schemas/evse'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { Filter, OmniLocation } from 'src/_shared/_old/schemas/typings'

import { getConnectorPowerType } from './charging'
import { distanceBetween } from './math'

export const hasConnectors = (location: OmniLocation) => {
	return location.evses.some((evse) => evse.connectors.length)
}

// to get all CPOs from locations
export const filterLocationCpos = (
	location: OmniLocation,
	filters: Filter,
	currentCoords: Coordinates
): boolean => {
	if (!hasConnectors(location)) {
		return false
	}

	if (currentCoords.lat && currentCoords.lng) {
		const locationCoords: Coordinates = {
			lat: parseFloat(location.coordinates.latitude),
			lng: parseFloat(location.coordinates.longitude)
		}
		if (
			distanceBetween(locationCoords, currentCoords) > filters.distHighest ||
			distanceBetween(locationCoords, currentCoords) < filters.distLowest
		) {
			return false
		}
	}
	return true
}

export const filterByCpos = (location: OmniLocation, cpoList: string[]) => {
	return cpoList.includes(location.operator.name)
}

export const filterBySubscriptionStatus = (
	location: OmniLocation,
	subscribedCpoEntities: string[]
) => {
	return subscribedCpoEntities.includes(location.entity_code)
}

export const filterEvseByPowerType = (evse: OmniEVSE, powerTypeFilter: PowerType | null) => {
	if (powerTypeFilter) {
		return evse.connectors.some((connector) => getConnectorPowerType(connector) === powerTypeFilter)
	}
	return true
}

export const filterEvse = (evses: OmniEVSE[], powerTypeFilter: PowerType | null) => {
	const allEvses = evses.filter((evse) => evse.connectors.length > 0)
	const filteredEvsesByPower = allEvses.filter((evse) => {
		return filterEvseByPowerType(evse, powerTypeFilter)
	})
	return filteredEvsesByPower
}

export const filterLocations = (
	location: OmniLocation,
	filters: Filter,
	currentCoords: Coordinates,
	subscribedCpoEntities: string[]
): boolean => {
	const filterByPowerType: OmniEVSE[] = location.evses.filter((evse) =>
		filterEvseByPowerType(evse, filters.powerTypeFilter)
	)

	if (!filterByPowerType.length || !hasConnectors(location)) {
		return false
	}

	if (currentCoords.lat && currentCoords.lng) {
		const locationCoords: Coordinates = {
			lat: parseFloat(location.coordinates.latitude),
			lng: parseFloat(location.coordinates.longitude)
		}
		if (
			distanceBetween(locationCoords, currentCoords) > filters.distHighest ||
			distanceBetween(locationCoords, currentCoords) < filters.distLowest
		) {
			return false
		}
	}
	if (filters.cpoFilter.length > 0) {
		if (!filterByCpos(location, filters.cpoFilter)) {
			return false
		}
	}

	if (filters.isSubscribedFilter) {
		if (filters.isSubscribedFilter === SubscriptionType.SUBSCRIBED) {
			return filterBySubscriptionStatus(location, subscribedCpoEntities)
		}
		return !filterBySubscriptionStatus(location, subscribedCpoEntities)
	}

	return true
}
