import { CarparkRate } from 'src/_shared/_old/schemas/typings'

interface P {
	carparkRates?: CarparkRate | null
	postalCode?: string
	name?: string
	address?: string
	showModal?: boolean
	closeModal?: () => void
}

export default function CarparkRatesModal({
	carparkRates,
	postalCode,
	address,
	name,
	showModal,
	closeModal
}: P) {
	return (
		showModal && (
			<div
				onClick={closeModal}
				className="absolute z-[60] flex h-full min-h-screen w-full items-center justify-center bg-black bg-opacity-40 px-6"
			>
				<div
					style={{ maxHeight: window.innerHeight - 60 }}
					className="flex w-full flex-col items-center overflow-scroll rounded-2xl bg-white px-6 py-7 text-xs"
				>
					<div className="flex w-full flex-row items-center justify-start space-x-2">
						<div className="flex h-5 w-5 items-center justify-center rounded-md bg-primary-800">
							<p className="text-sm font-bold text-white">P</p>
						</div>
						<p className="text-sm font-semibold text-typography-primary">Car Park Rates</p>
					</div>
					<div
						className={`flex w-full flex-col items-start justify-center space-y-2 border-primary-400/30 py-5 ${
							carparkRates && 'border-b'
						}`}
					>
						<p className="text-sm font-semibold">{name}</p>

						{address && postalCode && (
							<p className="text-typography-tertiary">
								{address}, S({postalCode})
							</p>
						)}

						{!carparkRates && (
							<p className="text-typography-tertiary">
								Car park rates for this location coming soon!
							</p>
						)}
					</div>

					{carparkRates && (
						<>
							<div className="flex w-full flex-row items-center justify-center space-y-2 border-b border-primary-400/30 py-5">
								<p className="w-1/3 text-xs font-semibold">MON-FRI Before 5/6 PM</p>
								<p className="w-2/3 pl-7 text-typography-tertiary">{carparkRates.beforeEvening}</p>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-y-2 border-b border-primary-400/30 py-5">
								<p className="w-1/3 text-xs font-semibold">MON-FRI After 5/6 PM</p>
								<p className="w-2/3 pl-7 text-typography-tertiary">{carparkRates.afterEvening}</p>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-y-2 border-b border-primary-400/30 py-5">
								<p className="w-1/3 text-xs font-semibold">SAT</p>
								<p className="w-2/3 pl-7 text-typography-tertiary">{carparkRates.saturday}</p>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-y-2 py-5">
								<p className="w-1/3 text-xs font-semibold">SUN / PUBLIC HOLIDAYS</p>
								<p className="w-2/3 pl-7 text-typography-tertiary">{carparkRates.sundayAndPH}</p>
							</div>
						</>
					)}
					<button
						className="mt-5 w-full rounded-lg bg-primary-800 px-10 py-3"
						onClick={() => closeModal?.()}
					>
						<p className="body-2-semibold text-center text-white">Close</p>
					</button>
				</div>
			</div>
		)
	)
}
