function Charge({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 13 19" fill="none">
			<path
				d="M11.6741 8.38003H8.97038V2.08003C8.97038 0.610032 8.17413 0.312532 7.20288 1.41503L6.50288 2.21128L0.579129 8.94878C-0.234621 9.86753 0.106629 10.62 1.33163 10.62H4.03538V16.92C4.03538 18.39 4.83163 18.6875 5.80288 17.585L6.50288 16.7888L12.4266 10.0513C13.2404 9.13253 12.8991 8.38003 11.6741 8.38003Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default Charge
