import { SVGProps, memo } from 'react'

const HourglassIcon = ({
	fill = 'none',
	viewBox = '0 0 91 144',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<g clipPath="url(#clip0_2749_32525)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.9088 4.15875H85.0912C88.0655 4.15875 90.5 6.59321 90.5 9.56755C90.5 12.5594 88.0655 14.9938 85.0912 14.9938H5.9088C2.93446 14.9913 0.5 12.5569 0.5 9.56505C0.5 6.59071 2.93446 4.15625 5.9088 4.15625V4.15875Z"
					fill="#98BEBB"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M82.2695 14.9922C82.4395 16.3619 82.5245 17.7641 82.5245 19.1838C82.5245 44.3657 51.5939 52.7663 48.9419 67.8754C48.3346 71.2897 48.3346 72.2695 48.9419 75.7012C51.5964 90.8103 82.5245 99.211 82.5245 124.393C82.5245 125.813 82.4395 127.215 82.2695 128.584H8.73088C8.56092 127.215 8.46094 125.813 8.46094 124.393C8.46094 99.211 39.4066 90.8103 42.061 75.7012C42.6683 72.2695 42.6683 71.2897 42.061 67.8754C39.4066 52.7663 8.46094 44.3657 8.46094 19.1838C8.46094 17.7641 8.56092 16.3619 8.73088 14.9922H82.267H82.2695Z"
					fill="#F3F3F3"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M76.8597 120.86C76.9446 122.06 76.7922 123.242 76.6397 124.409H14.3585C14.2061 123.242 14.1211 122.06 14.1211 120.86C14.1211 107.408 39.7254 112.832 45.4741 78.082C49.0233 117.091 75.39 99.1074 76.8597 120.86Z"
					fill="#FCC87F"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M75.0191 32.4326C70.2526 45.0923 53.6063 52.0033 49.0073 60.7763C46.5904 64.7155 46.0505 68.3996 45.4081 71.324C43.3111 59.999 40.8267 57.5321 37.075 54.3378C31.1438 49.3014 24.1954 44.4675 20.1562 37.8764C22.8107 35.6619 27.4246 34.6971 30.7689 34.6971C37.8673 34.6971 51.2019 40.6458 54.0262 37.1816C61.1421 28.511 67.6657 30.7605 75.0166 32.4326H75.0191Z"
					fill="#FCC87F"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.6069 4.15908H80.3914C81.5236 4.15908 82.4709 3.22928 82.4709 2.07954C82.4709 0.947289 81.5236 0 80.3914 0H10.6069C9.45714 0 8.52734 0.947289 8.52734 2.07954C8.52734 3.22928 9.45714 4.15908 10.6069 4.15908Z"
					fill="#769593"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.9088 139.217H85.0912C88.0655 139.217 90.5 136.783 90.5 133.794C90.5 130.819 88.0655 128.385 85.0912 128.385H5.9088C2.93446 128.385 0.5 130.819 0.5 133.794C0.5 136.785 2.93446 139.217 5.9088 139.217Z"
					fill="#98BEBB"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.6069 139.217H80.3914C81.5236 139.217 82.4709 140.147 82.4709 141.296C82.4709 142.429 81.5236 143.376 80.3914 143.376H10.6069C9.45714 143.376 8.52734 142.429 8.52734 141.296C8.52734 140.147 9.45714 139.217 10.6069 139.217Z"
					fill="#769593"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2749_32525">
					<rect width="90" height="143.373" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	)
}

const MemoisedHourglassIcon = memo(HourglassIcon)

export default MemoisedHourglassIcon
