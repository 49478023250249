import { useEffect, useState } from 'react'

const useLocationPermissions = (): { hasLocationPermissions: boolean } => {
	const [hasLocationPermissions, setHasLocationPermissions] = useState<boolean>(false)

	// Prompt handler for user geolocation permission.
	useEffect(() => {
		// Check for `permissions` as some mobile browsers (i.e. Safari on iOS pre-16) may not have it.
		// Read More: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/permissions#browser_compatibility
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		if (navigator.permissions) {
			const requestGeolocationPermission = async (): Promise<void> => {
				const permissionStatus = await navigator.permissions.query({
					name: 'geolocation'
				})
				if (permissionStatus.state === 'granted') {
					setHasLocationPermissions(true)
				}
			}

			void requestGeolocationPermission()
		}
	}, [])

	return { hasLocationPermissions }
}

export default useLocationPermissions
