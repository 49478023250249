import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { CPO_BACKEND_URL } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { OmniLocation } from '../types/omni/location'

interface LocationsByQrIdentifierMutationParams {
	qrIdentifier: string
	/**
	 * Filter based on the provided entity codes.
	 */
	entityCodes?: string[]
	/**
	 * Filter based on billing profile UID.
	 */
	billingProfileUid?: string
	/**
	 * Filter based on publish status,
	 */
	publish?: boolean
	limit?: number
	offset?: number
}

interface LocationsByQrIdentifierData {
	locations: OmniLocation[]
	message: string
}

export const useLocationsByQrIdentifierMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<LocationsByQrIdentifierData>,
			AxiosError<{ message: string }>,
			LocationsByQrIdentifierMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async (
			params: LocationsByQrIdentifierMutationParams
		): Promise<AxiosResponse<LocationsByQrIdentifierData>> => {
			try {
				const {
					qrIdentifier: qr_identifier,
					entityCodes: entity_codes,
					billingProfileUid: billing_profile_uid,
					...rest
				} = params
				const response = await axios.post<LocationsByQrIdentifierData>(
					`${CPO_BACKEND_URL}/v3/locations/qr-search`,
					{ ...rest, qr_identifier, entity_codes, billing_profile_uid },
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}
