import { DetailedHTMLProps, HTMLAttributes, ReactNode, memo, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath, useRouterLocation, useRouterNavigate } from 'src/App/router/hooks'
import { BACK_LINK, STRAPI_URL } from 'src/_shared/constants/env'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { classNames } from 'src/_shared/utils/elements'

import ArrowLeftIcon from '../../_icons/ArrowLeftIcon'
import TopBarButton from './TopBarButton'

export type TopBarProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'children' | 'ref'
> & {
	/**
	 * Overrides the display in the center `div` of the `TopBar`.
	 */
	centerRender?: ReactNode
	/**
	 * Overrides the display in the left `div` of the `TopBar`.
	 */
	leftRender?: ReactNode
	/**
	 * Overrides the display in the right `div` of the `TopBar`.
	 */
	rightRender?: ReactNode
}

const TopBar = ({
	className,
	centerRender,
	leftRender,
	rightRender,
	style,
	...props
}: TopBarProps): JSX.Element => {
	const location = useRouterLocation()

	const navigate = useRouterNavigate()

	const { brandData } = useStrapiContext()

	const { logoDark, logoLight, theme } = brandData?.attributes ?? {}

	const { logo, ...topBarStyle } = theme?.components?.topBar ?? {}

	const { mode: logoTheme = 'light', ...logoStyle } = logo ?? {}

	const logoUrl = useMemo(() => {
		if (STRAPI_URL) {
			if (logoTheme === 'dark') {
				return STRAPI_URL + logoDark?.data?.attributes.url
			}
			return STRAPI_URL + logoLight?.data?.attributes.url
		}
		return null
	}, [logoDark, logoLight, logoTheme])

	/**
	 * Returns the user to the previous page found in history.
	 * If there is no history, this defaults to the `BACK_LINK`
	 * or the Map Screen.
	 */
	const handleReturn = useCallback((): void => {
		const hasPreviousHistory = location.key !== 'default'
		if (hasPreviousHistory) {
			navigate(-1)
		} else if (BACK_LINK) {
			window.location.href = BACK_LINK
		} else {
			navigate(ScreenRoutePath.Map)
		}
	}, [location.key, navigate])

	/**
	 * Default Render: Return Button
	 */
	const leftElement = useMemo(
		(): ReactNode =>
			leftRender ?? (
				<TopBarButton onClick={handleReturn}>
					<ArrowLeftIcon className="h-4" />
				</TopBarButton>
			),
		[leftRender, handleReturn]
	)

	/**
	 * Default Render: Brand Logo Image
	 */
	const centerElement = useMemo(
		(): ReactNode =>
			centerRender ??
			(logoUrl ? (
				<Link to={ScreenRoutePath.Map}>
					<img className="max-h-10" src={logoUrl} style={logoStyle} />
				</Link>
			) : null),
		[centerRender, logoUrl, logoStyle]
	)

	/**
	 * Default Render: Empty
	 */
	const rightElement = useMemo((): ReactNode => rightRender ?? null, [rightRender])

	return (
		<div
			className={classNames('sticky top-0 z-10 flex h-10 shadow-md', className)}
			style={{
				...style,
				...topBarStyle
			}}
			{...props}
		>
			<div className="flex h-full w-12 items-center justify-center">{leftElement}</div>
			<div className="flex h-full flex-grow items-center justify-center">{centerElement}</div>
			<div className="flex h-full w-12 items-center justify-center">{rightElement}</div>
		</div>
	)
}

const MemoisedTopBar = memo(TopBar)

export default MemoisedTopBar
