import { TOPBAR_HEIGHT } from 'src/_shared/_old/enums'

const HeaderBar = () => {
	return (
		<>
			<div
				style={{
					marginTop: TOPBAR_HEIGHT
				}}
				className={`z-20 flex w-full items-center justify-between border-t px-6 py-1.5 shadow-md`}
			>
				<span className="body-2-semibold text-typography-primary">Charging Points</span>
				<span className="body-2-normal text-typography-secondary">Available / Total</span>
			</div>
		</>
	)
}

export default HeaderBar
