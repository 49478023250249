import { RxCrossCircled } from 'react-icons/rx'
import LocationPin from 'src/_shared/_old/assets/svgs/LocationPin'

interface DefaultMapPopupProps {
	locationName?: string | null
	onClickSearch?: () => void
	onClickClose?: () => void
}

export default function DefaultMapPopup(props: DefaultMapPopupProps) {
	const { locationName, onClickSearch, onClickClose } = props
	return (
		<div
			className="flex w-full flex-col items-center justify-center pt-0.5"
			style={{
				paddingBottom: 'calc(64px + env(safe-area-inset-bottom))'
			}}
		>
			{/* search locations */}
			{/* we use w-11/12 so the border does not go across the whole component */}
			<div className="mb-2 flex w-11/12 items-center border-b border-primary-400/30 px-5">
				<button
					className="flex w-full flex-row items-center justify-start space-x-4 py-3"
					onClick={() => {
						onClickSearch?.()
					}}
				>
					<LocationPin className="h-5 w-auto text-typography-secondary" />
					<p className={`text-left text-sm ${locationName ? '' : 'text-typography-secondary'}`}>
						{locationName ?? 'Find charging location here'}
					</p>
				</button>
				<button
					onClick={() => {
						onClickClose?.()
					}}
				>
					<RxCrossCircled className="ml-2 h-5 w-auto text-typography-secondary" />
				</button>
			</div>
		</div>
	)
}
