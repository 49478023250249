import { ReactNode, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

export interface LabelValueListItemProps {
	containerClassName?: string | null
	label: string
	labelClassName?: string | null
	value: ReactNode
	valueClassName?: string | null
}

const LabelValueListItem = ({
	containerClassName,
	label,
	labelClassName,
	value,
	valueClassName
}: LabelValueListItemProps) => {
	return (
		<div className={classNames('mb-4 flex items-center justify-between', containerClassName)}>
			<div>
				<span className={classNames('body-2-normal text-typography-tertiary', labelClassName)}>
					{label}
				</span>
			</div>
			<div className="text-right">
				<span className={classNames('body-2-semibold text-typography-primary', valueClassName)}>
					{value}
				</span>
			</div>
		</div>
	)
}

const MemoisedLabelValueListItem = memo(LabelValueListItem)

export default MemoisedLabelValueListItem
