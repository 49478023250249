import { useCallback, useSyncExternalStore } from 'react'

const useStorageItem = (
	key: string,
	storage: Storage
): [string | null, (value: string | null) => void] => {
	const value = useSyncExternalStore(
		(callback: () => void): (() => void) => {
			window.addEventListener('storage', callback)
			return (): void => {
				window.removeEventListener('storage', callback)
			}
		},
		(): string | null => {
			return storage.getItem(key)
		}
	)

	const setItem = useCallback(
		(newValue: string | null): void => {
			if (newValue !== null && newValue !== value) {
				storage.setItem(key, newValue)
				console.debug(`[useStorageItem] Updated "${key}":`, { oldValue: value, newValue })
				window.dispatchEvent(new StorageEvent('storage', { key, oldValue: value, newValue }))
			} else if (newValue === null && value !== null) {
				storage.removeItem(key)
				console.debug(`[useStorageItem] Removed "${key}":`, { oldValue: value, newValue })
				window.dispatchEvent(new StorageEvent('storage', { key, oldValue: value, newValue }))
			}
		},
		[key, storage, value]
	)

	return [value, setItem]
}

/**
 * Stores, retrieves, and synchronizes the specified data from `localStorage`.
 * @param {string} key The key to access the item inside of `localStorage`.
 * @returns {[string | null, (value: string | null) => void]} The key-value and the function to modify that key-value.
 * @see https://react.dev/reference/react/useSyncExternalStore
 */
export const useLocalStorageItem = (
	key: string
): [string | null, (value: string | null) => void] => {
	return useStorageItem(key, localStorage)
}

/**
 * Stores, retrieves, and synchronizes the specified data from `sessionStorage`.
 * @param {string} key The key to access the item inside of `sessionStorage`.
 * @returns {[string | null, (value: string | null) => void]}  The key-value and the function to modify that key-value.
 * @see https://react.dev/reference/react/useSyncExternalStore
 */
export const useSessionStorageItem = (
	key: string
): [string | null, (value: string | null) => void] => {
	return useStorageItem(key, sessionStorage)
}
