import { SVGProps, memo } from 'react'

const QuestionMarkCircledIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 19 19',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M6.95375 6.875C7.15947 6.29021 7.56551 5.79709 8.09996 5.48299C8.63441 5.16889 9.26278 5.05407 9.87378 5.15887C10.4848 5.26368 11.039 5.58133 11.4382 6.05559C11.8374 6.52984 12.0559 7.13008 12.055 7.75C12.055 9.5 9.43 10.375 9.43 10.375M9.5 13.875H9.50875M18.25 9.5C18.25 14.3325 14.3325 18.25 9.5 18.25C4.66751 18.25 0.75 14.3325 0.75 9.5C0.75 4.66751 4.66751 0.75 9.5 0.75C14.3325 0.75 18.25 4.66751 18.25 9.5Z"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedQuestionMarkCircledIcon = memo(QuestionMarkCircledIcon)

export default MemoisedQuestionMarkCircledIcon
