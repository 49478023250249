import { memo, ReactNode } from 'react'

import LabelValueListItem, { LabelValueListItemProps } from './LabelValueListItem'

interface LabelValueListProps {
	className?: string
	labelValueList: LabelValueListItemProps[]
	title: ReactNode
}

const LabelValueList = ({ className, title, labelValueList }: LabelValueListProps): JSX.Element => {
	return (
		<div className={className}>
			<div className="mb-4">
				<span className="body-3-semibold text-typography-primary">{title}</span>
			</div>
			{labelValueList.map(
				(labelValueListItemProps, index): JSX.Element => (
					<LabelValueListItem key={index} {...labelValueListItemProps} />
				)
			)}
		</div>
	)
}

const MemoisedLabelValueList = memo(LabelValueList)

export default MemoisedLabelValueList
