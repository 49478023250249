import TelegramIcon from 'src/_shared/components/_icons/TelegramIcon'
import WhatsAppIcon from 'src/_shared/components/_icons/WhatsAppIcon'
import { CONTACT_US_METHOD } from 'src/_shared/constants/env'

export const SEARCH_INPUT_DEBOUNCE = 125

// Note: Best to get these values in Strapi in the future so that re-deployment does not have to be done.
export const CONTACT_US_BUTTON_PREFIX_ICON = ((): JSX.Element | null => {
	const iconType = CONTACT_US_METHOD
	switch (iconType) {
		case 'telegram':
			return <TelegramIcon className="mr-2 w-5" />
		case 'whatsapp':
			return <WhatsAppIcon className="mr-2 w-5" />
		default:
			return null
	}
})()

export const CONTACT_US_TEXT = (() => {
	switch (CONTACT_US_METHOD) {
		case 'telegram':
			return 'Telegram'
		case 'whatsapp':
			return 'WhatsApp'
		default:
			return null
	}
})()
