import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import AccountFilledIcon from 'src/_shared/components/_icons/AccountFilledIcon'
import AccountIcon from 'src/_shared/components/_icons/AccountIcon'
import ClockFilledIcon from 'src/_shared/components/_icons/ClockFilledIcon'
import ClockIcon from 'src/_shared/components/_icons/ClockIcon'
import LocationPinFilledIcon from 'src/_shared/components/_icons/LocationPinFilledIcon'
import LocationPinIcon from 'src/_shared/components/_icons/LocationPinIcon'

interface P {
	className?: string
	selectedRoute?: string
}

export default function Bottombar(props: P) {
	const { className, selectedRoute } = props

	const navigate = useRouterNavigate()

	const tabs = [
		{
			route: 'Map',
			display: 'Maps',
			icon:
				selectedRoute === 'Map' ? (
					<LocationPinFilledIcon className="h-5 w-auto text-primary-800" />
				) : (
					<LocationPinIcon className="h-5 w-auto text-typography-secondary" />
				),
			navigate: ScreenRoutePath.Root
		},
		{
			route: 'History',
			display: 'Charging History',
			icon:
				selectedRoute === 'History' ? (
					<ClockFilledIcon className="h-5 w-auto text-typography-primary" />
				) : (
					<ClockIcon className="h-5 w-auto text-typography-tertiary" />
				),
			navigate: ScreenRoutePath.History
		},
		{
			route: 'Account',
			display: 'Account',
			icon:
				selectedRoute === 'Account' ? (
					<AccountFilledIcon className="h-5 w-auto text-typography-primary" />
				) : (
					<AccountIcon className="h-5 w-auto text-typography-tertiary" />
				),
			navigate: ScreenRoutePath.Account
		}
	]

	return (
		<div
			className="fixed bottom-0 z-20 flex w-full items-center justify-center bg-white"
			style={{
				paddingBottom: 'calc(env(safe-area-inset-bottom))'
			}}
		>
			<div
				className={`grid h-12 w-full max-w-112 grid-cols-${tabs.length} items-center bg-white ${className}`}
			>
				{tabs.map((tab, idx) => (
					<button
						key={idx}
						className="flex h-full w-full flex-col items-center justify-center"
						onClick={() => {
							navigate(tab.navigate)
						}}
					>
						{tab.icon}
						<p
							className={`whitespace-nowrap text-xs font-medium ${
								selectedRoute === tab.route ? 'text-typography-primary' : 'text-typography-tertiary'
							}`}
						>
							{tab.display}
						</p>
					</button>
				))}
			</div>
		</div>
	)
}
