import { Elements } from '@stripe/react-stripe-js'
import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { STRIPE_PUBLIC_KEY } from 'src/_shared/constants/env'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { useStripeSetupIntentQuery } from 'src/_shared/queries/payments'
import { classNames } from 'src/_shared/utils/elements'
import isHexColor from 'validator/lib/isHexColor'

import StripeForm from './components/StripeForm'
import { getStripeAppearance } from './utils'

const AccountPaymentMethodsNewScreen = (): JSX.Element => {
	const [stripe, setStripe] = useState<Stripe | null>(null)

	const { brandData } = useStrapiContext()

	const primaryColour = ((): string | null => {
		const colour = brandData?.attributes.theme?.rootColors?.primary
		if (!!colour && isHexColor(colour)) {
			return colour
		}
		return null
	})()

	const { data: stripeSetupIntentData, isLoading: isStripeSetupIntentLoading } =
		useStripeSetupIntentQuery()

	/**
	 * Initialise the `Stripe` object for `StripeElements`.
	 */
	useEffect(() => {
		if (!stripe) {
			const initialiseStripe = async (): Promise<void> => {
				const stripeObject = await loadStripe(STRIPE_PUBLIC_KEY)
				setStripe(stripeObject)
			}
			void initialiseStripe()
		}
	}, [stripe])

	return (
		<ScreenContainer
			contentViewProps={{
				className: classNames(
					'px-5 py-6',
					isStripeSetupIntentLoading ? 'items-center justify-center' : null
				)
			}}
			hideBottomBar
		>
			{isStripeSetupIntentLoading ? (
				<Spinner />
			) : (
				<Elements
					stripe={stripe}
					options={{
						appearance: getStripeAppearance(primaryColour),
						clientSecret: stripeSetupIntentData?.clientSecret
					}}
				>
					<StripeForm />
				</Elements>
			)}
		</ScreenContainer>
	)
}

export default AccountPaymentMethodsNewScreen
