import { ReactNode, useEffect, useState } from 'react'
import { BiChevronDown, BiFilter } from 'react-icons/bi'
import Battery from 'src/_shared/_old/assets/svgs/Battery.svg'
import BatteryWhite from 'src/_shared/_old/assets/svgs/BatteryWhite.svg'
import {
	FILTER_BAR_HEIGHT,
	TOPBAR_HEIGHT,
	PowerType,
	SEARCH_BAR_HEIGHT,
	FilterType
} from 'src/_shared/_old/enums'
import { Filter } from 'src/_shared/_old/schemas/typings'
import { classNames } from 'src/_shared/utils/elements'

interface FiltersProps {
	onSelectFilter: (filterType: PowerType | FilterType) => void
	filters: Filter
	onPowerTypeFilterChange: (selectedPowerType: PowerType) => void
	isListView: boolean
}

interface FilterOptionProps {
	icon: ReactNode
	label: string
	onClick: () => void
	isActive: boolean
}

const FILTER_OPTIONS: {
	icon: JSX.Element
	label: string
	key: PowerType | FilterType
}[] = [
	{
		icon: <BiFilter className="h-4 w-4" />,
		label: 'All',
		key: FilterType.ALL
	},
	{
		icon: <BiChevronDown className="h-4 w-4" />,
		label: 'Charge Point Operators',
		key: FilterType.CPO
	},
	{
		icon: <img className="h-3.5 w-3.5" src={Battery} />,
		label: PowerType.AC,
		key: PowerType.AC
	},
	{
		icon: <img className="h-3.5 w-3.5" src={Battery} />,
		label: PowerType.DC,
		key: PowerType.DC
	}
]

const FilterOption = ({ icon, label, onClick, isActive }: FilterOptionProps) => {
	const activeIcon =
		[PowerType.AC, PowerType.DC].includes(label as PowerType) && isActive ? (
			<img className="h-3.5 w-3.5" src={BatteryWhite} />
		) : (
			icon
		)
	return (
		<div
			className={`flex cursor-pointer flex-row items-center justify-center space-x-1 rounded-3xl bg-opacity-100 p-2 drop-shadow-md ${
				isActive ? 'bg-primary-800 text-white' : 'bg-white'
			}`}
			onClick={onClick}
		>
			{activeIcon}
			<p className="text-xs">{label}</p>
		</div>
	)
}

const Filters = ({
	onSelectFilter,
	filters,
	onPowerTypeFilterChange,
	isListView
}: FiltersProps) => {
	const [activeFilters, setActiveFilters] = useState<Record<string, boolean>>({
		CPO: false,
		AC: false,
		DC: false
	})

	const handleSelectFilterOption = (filterOption: PowerType | FilterType) => {
		if (filterOption === PowerType.AC || filterOption === PowerType.DC) {
			onPowerTypeFilterChange(filterOption)
		}
		onSelectFilter(filterOption)
	}

	useEffect(() => {
		const { powerTypeFilter, cpoFilter } = filters
		setActiveFilters({
			CPO: cpoFilter.length > 0,
			AC: powerTypeFilter === PowerType.AC,
			DC: powerTypeFilter === PowerType.DC
		})
	}, [filters])

	return (
		<div
			className={classNames(
				'absolute top-0 z-50 my-4 flex h-12 w-full items-center justify-evenly bg-transparent',
				isListView ? 'bg-white' : ''
			)}
			style={{
				height: FILTER_BAR_HEIGHT,
				marginTop: isListView ? SEARCH_BAR_HEIGHT + TOPBAR_HEIGHT : TOPBAR_HEIGHT
			}}
		>
			{FILTER_OPTIONS.map((option) => (
				<FilterOption
					{...option}
					key={option.key}
					onClick={() => {
						handleSelectFilterOption(option.key)
					}}
					isActive={activeFilters[option.key]}
				/>
			))}
		</div>
	)
}

export default Filters
