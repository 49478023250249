import { SVGProps, memo } from 'react'

const AccountFilledIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				opacity="0.4"
				d="M 12 24 C 18.627 24 24 18.627 24 12 C 24 5.373 18.627 0 12 0 C 5.373 0 0 5.373 0 12 C 0 18.627 5.373 24 12 24 Z"
				transform="matrix(1, 0, 0, 1, 0, 8.881784197001252e-16)"
			/>
			<path
				d="M 12 5.916 C 9.516 5.916 7.5 7.932 7.5 10.416 C 7.5 12.852 9.408 14.832 11.94 14.904 C 11.976 14.904 12.024 14.904 12.048 14.904 C 12.072 14.904 12.108 14.904 12.132 14.904 C 12.144 14.904 12.156 14.904 12.156 14.904 C 14.58 14.82 16.488 12.852 16.5 10.416 C 16.5 7.932 14.484 5.916 12 5.916 Z"
				transform="matrix(1, 0, 0, 1, 0, 8.881784197001252e-16)"
			/>
			<path
				d="M 20.137 20.82 C 18.001 22.788 15.145 24 12.001 24 C 8.857 24 6.001 22.788 3.865 20.82 C 4.153 19.728 4.933 18.732 6.073 17.964 C 9.349 15.78 14.677 15.78 17.929 17.964 C 19.081 18.732 19.849 19.728 20.137 20.82 Z"
				transform="matrix(1, 0, 0, 1, 0, 8.881784197001252e-16)"
			/>
		</svg>
	)
}

const MemoisedAccountFilledIcon = memo(AccountFilledIcon)

export default MemoisedAccountFilledIcon
