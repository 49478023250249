import dayjs from 'dayjs'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniSession } from 'src/_shared/types/omni'
import { getCurrencyDetails } from 'src/_shared/utils/currency'
import { classNames } from 'src/_shared/utils/elements'
import { getPaymentBreakdown } from 'src/_shared/utils/receipt'

interface HistoryItemProps {
	chargingSession: OmniSession
}

const HistoryItem = ({ chargingSession }: HistoryItemProps) => {
	const { prefix: currencyPrefix } = getCurrencyDetails(chargingSession.currency)

	const paymentBreakdown = getPaymentBreakdown(chargingSession)

	return (
		<div className="flex w-full flex-row items-center justify-between space-x-4 px-7">
			<div className="w-1/12">
				<LightningBoltFilledIcon className="h-6 w-auto pl-1 text-primary-800" />
			</div>
			<div className="flex w-full flex-col items-center justify-center">
				<div className="flex w-full flex-row items-center justify-between">
					<div className="flex flex-row items-center">
						<p className="body-2-medium max-w-64 overflow-hidden truncate whitespace-nowrap text-typography-primary">
							{chargingSession.location_name}
						</p>
					</div>
					<p
						className={classNames(
							'body-2-medium',
							paymentBreakdown.isCostFree
								? 'text-typography-tertiary line-through'
								: 'text-typography-primary'
						)}
					>
						{currencyPrefix}
						{paymentBreakdown.totalCost.toFixed(3)}
					</p>
				</div>
				<div className="flex w-full flex-row items-center justify-between">
					<div className="flex flex-row items-center">
						<p className="caption-3-normal text-typography-tertiary">
							{paymentBreakdown.totalKwh.toFixed(3)} kWh
						</p>
					</div>
					<p className="caption-3-normal text-typography-tertiary">
						{dayjs(chargingSession.start_date_time).format(TIME_FORMAT)}
					</p>
				</div>
			</div>
		</div>
	)
}

export default HistoryItem
