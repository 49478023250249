import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import axios, { AxiosResponse, AxiosError } from 'axios'

import { CPO_BACKEND_URL } from '../constants/env'
import { useAuthContext } from '../hooks/useAuthContext'

interface UserProfileEditMutationParams {
	name: string
	email?: string
	vehiclePlateNumber?: string
	/**
	 * Unused
	 */
	defaultPaymentMethodId?: string
	/**
	 * Implemented inside Busways.
	 */
	vehicleModelId?: string
}

export const useUserProfileEditMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<{ message: string }>,
			AxiosError<{ message: string }>,
			UserProfileEditMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async (params: UserProfileEditMutationParams) => {
			try {
				const response = await axios.put<{ message: string }>(`${CPO_BACKEND_URL}/v2/me`, params, {
					headers: { Authorization: accessToken }
				})
				return response
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}
