import { SVGProps, memo } from 'react'

const WarningIcon = ({
	viewBox = '0 0 95 95',
	fill = 'currentColor',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M86.1333 63.0167L60.8 17.4167C57.3958 11.2813 52.6854 7.91675 47.5 7.91675C42.3146 7.91675 37.6042 11.2813 34.2 17.4167L8.86667 63.0167C5.66042 68.8355 5.30417 74.4167 7.87709 78.8105C10.45 83.2042 15.5167 85.6188 22.1667 85.6188H72.8333C79.4833 85.6188 84.55 83.2042 87.1229 78.8105C89.6958 74.4167 89.3396 68.7959 86.1333 63.0167ZM44.5312 35.6251C44.5312 34.0022 45.8771 32.6563 47.5 32.6563C49.1229 32.6563 50.4688 34.0022 50.4688 35.6251V55.4167C50.4688 57.0397 49.1229 58.3855 47.5 58.3855C45.8771 58.3855 44.5312 57.0397 44.5312 55.4167V35.6251ZM50.3104 70.1022C50.1125 70.2605 49.9146 70.4188 49.7167 70.5772C49.4792 70.7355 49.2417 70.8542 49.0042 70.9334C48.7667 71.0522 48.5292 71.1313 48.2521 71.1709C48.0146 71.2105 47.7375 71.2501 47.5 71.2501C47.2625 71.2501 46.9854 71.2105 46.7083 71.1709C46.4708 71.1313 46.2333 71.0522 45.9958 70.9334C45.7583 70.8542 45.5208 70.7355 45.2833 70.5772C45.0854 70.4188 44.8875 70.2605 44.6896 70.1022C43.9771 69.3501 43.5417 68.3209 43.5417 67.2917C43.5417 66.2626 43.9771 65.2334 44.6896 64.4813C44.8875 64.323 45.0854 64.1647 45.2833 64.0063C45.5208 63.848 45.7583 63.7292 45.9958 63.6501C46.2333 63.5313 46.4708 63.4522 46.7083 63.4126C47.2229 63.2938 47.7771 63.2938 48.2521 63.4126C48.5292 63.4522 48.7667 63.5313 49.0042 63.6501C49.2417 63.7292 49.4792 63.848 49.7167 64.0063C49.9146 64.1647 50.1125 64.323 50.3104 64.4813C51.0229 65.2334 51.4583 66.2626 51.4583 67.2917C51.4583 68.3209 51.0229 69.3501 50.3104 70.1022Z" />
		</svg>
	)
}

const MemoisedWarningIcon = memo(WarningIcon)

export default MemoisedWarningIcon
