import { Appearance } from '@stripe/stripe-js'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

const BASE_FORM_FAMILIES = [
	'ui-sans-serif',
	'system-ui',
	'sans-serif',
	'"Apple Color Emoji"',
	'"Segoe UI Emoji"',
	'"Segoe UI Symbol"',
	'"Noto Color Emoji"'
]

export const getStripeAppearance = (primaryColour: string | null): Appearance => {
	switch (BRAND) {
		case Brand.Evme: {
			const evmeTheme: Appearance = {
				theme: 'stripe',
				rules: {
					'.Error': {
						fontSize: '0.75rem',
						fontWeight: '500'
					},
					'.Input': {
						borderColor: '#9EB1D1',
						borderWidth: '2px',
						boxShadow: 'none',
						fontWeight: '500'
					},
					'.Input:focus': {
						borderColor: '#65748C',
						boxShadow: 'none'
					},
					'.Input--invalid': {
						boxShadow: 'none'
					},
					'.Label': {
						fontSize: '0.9375rem',
						fontWeight: '500',
						marginBottom: '8px'
					},
					'.TermsText': {
						fontSize: '0.8125rem'
					}
				},
				variables: {
					borderRadius: '0.375rem',
					colorText: '#333C4D',
					colorTextSecondary: '#65748C80',
					colorTextPlaceholder: '#C2D0EC',
					colorDanger: '#E55555',
					fontFamily: ['FC Minimal', ...BASE_FORM_FAMILIES].join(', '),
					iconColor: '#65748C'
				}
			}
			return evmeTheme
		}
		default: {
			const defaultTheme: Appearance = {
				theme: 'stripe',
				rules: {
					'.Error': {
						fontSize: '0.75rem',
						fontWeight: '500'
					},
					'.Input': {
						borderColor: '#3232324D',
						borderLeft: '0px',
						borderRight: '0px',
						borderTop: '0px',
						borderWidth: '1px',
						boxShadow: 'none',
						fontSize: '0.9375rem',
						paddingLeft: '4px',
						paddingRight: '4px',
						fontWeight: '500'
					},
					'.Input:focus': {
						borderColor: primaryColour ?? '#32323280',
						boxShadow: 'none'
					},
					'.Input--invalid': {
						boxShadow: 'none'
					},
					'.Label': {
						fontSize: '0.9375rem',
						fontWeight: '500',
						marginBottom: '8px'
					},
					'.TermsText': {
						fontSize: '0.8125rem'
					}
				},
				variables: {
					borderRadius: '0rem',
					colorText: '#323232',
					colorTextSecondary: '#32323280',
					colorTextPlaceholder: '3232324D',
					fontFamily: ['Inter', ...BASE_FORM_FAMILIES].join(', '),
					iconColor: '#32323280'
				}
			}
			return defaultTheme
		}
	}
}
