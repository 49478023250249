import { useCallback, useMemo, MouseEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Spinner from 'src/_shared/components/Spinner'
import TransientFooter from 'src/_shared/components/TransientFooter'
import QrScannerIcon from 'src/_shared/components/_icons/QrScannerIcon'
import {
	BRAND,
	LANDING_APP_STORE_LINK,
	LANDING_FALLBACK_LINK,
	LANDING_GOOGLE_PLAY_LINK,
	STRAPI_URL
} from 'src/_shared/constants/env'
import useCheckInCpos from 'src/_shared/hooks/useCheckInCpos'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

const LandingScreen = (): JSX.Element => {
	const navigate = useRouterNavigate()

	const { cpos, isCpoDataLoading } = useCheckInCpos()

	const { brandData } = useStrapiContext()

	const { logoDark, logoLight, theme } = brandData?.attributes ?? {}

	const { logo } = theme?.components?.topBar ?? {}

	const { mode: logoTheme = 'light' } = logo ?? {}

	const mobileOs = ((): 'android' | 'ios' | null => {
		if (/android/i.test(navigator.userAgent)) {
			return 'android'
		} else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
			return 'ios'
		}
		return null
	})()

	const logoUrl = useMemo((): string | null => {
		if (STRAPI_URL) {
			if (logoTheme === 'dark') {
				return STRAPI_URL + logoDark?.data?.attributes.url
			}
			return STRAPI_URL + logoLight?.data?.attributes.url
		}
		return null
	}, [logoDark, logoLight, logoTheme])

	const openUrl = useMemo((): string => {
		switch (mobileOs) {
			case 'android':
				return LANDING_GOOGLE_PLAY_LINK
			case 'ios':
				return LANDING_APP_STORE_LINK
			// Not Mobile Device
			case null:
				return LANDING_FALLBACK_LINK
		}
	}, [mobileOs])

	const handleScanQrClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>): void => {
			// Prevent default anchor link redirection behaviour.
			event.preventDefault()

			const cpoEntityCodes = cpos.map(({ entityCode }): string => entityCode).join(',')
			navigate({
				pathname: ScreenRoutePath.CheckInQrScanner,
				search: `cpoEntityCodes=${cpoEntityCodes}`
			})
		},
		[cpos, navigate]
	)

	return (
		<div
			className="mx-auto flex min-h-full max-w-112 flex-col"
			style={{
				paddingBottom: 'calc(env(safe-area-inset-bottom))'
			}}
		>
			{isCpoDataLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					{/* Header Spoiler */}
					{openUrl && (
						<div className="flex flex-row items-center justify-between bg-primary-800 px-5 py-3">
							<div>
								<p className="body-3-normal capitalize text-white">{BRAND}</p>
								<p className="caption-3-normal text-white">
									<FormattedMessage
										id="LandingScreen.DescriptionOpenBrandApp"
										defaultMessage="Better on our App!"
									/>
								</p>
							</div>
							<Button
								variant="ghost"
								className="h-8 w-20 !border !border-white"
								linkProps={{
									rel: 'noreferrer',
									target: '_blank',
									to: openUrl
								}}
							>
								<span className="body-1-normal text-white">
									<FormattedMessage
										id="LandingScreen.ButtonTextOpenBrandApp"
										defaultMessage="Open"
									/>
								</span>
							</Button>
						</div>
					)}
					{/* Content */}
					<div className="flex flex-grow flex-col items-center justify-around px-5 py-6">
						{logoUrl && <img className="h-10" src={logoUrl} />}
						<QrScannerIcon className="h-32 w-32 text-typography-primary" />
						<h1 className="text-center text-typography-primary">
							<FormattedMessage
								id="LandingScreen.DescriptionScanQrCode"
								defaultMessage="Please scan the Connector QR code to get started"
							/>
						</h1>
					</div>
					{/* Footer */}
					<div className="flex flex-col space-y-6 px-5 py-6">
						<Button
							className="w-full"
							linkProps={{ className: 'w-full', to: ScreenRoutePath.CheckInQrScanner }}
							onClick={handleScanQrClick}
						>
							<QrScannerIcon className="mr-1 h-5 w-5" />
							<FormattedMessage
								id="LandingScreen.ButtonTextScanQrCode"
								defaultMessage="Scan QR Code"
							/>
						</Button>
						<TransientFooter />
					</div>
				</>
			)}
		</div>
	)
}

export default LandingScreen
