import { DetailedHTMLProps, HTMLAttributes, MouseEvent, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

type ModalCardProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const ModalCard = ({ children, className, ...props }: ModalCardProps): JSX.Element => {
	/**
	 * Prevents click `event` from propagating to the parent `Modal`
	 * so that the `Modal` does not close.
	 */
	const handleModalCardContentClick = (event: MouseEvent<HTMLDivElement>) => {
		props.onClick?.(event)
		event.stopPropagation()
	}

	return (
		<div
			{...props}
			className={classNames('modal-card', className)}
			onClick={handleModalCardContentClick}
		>
			{children}
		</div>
	)
}

const MemoisedModalCard = memo(ModalCard)

export default MemoisedModalCard
