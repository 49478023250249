import { SVGProps, memo } from 'react'

const ErrorFilledIcon = ({
	fill = 'none',
	viewBox = '0 0 81 81',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M76.6001 20.6292L60.3709 4.40001C58.4709 2.50001 54.6709 0.916672 51.9792 0.916672H29.0209C26.3293 0.916672 22.5292 2.50001 20.6292 4.40001L4.40008 20.6292C2.50008 22.5292 0.916748 26.3292 0.916748 29.0208V51.9792C0.916748 54.6708 2.50008 58.4708 4.40008 60.3708L20.6292 76.6C22.5292 78.5 26.3293 80.0833 29.0209 80.0833H51.9792C54.6709 80.0833 58.4709 78.5 60.3709 76.6L76.6001 60.3708C78.5001 58.4708 80.0834 54.6708 80.0834 51.9792V29.0208C80.0834 26.3292 78.5001 22.5292 76.6001 20.6292ZM56.4522 52.2563C57.6001 53.4042 57.6001 55.3042 56.4522 56.4521C55.8584 57.0458 55.1063 57.3229 54.3543 57.3229C53.6022 57.3229 52.8501 57.0458 52.2563 56.4521L40.5001 44.6958L28.7438 56.4521C28.1501 57.0458 27.398 57.3229 26.6459 57.3229C25.8938 57.3229 25.1417 57.0458 24.548 56.4521C23.4001 55.3042 23.4001 53.4042 24.548 52.2563L36.3042 40.5L24.548 28.7438C23.4001 27.5958 23.4001 25.6958 24.548 24.5479C25.6959 23.4 27.5959 23.4 28.7438 24.5479L40.5001 36.3042L52.2563 24.5479C53.4043 23.4 55.3043 23.4 56.4522 24.5479C57.6001 25.6958 57.6001 27.5958 56.4522 28.7438L44.6959 40.5L56.4522 52.2563Z"
				fill="currentColor"
			/>
		</svg>
	)
}

const MemoisedErrorFilledIcon = memo(ErrorFilledIcon)

export default MemoisedErrorFilledIcon
