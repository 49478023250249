import { CSSProperties } from 'react'
import { useIntl } from 'react-intl'
import { OmniCountryCurrencyCode, OmniTariffPriceModifierType } from 'src/_shared/enums/omni'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { getCurrencyDetails } from 'src/_shared/utils/currency'

import Tag from '../Tag'

interface PriceModifierTagProps {
	className?: string
	currencyCode?: OmniCountryCurrencyCode
	priceModifierValue?: number
	priceModifierType?: OmniTariffPriceModifierType
	style?: CSSProperties
}

const PriceModifierTag = ({
	className,
	currencyCode = OmniCountryCurrencyCode.Singapore,
	priceModifierValue = 0,
	priceModifierType,
	style
}: PriceModifierTagProps) => {
	const intl = useIntl()

	const { brandData } = useStrapiContext()

	const formattedTextValue = ((): string => {
		switch (priceModifierType) {
			case OmniTariffPriceModifierType.DiscountPercentage:
				return intl.formatMessage(
					{
						id: 'PriceModifierTag.TagSuffixDiscountPercentage',
						defaultMessage: '{percentage}% Off'
					},
					{
						percentage: priceModifierValue
					}
				)
			case OmniTariffPriceModifierType.DiscountFlat: {
				const { prefix: currencyPrefix } = getCurrencyDetails(currencyCode)
				return intl.formatMessage(
					{
						id: 'PriceModifierTag.TagPrefixDiscountFlat',
						defaultMessage: '-{flatValue}'
					},
					{
						flatValue: `-${currencyPrefix}${priceModifierValue}`
					}
				)
			}
			// Note: Currently there's no business logic for markup.
			case OmniTariffPriceModifierType.MarkupFlat:
			case OmniTariffPriceModifierType.MarkupPercentage:
			default:
				return ''
		}
	})()

	// Do not show `PriceModifierTag` if markup modifiers are applied.
	if (!formattedTextValue) {
		return null
	}
	return (
		<Tag
			className={className}
			value={formattedTextValue}
			style={{ ...style, ...brandData?.attributes.theme?.components?.priceModifierTag }}
		/>
	)
}

export default PriceModifierTag
