import { SVGProps, memo } from 'react'

const InformationCircleFilledIcon = ({
	viewBox = '0 0 24 24',
	fill = 'currentColor',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z" />
		</svg>
	)
}

const MemoisedInformationCircleFilledIcon = memo(InformationCircleFilledIcon)

export default MemoisedInformationCircleFilledIcon
