import { OmniCountryCode, OmniCountryCurrencyCode } from '../enums/omni'

interface CurrencyDetails {
	countryCode?: OmniCountryCode
	prefix: string
	suffix?: string
}

export const getCurrencyDetails = (currencyCode?: OmniCountryCurrencyCode): CurrencyDetails => {
	switch (currencyCode) {
		case OmniCountryCurrencyCode.Malaysia:
			return {
				countryCode: OmniCountryCode.Malaysia,
				prefix: 'RM ' // Trailing whitespace is intended.
			}
		case OmniCountryCurrencyCode.Singapore:
			return { countryCode: OmniCountryCode.Singapore, prefix: '$' }
		case OmniCountryCurrencyCode.Thailand:
			return { countryCode: OmniCountryCode.Thailand, prefix: '฿' }
		default:
			return { prefix: '$' }
	}
}
