import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
	ScreenRoutePath,
	useQueryParams,
	useRouterLocation,
	useRouterNavigate
} from 'src/App/router/hooks'
import Error404Notice from 'src/_shared/components/Error404Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { APP_MODE } from 'src/_shared/constants/env'
import { TRANSIENT_SESSION_ID_KEY } from 'src/_shared/constants/storage'
import { AppMode } from 'src/_shared/enums/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocalStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useSessionQuery, useSessionTransientWithTokenQuery } from 'src/_shared/queries/sessions'

interface SessionScreenRouteParams {
	sessionId: string
}

interface SessionScreenQueryParams {
	transient_token: string
}

const SessionScreen = (): JSX.Element => {
	const { isAuthenticated } = useAuthContext()

	const navigate = useRouterNavigate()

	const { pathname } = useRouterLocation()

	const [, setTransientSessionId] = useLocalStorageItem(TRANSIENT_SESSION_ID_KEY)

	const routeParams = useParams() as unknown as SessionScreenRouteParams

	const [{ transient_token = '' }] = useQueryParams<SessionScreenQueryParams>()

	const isTransient = APP_MODE === AppMode.Transient

	const sessionId = routeParams.sessionId

	const sessionQuery = useSessionQuery(
		{ sessionId },
		{
			enabled: !isTransient
		}
	)

	const sessionTransientWithTokenQuery = useSessionTransientWithTokenQuery(
		{ sessionId, transientToken: transient_token },
		{
			enabled: isTransient
		}
	)

	const { data: session, status: sessionQueryStatus } = isTransient
		? sessionTransientWithTokenQuery
		: sessionQuery

	/**
	 * Default and SSO Modes: If user is not logged in, redirect to the `AccountLoginScreen`.
	 */
	useEffect((): void => {
		if (!isAuthenticated && !isTransient) {
			navigate({
				pathname: ScreenRoutePath.AccountLogin,
				search: `redirect=${pathname}`
			})
		}
	}, [isAuthenticated, isTransient, pathname, navigate])

	/**
	 * Redirect Logic
	 */
	useEffect((): void => {
		if (sessionQueryStatus === 'success' && session !== null) {
			// Redirect to `HistoryDetailScreen` if the session is completed.
			if (
				session.start_date_time &&
				session.end_date_time &&
				dayjs(session.start_date_time).isSameOrBefore(session.end_date_time)
			) {
				if (isTransient) {
					setTransientSessionId(sessionId)
				}
				// Navigate back to Map when user clicks on the back button in `HistoryDetailScreen`.
				// Map screen is not previously in the history stack, hence we push it manually.
				window.history.pushState({}, '', ScreenRoutePath.Map)
				navigate([ScreenRoutePath.HistoryDetail, sessionId])
			}
			// Redirect to `ChargerScreen` if it is an active session.
			else if (
				!session.start_date_time ||
				!session.end_date_time ||
				dayjs(session.start_date_time).isSameOrAfter(session.end_date_time)
			) {
				if (isTransient) {
					setTransientSessionId(sessionId)
				}
				window.history.pushState({}, '', ScreenRoutePath.Map)
				navigate({
					pathname: [
						ScreenRoutePath.Charger,
						session.entity_code ?? '',
						session.location_uid ?? '',
						session.evse_uid ?? '',
						session.connector_uid ?? ''
					],
					search: 'payment_success=true'
				})
			}
		}
	}, [session, sessionId, sessionQueryStatus, isTransient, navigate, setTransientSessionId])

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			hideBottomBar
		>
			{sessionQueryStatus === 'pending' && (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			)}
			{(sessionQueryStatus === 'error' ||
				(sessionQueryStatus === 'success' && session === null)) && <Error404Notice />}
		</ScreenContainer>
	)
}
export default SessionScreen
