import nonHDBCarparkRates from 'src/_shared/_old/api/charging/Carparks.json'
import hdbCarparkRates from 'src/_shared/_old/api/charging/HDBCarparks.json'
import { ConnectorState, PaymentMethodCodes, PowerType } from 'src/_shared/_old/enums'
import { OmniChargingSession, OmniTariff } from 'src/_shared/_old/schemas/chargingSession'
import { OmniEVSE, OmniConnector } from 'src/_shared/_old/schemas/evse'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { CarparkRate, OmniLocation } from 'src/_shared/_old/schemas/typings'
import { filterEvse } from 'src/_shared/_old/utils/filter'
import { distanceBetween } from 'src/_shared/_old/utils/math'
import { OmniCountryCurrencyCode } from 'src/_shared/enums/omni'
import { getCurrencyDetails } from 'src/_shared/utils/currency'

import { formatDist, formatLocCoords } from './format'

export const getOriginalTariff = (connector?: OmniConnector): string => {
	try {
		const price = connector?.original_tariff?.price_per_kwh
		return price?.incl_vat ? price.incl_vat.toFixed(2) : '0.00'
	} catch (err) {
		console.log('Error while getting original tariff')
		console.error(err)
	}
	return '0.00'
}

export const getActiveTariff = (connector?: OmniConnector): string => {
	try {
		const price = connector?.active_tariff?.price_per_kwh
		return price?.incl_vat ? price.incl_vat.toFixed(2) : '0.00'
	} catch (err) {
		console.log('Error while getting active tariff')
		console.error(err)
	}
	return '0.00'
}

export const getHighestTariff = (
	comparisonKey: 'price_per_kwh' | 'price_per_min',
	tariffKey: 'active_tariff' | 'original_tariff',
	evses: OmniEVSE[],
	powerTypeFilter: PowerType | null
): OmniTariff | null => {
	let highestTariff: OmniTariff | null = null
	const filteredEvses = filterEvse(evses, powerTypeFilter)
	for (const evse of filteredEvses) {
		for (const connector of evse.connectors) {
			const pricePerKwh = connector[tariffKey]?.[comparisonKey]?.incl_vat ?? 0

			if (!highestTariff || (highestTariff[comparisonKey]?.incl_vat ?? 0) < pricePerKwh) {
				highestTariff = connector[tariffKey] ?? null
			}
		}
	}
	return highestTariff
}

/**
 * (For Old Locations Listing) Get the highest tariff from an EVSE.
 */
export const getHighestTariffPriceValue = (
	evses: OmniEVSE[],
	powerTypeFilter: PowerType | null,
	isAuthenticated: boolean
): string => {
	// Prioritise `price_per_kwh`; fallback to `price_per_min` otherwise.
	const comparisonKey = evses.every((evse) =>
		evse.connectors.every((connector) => !connector.active_tariff?.price_per_kwh?.incl_vat)
	)
		? 'price_per_min'
		: 'price_per_kwh'

	// Logged-in users should see the active tariff values instead (which has price modifiers).
	const tariffKey = isAuthenticated ? 'active_tariff' : 'original_tariff'

	const tariff = getHighestTariff(comparisonKey, tariffKey, evses, powerTypeFilter)

	const pricePerKwh = tariff?.price_per_kwh?.incl_vat ?? 0

	const pricePerMin = tariff?.price_per_min?.incl_vat ?? 0

	const { prefix: currencyPrefix } = getCurrencyDetails(tariff?.currency as OmniCountryCurrencyCode)

	// Prioritise display of `price_per_kwh` over `price_per_min`, even when both are zero.
	if (pricePerKwh > 0 || pricePerMin === 0) {
		return `${currencyPrefix}${pricePerKwh.toFixed(3)}/kWh`
	}
	return `${currencyPrefix}${pricePerMin.toFixed(3)}/min`
}

/**
 * (For Old Locations Listing) Display the connector's active tariff pricing,
 * which is either `price_per_kwh` (higher priority) or `price_per_min`.
 */
export const getConnectorTariffPriceValue = (
	connector: OmniConnector,
	tariffKey: 'active_tariff' | 'original_tariff'
) => {
	const { prefix: currencyPrefix } = getCurrencyDetails(
		connector[tariffKey]?.currency as OmniCountryCurrencyCode
	)
	const pricePerKwh = connector[tariffKey]?.price_per_kwh?.incl_vat ?? 0
	const pricePerMin = connector[tariffKey]?.price_per_min?.incl_vat ?? 0
	if (pricePerKwh > 0 || pricePerMin === 0) {
		return `${currencyPrefix}${pricePerKwh.toFixed(3)}/kWh`
	}
	return `${currencyPrefix}${pricePerMin.toFixed(3)}/min`
}

export const getConnectorPowerRating = (connector: OmniConnector): string => {
	return (connector.max_electric_power / 1000).toString()
}

export const getConnectorPowerType = (connector: OmniConnector): PowerType => {
	return connector.power_type.startsWith('AC') ? PowerType.AC : PowerType.DC
}

export const handleCheckAvailability = (
	evse: OmniEVSE,
	operatorName: string,
	connector?: OmniConnector
) => {
	const followsVirtaFlow: boolean =
		evse.protocol === 'VIRTA' || operatorName === 'MNL Asia' || operatorName === 'City Energy'

	const targetState: ConnectorState = followsVirtaFlow
		? ConnectorState.PREPARING
		: ConnectorState.AVAILABLE

	if (connector) {
		return connector.status === targetState
	}
	// if no specific connector required
	else if (evse.connectors.length > 0) {
		return evse.connectors.some((connector) => connector.status === targetState)
	}
	return false
}

export const getConnectorCounts = (evses: OmniEVSE[], operatorName: string) => {
	let totalACCount = 0
	let totalDCCount = 0
	let availableACCount = 0
	let availableDCCount = 0

	if (evses.length > 0) {
		for (const evse of evses) {
			for (const connector of evse.connectors) {
				const powerType = getConnectorPowerType(connector)
				if (powerType === PowerType.AC) {
					totalACCount++
					if (handleCheckAvailability(evse, operatorName, connector)) {
						availableACCount++
					}
				} else {
					totalDCCount++
					if (handleCheckAvailability(evse, operatorName, connector)) {
						availableDCCount++
					}
				}
			}
		}
	}

	return { totalACCount, totalDCCount, availableACCount, availableDCCount }
}

export const getCarparkRates = (location?: OmniLocation | null): CarparkRate | null => {
	let foundCarparkRate: CarparkRate | null = null
	if (location?.name.toLowerCase().includes('blk')) {
		for (const hdbCarparkRate of hdbCarparkRates) {
			if (hdbCarparkRate['Search Name'] === location.name) {
				foundCarparkRate = {
					beforeEvening: hdbCarparkRate['Weekday Before Evening'],
					afterEvening: hdbCarparkRate['Weekday After Evening'],
					saturday: hdbCarparkRate.Sat,
					sundayAndPH: hdbCarparkRate['Sun/PH']
				}
			}
		}
	} else {
		for (const nonHDBCarparkRate of nonHDBCarparkRates) {
			if (location?.name === nonHDBCarparkRate.Name) {
				foundCarparkRate = {
					beforeEvening: nonHDBCarparkRate['Weekday Before Evening'],
					afterEvening: nonHDBCarparkRate['Weekday After Evening'],
					saturday: nonHDBCarparkRate.Sat,
					sundayAndPH: nonHDBCarparkRate['Sun/PH']
				}
			}
		}
	}
	return foundCarparkRate
}

export const getLocationDetails = (location: OmniLocation, currentCoords: Coordinates) => {
	const locationCoords = formatLocCoords(location)
	const available = location.evses.some((e) => handleCheckAvailability(e, location.operator.name))
	const opening = location.opening_times.twentyfourseven
	const distance =
		currentCoords.lat && currentCoords.lng
			? formatDist(distanceBetween(locationCoords, currentCoords))
			: null

	return { available, opening, distance }
}

export const getTotalCost = (chargingSession: OmniChargingSession) => {
	if (chargingSession.payment_method_code === PaymentMethodCodes.SUBSCRIPTION) {
		return 'Subscription'
	} else {
		const cost = chargingSession.total_cost?.incl_vat?.toFixed(2) ?? '0.00'
		return `$${cost}`
	}
}

export const getPaymentMethodUsed = (chargingSession?: Partial<OmniChargingSession>) => {
	if (chargingSession?.payment_method_code === PaymentMethodCodes.SUBSCRIPTION) {
		return 'Subscription'
	} else if (
		(chargingSession?.payment_method_code === PaymentMethodCodes.BEEP ||
			chargingSession?.payment_method_code === PaymentMethodCodes.STRIPE) &&
		chargingSession.payment_method_token
	) {
		return `${chargingSession.payment_method_token.cardType} [xxxx ${chargingSession.payment_method_token.lastFourDigits}]`
	}
	return '-'
}
