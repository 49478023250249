import { createContext, useContext } from 'react'

import { StrapiBrandData } from '../queries/strapi'

export interface StrapiContextData {
	brandData: StrapiBrandData | null
}

export const StrapiContext = createContext<StrapiContextData>({ brandData: null })

export const useStrapiContext = (): StrapiContextData => {
	const context = useContext(StrapiContext)
	return context
}
