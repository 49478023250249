import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import visaAndMastercardStackedLogos from './visaAndMastercardStackedLogos.png'

const VisaAndMastercardStackedLogosImage = ({
	alt = 'Visa/Mastercard',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={visaAndMastercardStackedLogos} {...props} />
}

const MemoisedVisaAndMastercardStackedLogosImage = memo(VisaAndMastercardStackedLogosImage)

export default MemoisedVisaAndMastercardStackedLogosImage
