function Danger({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 83 79" fill="none">
			<path
				d="M80.1333 56.0165L54.8 10.4165C51.3958 4.28109 46.6854 0.916504 41.5 0.916504C36.3146 0.916504 31.6041 4.28109 28.2 10.4165L2.86664 56.0165C-0.339611 61.8353 -0.695861 67.4165 1.87706 71.8102C4.44997 76.204 9.51664 78.6186 16.1666 78.6186H66.8333C73.4833 78.6186 78.55 76.204 81.1229 71.8102C83.6958 67.4165 83.3396 61.7957 80.1333 56.0165ZM38.5312 28.6248C38.5312 27.0019 39.8771 25.6561 41.5 25.6561C43.1229 25.6561 44.4687 27.0019 44.4687 28.6248V48.4165C44.4687 50.0394 43.1229 51.3853 41.5 51.3853C39.8771 51.3853 38.5312 50.0394 38.5312 48.4165V28.6248ZM44.3104 63.1019C44.1125 63.2603 43.9146 63.4186 43.7166 63.5769C43.4791 63.7353 43.2416 63.854 43.0041 63.9332C42.7666 64.0519 42.5291 64.1311 42.2521 64.1707C42.0146 64.2103 41.7375 64.2498 41.5 64.2498C41.2625 64.2498 40.9854 64.2103 40.7083 64.1707C40.4708 64.1311 40.2333 64.0519 39.9958 63.9332C39.7583 63.854 39.5208 63.7353 39.2833 63.5769C39.0854 63.4186 38.8875 63.2603 38.6896 63.1019C37.9771 62.3498 37.5416 61.3207 37.5416 60.2915C37.5416 59.2623 37.9771 58.2332 38.6896 57.4811C38.8875 57.3228 39.0854 57.1644 39.2833 57.0061C39.5208 56.8478 39.7583 56.729 39.9958 56.6498C40.2333 56.5311 40.4708 56.4519 40.7083 56.4123C41.2229 56.2936 41.7771 56.2936 42.2521 56.4123C42.5291 56.4519 42.7666 56.5311 43.0041 56.6498C43.2416 56.729 43.4791 56.8478 43.7166 57.0061C43.9146 57.1644 44.1125 57.3228 44.3104 57.4811C45.0229 58.2332 45.4583 59.2623 45.4583 60.2915C45.4583 61.3207 45.0229 62.3498 44.3104 63.1019Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default Danger
