import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { CPO_BACKEND_URL } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { PaymentMethod } from '../types/payments'

export const ROOT_PAYMENTS_QUERY_KEY = 'Payments'

export enum PaymentsQueryKey {
	PaymentMethods = 'PaymentMethods',
	StripeSetupIntent = 'StripeSetupIntent'
}

export const usePaymentMethodsQuery = <TData = PaymentMethod[]>(
	options?: Omit<
		UseQueryOptions<
			PaymentMethod[],
			AxiosError<{ message: string }>,
			TData,
			[string, PaymentsQueryKey.PaymentMethods, string | null]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken, isAuthenticated } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [ROOT_PAYMENTS_QUERY_KEY, PaymentsQueryKey.PaymentMethods, accessToken],
		enabled: (options?.enabled === undefined || options.enabled) && isAuthenticated,
		queryFn: async (): Promise<PaymentMethod[]> => {
			try {
				const response = await axios.get<PaymentMethod[]>(
					`${CPO_BACKEND_URL}/v2/me/payment-method-tokens`,
					{
						headers: {
							Authorization: accessToken
						}
					}
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StripeSetupIntentData {
	clientSecret: string
}

export const useStripeSetupIntentQuery = <TData = StripeSetupIntentData>(
	options?: Omit<
		UseQueryOptions<
			StripeSetupIntentData,
			AxiosError<{ message: string }>,
			TData,
			[PaymentsQueryKey.StripeSetupIntent, string | null]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	const { accessToken, isAuthenticated } = useAuthContext()
	return useQuery({
		...options,
		queryKey: [PaymentsQueryKey.StripeSetupIntent, accessToken],
		enabled: (options?.enabled === undefined || options.enabled) && isAuthenticated,
		queryFn: async (): Promise<StripeSetupIntentData> => {
			try {
				const response = await axios.get<StripeSetupIntentData>(
					`${CPO_BACKEND_URL}/v2/payment-method-tokens/stripe/setup-intent`,
					{
						headers: {
							Authorization: accessToken
						}
					}
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}
