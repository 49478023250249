interface P {
	number?: number
}

export default function LocationListSkel(props: P) {
	const { number } = props
	const components = [...Array(number).keys()]

	return (
		<div className="flex w-full flex-col items-center justify-center space-y-3 py-3">
			{components.map((_, index) => (
				<div key={index} className="h-24 w-full animate-pulse rounded-lg bg-grayscale-500"></div>
			))}
		</div>
	)
}
