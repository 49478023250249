import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { STRAPI_URL } from 'src/_shared/constants/env'
import { Brand, Language } from 'src/_shared/enums/env'
import { ROOT_STRAPI_QUERY_KEY, StrapiQueryKey, StrapiResponse } from 'src/_shared/queries/strapi'
import { FaqItem } from 'src/screens/AccountHelpScreen/types'

interface StrapiFaqQueryParams {
	locale?: Language
	brand?: Brand
}

interface StrapiFaqTranslationsData {
	id: number
	attributes: Partial<{
		translations: FaqItem[]
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		createdAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		updatedAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		publishedAt: string
		locale: Language
	}>
}

export const useStrapiFaqQuery = <TData = StrapiFaqTranslationsData>(
	params: StrapiFaqQueryParams,
	options?: Omit<
		UseQueryOptions<
			StrapiFaqTranslationsData,
			AxiosError,
			TData,
			[string, StrapiQueryKey.StrapiFaq, StrapiFaqQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_STRAPI_QUERY_KEY, StrapiQueryKey.StrapiFaq, params],
		queryFn: async (): Promise<StrapiFaqTranslationsData> => {
			try {
				const { locale, brand } = params
				const response = await axios.get<StrapiResponse<StrapiFaqTranslationsData>>(
					`${STRAPI_URL}/api/faq`,
					{
						params: {
							locale,
							brand
						}
					}
				)
				const data: StrapiFaqTranslationsData | undefined | null = response.data.data

				if (!data) {
					console.debug('[useStrapiFaqQuery] No Translations Data Found')
					return {
						id: -1,
						attributes: {}
					}
				}
				return data
			} catch (error) {
				const axiosError = error as AxiosError<StrapiResponse<StrapiFaqTranslationsData>>
				return Promise.reject(axiosError)
			}
		}
	})
}
