import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { CPO_BACKEND_URL } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { OmniError } from '../enums/omni'
import { PaymentMethodCode, PaymentTypeCode } from '../enums/payments'
import { OmniSession } from '../types/omni'

export interface StartChargingSessionMutationParams {
	locationUid: string
	evseUid: string
	connectorUid: string
	paymentMethodCode?: PaymentMethodCode
	paymentMethodUid?: string
	paymentTypeCode?: PaymentTypeCode
	licensePlate?: string
	/**
	 * This field is for EVme and should be provided via query parameter.
	 */
	vehicleId?: string
}

interface StartChargingSessionResponse {
	data: {
		session: OmniSession | null
		session_id: string
		payment_url: string
	} | null
	message: string
	status: number
}

export const useStartChargingSessionMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<StartChargingSessionResponse>,
			AxiosError<{ error?: { code: OmniError; message: string } }>,
			StartChargingSessionMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async ({
			locationUid: location_uid,
			evseUid: evse_uid,
			connectorUid: connector_uid,
			paymentMethodCode: payment_method_code,
			paymentMethodUid: payment_token_uid,
			paymentTypeCode: payment_type_code,
			licensePlate: license_plate = '',
			vehicleId: vehicle_id
		}: StartChargingSessionMutationParams): Promise<
			AxiosResponse<StartChargingSessionResponse>
		> => {
			try {
				const response = await axios.post<StartChargingSessionResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/start`,
					{
						location_uid,
						evse_uid,
						connector_uid,
						payment_method_code,
						payment_token_uid,
						payment_type_code,
						license_plate,
						vehicle_id
					},
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StopChargingSessionMutationParams {
	sessionId: string
}

interface StopChargingSessionResponse {
	/**
	 * `data` may just be an empty object `{}`.
	 */
	data: Partial<Record<string, unknown>> | null
	message: string
	redirect_url?: string
	status: number
}

export const useStopChargingSessionMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<StopChargingSessionResponse>,
			AxiosError<{ message: string }>,
			StopChargingSessionMutationParams
		>,
		'mutationFn'
	>
) => {
	const { accessToken } = useAuthContext()
	return useMutation({
		...options,
		mutationFn: async ({
			sessionId
		}: StopChargingSessionMutationParams): Promise<AxiosResponse<StopChargingSessionResponse>> => {
			try {
				const response = await axios.post<StopChargingSessionResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/${sessionId}/stop`,
					{},
					{
						headers: { Authorization: accessToken }
					}
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StartChargingSessionTransientMutationParams {
	/**
	 * For email notifications.
	 */
	email: string
	locationUid: string
	evseUid: string
	connectorUid: string
	paymentMethodCode: PaymentMethodCode
	paymentTypeCode: PaymentTypeCode
	/**
	 * Returns to specified URL when successful.
	 */
	redirectUrl: string
	/**
	 * Returns to specified URL when unsuccessful.
	 */
	backUrl: string
}

interface StartChargingSessionTransientResponse {
	data: {
		session: OmniSession | null
		session_id: string
		payment_url: string
	} | null
	message: string
	status: number
}

export const useStartChargingSessionTransientMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<StartChargingSessionTransientResponse>,
			AxiosError<{ error?: { code: OmniError; message: string } }>,
			StartChargingSessionTransientMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async ({
			email,
			locationUid: location_uid,
			evseUid: evse_uid,
			connectorUid: connector_uid,
			paymentMethodCode: payment_method_code,
			paymentTypeCode: payment_type_code,
			redirectUrl: redirect_url,
			backUrl: back_url
		}: StartChargingSessionTransientMutationParams): Promise<
			AxiosResponse<StartChargingSessionTransientResponse>
		> => {
			try {
				const response = await axios.post<StartChargingSessionTransientResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/transient/start`,
					{
						email,
						location_uid,
						evse_uid,
						connector_uid,
						payment_method_code,
						payment_type_code,
						redirect_url,
						back_url
					},
					{ withCredentials: true } // Include cookies.
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}

interface StopChargingSessionTransientMutationParams {
	sessionId: string
}

interface StopChargingSessionTransientResponse {
	/**
	 * `data` may just be an empty object `{}`.
	 */
	data: Partial<Record<string, unknown>> | null
	message: string
	redirect_url?: string
	status: number
}

export const useStopChargingSessionTransientMutation = (
	options?: Omit<
		UseMutationOptions<
			AxiosResponse<StopChargingSessionTransientResponse>,
			AxiosError<{ message: string }>,
			// Params below are unused; this is used for `ChargingView` `stopChargingSession` type-checking.
			StopChargingSessionTransientMutationParams
		>,
		'mutationFn'
	>
) => {
	return useMutation({
		...options,
		mutationFn: async (): Promise<AxiosResponse<StopChargingSessionTransientResponse>> => {
			try {
				const response = await axios.post<StopChargingSessionTransientResponse>(
					`${CPO_BACKEND_URL}/v3/sessions/transient/stop`,
					{},
					{ withCredentials: true } // Include cookies.
				)
				return response
			} catch (error) {
				const axiosError = error as AxiosError
				return Promise.reject(axiosError)
			}
		}
	})
}
