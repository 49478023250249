function IconCluster({ className }: { className?: string }) {
	return (
		<svg
			className={className}
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)'
			}}
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 240 240"
		>
			<circle cx="120" cy="120" opacity="1" r="50" />
			<circle cx="120" cy="120" opacity=".7" r="70" />
			<circle cx="120" cy="120" opacity=".3" r="90" />
			<circle cx="120" cy="120" opacity=".2" r="110" />
		</svg>
	)
}

export default IconCluster
