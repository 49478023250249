import IconRenderer from 'src/_shared/_old/components/IconRenderer'
import { IconTypes } from 'src/_shared/_old/enums'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'

interface Props {
	title?: string
	icon?: IconTypes
	text?: string
	buttonType?: string
	buttonTitle?: string
	showModal?: boolean
	onCloseModal?: () => void
	onClickButtonYes?: () => void
}

const OldModal = ({
	title,
	icon,
	text,
	buttonType,
	buttonTitle,
	showModal,
	onCloseModal
}: Props) => {
	if (!showModal) return null

	return (
		<Modal open={showModal}>
			<ModalCard>
				{icon && <IconRenderer icon={icon} />}
				<div className="flex w-full flex-col items-center justify-center space-y-1">
					{title && <h1 className="mb-2 text-center">{title}</h1>}
					{text && <p className="body-2-normal text-center">{text}</p>}
				</div>
				{buttonType === 'CLOSE' && (
					<Button
						className="mt-8 w-full"
						onClick={() => {
							onCloseModal?.()
						}}
					>
						{buttonTitle}
					</Button>
				)}
			</ModalCard>
		</Modal>
	)
}

export default OldModal
