import { PASSWORD_MINIMUM_LENGTH } from '../constants/account'

export const arePasswordsMatching = (password: string, confirmPassword: string): boolean => {
	if (password.length > 0 && confirmPassword.length > 0) {
		return password === confirmPassword
	}
	return true
}

export const isPasswordComplexityMet = (password: string): boolean => {
	if (
		// Minimum length requirement
		password.length < PASSWORD_MINIMUM_LENGTH ||
		// At least 1 lowercase and uppercase letter
		!password.match(/([a-z]{1,})/) ||
		!password.match(/([A-Z]{1,})/) ||
		// At least 1 number
		!password.match(/([0-9]{1,})/)
	) {
		return false
	}
	return true
}
