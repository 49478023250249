import { useRollbar } from '@rollbar/react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren, useMemo } from 'react'

/**
 * Configured React Query with Rollbar error logging.
 */
const ReactQueryWrapper = ({ children }: PropsWithChildren): JSX.Element => {
	const rollbar = useRollbar()

	const queryClient = useMemo((): QueryClient => {
		return new QueryClient({
			queryCache: new QueryCache({
				onError: (error): void => {
					rollbar.error(
						'[React Query] Error:',
						typeof error === 'object' ? JSON.stringify(error) : String(error)
					)
				}
			})
		})
	}, [rollbar])

	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default ReactQueryWrapper
