/**
 * Delays the next execution for a specified amount of time.
 * @param {number} delay The time in milliseconds that the timer should wait for.
 */
export const delay = async (delay: number): Promise<void> => {
	const timeoutId = await new Promise<number>((resolve): number => {
		return setTimeout(resolve, delay)
	})
	clearTimeout(timeoutId)
}
