import { GOOGLE_BLUE_MARKER_COLOR } from '../../enums'

interface Props {
	lat?: number
	lng?: number
}

type CurrentLocationPinFn = (props: Props) => JSX.Element

const CURRENT_LOCATION_PIN_Z_INDEX = 20

const CurrentLocationPin: CurrentLocationPinFn = () => {
	return (
		<div
			className="absolute -translate-x-1/2 -translate-y-1/2 transform"
			ref={(divRef) => {
				if (divRef?.parentElement) {
					const parentDivElement = divRef.parentElement as HTMLDivElement
					parentDivElement.style.zIndex = String(CURRENT_LOCATION_PIN_Z_INDEX)
				}
			}}
		>
			<div
				className={`absolute h-14 w-14 -translate-x-1/2 -translate-y-1/2 transform ${GOOGLE_BLUE_MARKER_COLOR} pointer-events-none rounded-full bg-opacity-10`}
			/>
			<div className="absolute h-6 w-6 -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-white" />
			<div
				className={`h-4 w-4 rounded-full ${GOOGLE_BLUE_MARKER_COLOR} animate-scale ml-[-8px] mt-[-8px]`}
			/>
		</div>
	)
}

export default CurrentLocationPin
