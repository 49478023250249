import { ReactNode, useEffect, useState } from 'react'

interface Props {
	className?: string
	children: ReactNode
	scroll?: boolean
}

const PageContainer = ({ className = '', children, scroll }: Props) => {
	const [windowHeight, setWindowHeight] = useState(0)

	const calculateWindowHeight = () => {
		const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
		setWindowHeight(height)
	}

	/*
    this useEffect attaches a listener for window resize events.
    When the window is resized due to on screen keyboard showing or orientation changing,
    it recalculates and updates the window height state.
  */
	useEffect(() => {
		const handleResize = () => {
			calculateWindowHeight()
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className="flex w-full justify-center">
			<div
				style={{
					height: windowHeight !== 0 ? windowHeight : window.innerHeight,
					overflow: scroll === undefined || scroll ? 'scroll' : 'hidden'
				}}
				className={`flex max-h-screen w-full max-w-112 flex-col items-center ${className}`}
			>
				{children}
			</div>
		</div>
	)
}

export default PageContainer
