export enum AccountForgotPasswordScreenViewItemKey {
	EnterOtpView = 'EnterOtpView',
	PostNewPasswordView = 'PostNewPasswordView',
	NewPasswordView = 'NewPasswordView',
	SelectOtpMethodView = 'SelectOtpMethodView'
}

export enum FormFieldKey {
	ConfirmNewPassword = 'confirmNewPassword',
	CountryCode = 'countryCode',
	MobileNumber = 'mobileNumber',
	NewPassword = 'newPassword',
	Otp = 'otp',
	ResetToken = 'resetToken'
}
