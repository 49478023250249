import { PaymentMethodCardType } from 'src/_shared/enums/payments'

import amexImage from './assets/images/amex.jpg'
import jcbImage from './assets/images/jcb.jpg'
import mastercardImage from './assets/images/mastercard.png'
import unionpayImage from './assets/images/unionpay.jpg'
import visaImage from './assets/images/visa.png'

export const getImageAsset = (cardType?: PaymentMethodCardType): string | null => {
	switch (cardType) {
		case PaymentMethodCardType.Amex:
			return amexImage
		case PaymentMethodCardType.Jcb:
			return jcbImage
		case PaymentMethodCardType.Mastercard:
			return mastercardImage
		case PaymentMethodCardType.UnionPay:
			return unionpayImage
		case PaymentMethodCardType.Visa:
			return visaImage
		default:
			return null
	}
}
