import { PowerType } from 'src/_shared/_old/enums'
import { OmniConnector } from 'src/_shared/_old/schemas/evse'

interface RatingBoxProps {
	connectors: OmniConnector[]
	availableCount: number
	powerType: PowerType
	powerRating: string
}

const RatingBox = ({ connectors, availableCount, powerType, powerRating }: RatingBoxProps) => {
	return (
		<div
			className={`${availableCount > 0 ? (powerType === PowerType.AC ? 'bg-[#6181A4]' : 'bg-orange-400') : 'bg-grayscale-600'} flex min-w-24 items-center justify-between rounded-lg px-3 py-2`}
		>
			<span className="text-[0.7rem] font-bold leading-5 text-white">
				{powerType} {powerRating}
			</span>
			<span className="text-sm font-bold text-white">
				<span>{availableCount}</span>
				<span className="font-normal">/{connectors.length}</span>
			</span>
		</div>
	)
}

export default RatingBox
