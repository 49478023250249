import { ReactNode, memo, useMemo } from 'react'
import LightningBoltFilledIcon from 'src/_shared/components/_icons/LightningBoltFilledIcon'
import { classNames } from 'src/_shared/utils/elements'

interface StateOfChargeCircleProps {
	className?: string
	variant?: 'active' | 'inactive' | 'progress' | 'warning'
	isCharging?: boolean
	/**
	 * Range: [0, 100]
	 */
	percentage?: number | null
	centerRender?: ReactNode
}

// Based on `h-24`/`w-24` (96px).
const SVG_CIRCLE_CENTER = 48

const SVG_CIRCLE_LINE_WIDTH = 16

const SVG_CIRCLE_RADIUS = SVG_CIRCLE_CENTER + SVG_CIRCLE_LINE_WIDTH / 2

// Accounts for strokecaps protruding out of each end.
const SVG_CIRCLE_ROTATION_OFFSET = 7

// Based on `SVG_CIRCLE_ROTATION_OFFSET`; increasing Order: 25%, 50%, 75%, 99%
// Ensures that the line caps are in the correct ending positions at each quadrant
const SVG_CIRCLE_PERCENTAGE_BREAKPOINTS = [20.5, 45.5, 70.5, 95.5]

const SVG_CIRCLE_PERCENTAGE_INTERVAL = 25

// For display of 0%
const SVG_CIRCLE_PERCENTAGE_MINIMUM = 0.01

const SVG_CIRCLE_PERCENTAGE_MAXIMUM = 100

const SVG_CIRCLE_CIRCUMFERENCE = 2 * SVG_CIRCLE_RADIUS * Math.PI

const StateOfChargeCircle = ({
	className,
	variant = 'inactive',
	isCharging = false,
	percentage = 0,
	centerRender
}: StateOfChargeCircleProps): JSX.Element => {
	/**
	 * Estimation for the ending position of the radial progress.
	 * - Based on the circle's dimensions (specified above).
	 * - Due to the protruding round stroke linecaps, we have to interpolate
	 *   between the percentage breakpoints to ensure that the line visually grows
	 *   from 0% to 100%.
	 */
	const strokePercentage = useMemo((): number => {
		if (typeof percentage === 'number') {
			if (percentage < SVG_CIRCLE_PERCENTAGE_MAXIMUM) {
				let maximumIndex = 0
				while (percentage > SVG_CIRCLE_PERCENTAGE_INTERVAL * (maximumIndex + 1)) {
					maximumIndex++
				}
				const percentageMaximum = SVG_CIRCLE_PERCENTAGE_INTERVAL * (maximumIndex + 1)
				return Math.max(
					SVG_CIRCLE_PERCENTAGE_MINIMUM, // Ensures that the stroke is still visible even at 0%.
					(percentage / percentageMaximum) * SVG_CIRCLE_PERCENTAGE_BREAKPOINTS[maximumIndex]
				)
			}
			return SVG_CIRCLE_PERCENTAGE_MAXIMUM
		}
		return 0
	}, [percentage])

	/**
	 * Formula: `circumference - (circumference * percentage) / 100`
	 */
	const circleStrokeDashoffset = useMemo((): number => {
		return (
			SVG_CIRCLE_CIRCUMFERENCE -
			(SVG_CIRCLE_CIRCUMFERENCE * strokePercentage) / SVG_CIRCLE_PERCENTAGE_MAXIMUM
		)
	}, [strokePercentage])

	return (
		<div
			className={classNames(
				// Base Classes
				'relative flex h-32 w-32 flex-col items-center justify-center rounded-full',
				// Charging Classes
				// `variant` Classes
				((): string => {
					switch (variant) {
						case 'active':
							return 'bg-gradient-to-b from-primary-800/90 to-primary-800/10'
						case 'inactive':
							return 'bg-gradient-to-b from-grayscale-800/40 to-grayscale-800/5'
						case 'progress':
							return 'bg-primary-800/25'
						case 'warning':
							return 'bg-gradient-to-b from-[#F1A75E]/90 to-[#F1A75E]/35'
					}
				})(),

				className
			)}
		>
			{/* Radial Progress */}
			{isCharging && (
				<div className="absolute h-24 w-24">
					<svg
						className={`overflow-visible`}
						viewBox={`0 0 ${SVG_CIRCLE_CENTER * 2} ${SVG_CIRCLE_CENTER * 2}`}
					>
						<circle
							className="text-primary-800 duration-300 ease-in-out"
							fill="transparent"
							stroke="currentColor"
							strokeLinecap="round" // Protrudes out of the stroke ends.
							strokeWidth={SVG_CIRCLE_LINE_WIDTH}
							cx={SVG_CIRCLE_CENTER}
							cy={SVG_CIRCLE_CENTER}
							r={SVG_CIRCLE_RADIUS}
							strokeDasharray={SVG_CIRCLE_CIRCUMFERENCE}
							strokeDashoffset={circleStrokeDashoffset}
							// Rotation offset done here to account for protruding stroke ends.
							transform={`rotate(${-90 + SVG_CIRCLE_ROTATION_OFFSET} ${SVG_CIRCLE_CENTER} ${SVG_CIRCLE_CENTER})`}
						/>
					</svg>
				</div>
			)}
			{/* Center Render */}
			<div className="flex h-24 w-24 flex-col items-center justify-center rounded-full border-8 border-black bg-black/90">
				{centerRender ? centerRender : <LightningBoltFilledIcon className="h-7 w-7 text-white" />}
			</div>
		</div>
	)
}

const MemoisedStateOfChargeCircle = memo(StateOfChargeCircle)

export default MemoisedStateOfChargeCircle
