import { COUNTRY_CODE_OPTIONS } from '../enums'
import { Coordinates } from '../schemas/geolocation'
import { CountryCode, OmniLocation } from '../schemas/typings'

export const replaceEmpty = (value: unknown, replacement?: string): string => {
	if (
		value === null ||
		value === false ||
		value === undefined ||
		value === '' ||
		value === 'Invalid Date'
	) {
		return replacement ?? '-'
	}
	if (typeof value === 'string') {
		return value
	}
	return '-'
}

export const removeDuplicates = <T = unknown,>(array: T[]): T[] => {
	return [...new Set(array)]
}

export const formatLocCoords = (location?: OmniLocation | null): Coordinates => {
	return {
		lat: location ? parseFloat(location.coordinates.latitude) : 0,
		lng: location ? parseFloat(location.coordinates.longitude) : 0
	}
}

export const formatDist = (value: number): string => {
	return value < 1 ? (value * 1000).toFixed(0) + ' m' : value.toFixed(2) + ' km'
}

export const formatPrice = (value: number): string => {
	return '$' + value.toFixed(2)
}

export const getCountryCode = (country: string): CountryCode => {
	const countryCode = COUNTRY_CODE_OPTIONS.find(
		(c) => c.fullName.toLowerCase() === country.toLowerCase()
	)
	return (
		countryCode ?? {
			fullName: '',
			shortName: '',
			code: '',
			emoji: '',
			display: '',
			emojiCodeDisplay: '',
			emojiNameDisplay: ''
		}
	)
}

// CGDE chargers would contain unintended strings
export const filterCDGEAddressName = (addressName: string): string => {
	const regex = /Charging speed may vary.\s+Click 'More info' to find out more.\s+/
	if (regex.test(addressName)) {
		const trimmedAddressName = addressName.replace(regex, '')
		return trimmedAddressName
	} else {
		return addressName
	}
}

export const filterSPDirectionsString = (directionsName: string): string => {
	const regex = /^(language:\s?entext:)([\s\S]*?)(\n)$/
	const matches = directionsName.match(regex)
	if (regex.test(directionsName) && !!matches && matches.length > 0) {
		const trimmedDirectionsName = directionsName.replace(matches[1], '').replace(matches[3], '')
		return trimmedDirectionsName
	} else {
		return directionsName
	}
}
