import { useEffect, useState } from 'react'
import Compass from 'src/_shared/_old/assets/svgs/compass.svg'
import ListViewLogo from 'src/_shared/_old/assets/svgs/listview.svg'
import QrScanner from 'src/_shared/_old/assets/svgs/qrscanner.svg'

interface Props {
	onClickMapType: () => void
	onClickCurLoc: () => void
	onClickList: () => void
	onClickQrScanner: () => void
}

const MapButtons = ({ onClickCurLoc, onClickList, onClickQrScanner }: Props) => {
	const [isCurLocClicked, setIsCurLocClicked] = useState(false)

	useEffect(() => {
		if (isCurLocClicked) {
			setTimeout(() => {
				setIsCurLocClicked(false)
			}, 300)
		}
	}, [isCurLocClicked])

	return (
		<div className="flex w-full flex-col-reverse items-end justify-center gap-y-3 px-3 pb-3">
			<div className="space-x-3">
				<button
					data-testid="qr-scanner"
					className="pointer-events-auto rounded-md bg-white p-3 drop-shadow-lg"
					onClick={onClickQrScanner}
				>
					<img src={QrScanner} className="h-5 text-typography-primary" />
				</button>
			</div>
			<button
				data-testid="list-chargers"
				className="pointer-events-auto rounded-md bg-white p-3 drop-shadow-lg"
				onClick={onClickList}
			>
				<img src={ListViewLogo} className="h-5 w-5 text-typography-primary" />
			</button>
			<button
				data-testid="current-location"
				className={`pointer-events-auto rounded-full bg-primary-800 p-1 drop-shadow-lg ${
					isCurLocClicked && 'animate-rotate-shake'
				}`}
				onClick={() => {
					setIsCurLocClicked(true)
					onClickCurLoc()
				}}
			>
				<img src={Compass} className="h-8 w-8 text-white drop-shadow-lg" />
			</button>
		</div>
	)
}

export default MapButtons
