import { useState, useLayoutEffect, useRef } from 'react'

// Note: This doesn't seem to do anything...
export default function useHeight() {
	const [height, setHeight] = useState(0)
	const ref = useRef<HTMLDivElement | null>(null)

	useLayoutEffect(() => {
		setHeight(ref.current?.clientHeight ?? 0)
	}, [ref.current?.clientHeight])

	return {
		ref,
		height
	}
}
