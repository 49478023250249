import { ChangeEvent, FormEvent, useCallback, useMemo } from 'react'
import Button from 'src/_shared/components/Button'
import InputPassword from 'src/_shared/components/InputPassword'
import UnlockIcon from 'src/_shared/components/_icons/UnlockIcon'
import { PASSWORD_REQUIREMENTS_TEXT } from 'src/_shared/constants/account'
import { useUserOtpResetPasswordMutation } from 'src/_shared/mutations/auth'
import { arePasswordsMatching, isPasswordComplexityMet } from 'src/_shared/utils/account'

import AccountScreenHeader from '../../../_shared/components/AccountScreenHeader'
import { FormFieldKey } from '../enums'
import { AccountForgotPasswordScreenCommonViewProps, FormField } from '../types'

type NewPasswordViewProps = AccountForgotPasswordScreenCommonViewProps

const NewPasswordView = ({
	formValues,
	setFormValues,
	onNext: handleNext
}: NewPasswordViewProps): JSX.Element => {
	const countryCode = formValues[FormFieldKey.CountryCode]

	const mobileNumber = formValues[FormFieldKey.MobileNumber]

	const resetToken = formValues[FormFieldKey.ResetToken]

	const newPassword = formValues[FormFieldKey.NewPassword]

	const confirmNewPassword = formValues[FormFieldKey.ConfirmNewPassword]

	const {
		error: userResetPasswordError,
		isError: isUserResetPasswordError,
		isPending: isUserResetPasswordPending,
		mutateAsync: resetUserPasssord,
		reset: resetUserOtpResetPasswordMutation
	} = useUserOtpResetPasswordMutation()

	const formFields = useMemo((): FormField[] => {
		const newPasswordFormField: FormField = {
			key: FormFieldKey.NewPassword,
			label: 'New Password',
			errorMessage: ((): string | undefined => {
				if (newPassword.length > 0 && !isPasswordComplexityMet(newPassword)) {
					return 'Your password does not meet the complexity requirements'
				}
			})()
		}

		const confirmNewPasswordFormField: FormField = {
			key: FormFieldKey.ConfirmNewPassword,
			label: 'Confirm New Password',
			errorMessage: ((): string | undefined => {
				if (!arePasswordsMatching(newPassword, confirmNewPassword)) {
					return 'Your password does not match'
				}
				// Handle 4XX/5XX status errors thrown with messages.
				if (isUserResetPasswordError) {
					const errorMessage = userResetPasswordError.response?.data.message
					return errorMessage ? errorMessage : 'Oops! Something went wrong'
				}
			})()
		}

		return [newPasswordFormField, confirmNewPasswordFormField]
	}, [confirmNewPassword, isUserResetPasswordError, newPassword, userResetPasswordError])

	/**
	 * Checks for missing form values or an error present in any field.
	 */
	const isFormInvalid = useMemo((): boolean => {
		return (
			Object.values(formValues).some((value): boolean => !value) ||
			formFields.some((field): boolean => !!field.errorMessage)
		)
	}, [formFields, formValues])

	const handleFormChange = useCallback(
		(key: FormFieldKey) =>
			(event: ChangeEvent<HTMLInputElement>): void => {
				const value = event.currentTarget.value.replace(/\s/g, '')
				setFormValues(
					(values): Record<FormFieldKey, string> => ({
						...values,
						[key]: value
					})
				)
				if (isUserResetPasswordError) {
					resetUserOtpResetPasswordMutation()
				}
			},
		[isUserResetPasswordError, resetUserOtpResetPasswordMutation, setFormValues]
	)

	const handleNextClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (!isFormInvalid) {
				void resetUserPasssord(
					{
						mobileNumber: countryCode + mobileNumber,
						password: newPassword,
						resetToken
					},
					{
						onSuccess: (): void => {
							resetUserOtpResetPasswordMutation()
							handleNext?.()
						}
					}
				)
			}
		},
		[
			countryCode,
			isFormInvalid,
			mobileNumber,
			newPassword,
			resetToken,
			handleNext,
			resetUserOtpResetPasswordMutation,
			resetUserPasssord
		]
	)
	return (
		<form className="flex flex-grow flex-col" onSubmit={handleNextClick}>
			<div className="flex flex-grow flex-col space-y-5">
				<AccountScreenHeader title="Set New Password" subTitle={PASSWORD_REQUIREMENTS_TEXT} />
				{formFields.map(({ key, label, errorMessage }, index): JSX.Element => {
					return (
						<InputPassword
							key={index}
							autoComplete="off"
							id={key}
							name={key}
							placeholder={label}
							value={formValues[key]}
							disabled={isUserResetPasswordPending}
							error={!!errorMessage}
							description={errorMessage}
							onChange={handleFormChange(key)}
							startAdornment={
								<div className="p-1">
									<UnlockIcon className="h-5 w-5 text-typography-secondary" />
								</div>
							}
						/>
					)
				})}
			</div>
			<div className="flex flex-col">
				<Button
					variant="primary"
					disabled={isFormInvalid || isUserResetPasswordPending}
					loading={isUserResetPasswordPending}
				>
					Reset Password
				</Button>
			</div>
		</form>
	)
}

export default NewPasswordView
