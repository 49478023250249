import { FILTER_BAR_HEIGHT, LIVE_SESSION_HEIGHT, TOPBAR_HEIGHT } from 'src/_shared/_old/enums'

interface P {
	isLiveSession: boolean
	showAlert?: boolean
	msg?: string
}

export default function Elert(props: P) {
	const { isLiveSession, showAlert, msg } = props
	return (
		showAlert && (
			<div
				className="pointer-events-none absolute top-0 z-20 flex w-full items-center justify-center px-10 py-4"
				style={{
					marginTop: TOPBAR_HEIGHT + FILTER_BAR_HEIGHT + (isLiveSession ? LIVE_SESSION_HEIGHT : 0)
				}}
			>
				<div className="rounded-lg bg-black bg-opacity-40 px-4 py-3">
					<p className="text-sm font-medium text-white">{msg}</p>
				</div>
			</div>
		)
	)
}
