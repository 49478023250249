import { DetailedHTMLProps, HTMLAttributes, ReactNode, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface AccountScreenHeaderProps {
	title: ReactNode
	titleProps?: Omit<
		DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>,
		'children'
	>
	subTitle?: ReactNode
	subTitleProps?: Omit<
		DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>,
		'children'
	>
	className?: string
}

const AccountScreenHeader = ({
	title,
	titleProps,
	subTitle,
	subTitleProps,
	className
}: AccountScreenHeaderProps): JSX.Element => {
	return (
		<div className={classNames('pb-3 pt-10', className)}>
			<h1
				{...titleProps}
				className={classNames('mb-2 text-center text-typography-primary', titleProps?.className)}
			>
				{title}
			</h1>
			{subTitle && (
				<p
					{...subTitleProps}
					className={classNames(
						'body-1-normal text-center text-typography-secondary',
						subTitleProps?.className
					)}
				>
					{subTitle}
				</p>
			)}
		</div>
	)
}

const MemoisedAccountScreenHeader = memo(AccountScreenHeader)

export default MemoisedAccountScreenHeader
