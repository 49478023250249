import ReactSlider from 'react-slider'

import './slideRange.css'

interface P {
	controlledValue?: number[]
	min?: number
	max?: number
	minDistance?: number
	onChange?: (value: number[]) => void
}

export default function SlideRange(props: P) {
	const { controlledValue, min, max, minDistance, onChange } = props

	return (
		<ReactSlider<number[]>
			className="horizontal-slider"
			thumbClassName="example-thumb"
			trackClassName="example-track"
			value={controlledValue}
			min={min}
			max={max}
			onChange={(value) => onChange?.(value)}
			minDistance={minDistance}
		/>
	)
}
