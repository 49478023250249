function TinyLocation({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 15 15" fill="none">
			<g opacity="0.5">
				<path
					opacity="0.4"
					d="M12.8875 5.28125C12.2312 2.39375 9.7125 1.09375 7.5 1.09375C7.5 1.09375 7.5 1.09375 7.49375 1.09375C5.2875 1.09375 2.7625 2.3875 2.10625 5.275C1.375 8.5 3.35 11.2312 5.1375 12.95C5.8 13.5875 6.65 13.9062 7.5 13.9062C8.35 13.9062 9.2 13.5875 9.85625 12.95C11.6437 11.2312 13.6187 8.50625 12.8875 5.28125Z"
					fill="#595959"
				/>
				<path
					d="M7.5 8.4126C8.58731 8.4126 9.46875 7.53116 9.46875 6.44385C9.46875 5.35654 8.58731 4.4751 7.5 4.4751C6.41269 4.4751 5.53125 5.35654 5.53125 6.44385C5.53125 7.53116 6.41269 8.4126 7.5 8.4126Z"
					fill="#595959"
				/>
			</g>
		</svg>
	)
}

export default TinyLocation
