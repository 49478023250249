import { DetailedHTMLProps, HTMLAttributes, ReactNode, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

type SkeletonProps = {
	loading?: boolean
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const Skeleton = ({ children, className, loading, ...props }: SkeletonProps): ReactNode => {
	if (loading) {
		return (
			<div className={classNames('relative', className)} {...props}>
				{/* Skeleton Overlay */}
				<div className="absolute h-full w-full animate-pulse rounded-lg bg-grayscale-500" />
				{/* Reliant on the height and width of the `children` elements, but can be overriden via `className`. */}
				<div className="invisible">{children}</div>
			</div>
		)
	}
	return children
}

const MemoisedSkeleton = memo(Skeleton)

export default MemoisedSkeleton
