import { PropsWithChildren, ReactNode, useEffect } from 'react'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { useUserSsoLoginMutation } from 'src/_shared/mutations/auth'

const SSO_TOKEN_KEY = 'sso_token'

/**
 * Handles SSO Login using the provided `sso_token` query parameter.
 */
const AuthSsoWrapper = ({ children }: PropsWithChildren): ReactNode => {
	const { isAuthenticated, setAuthTokens } = useAuthContext()

	const { mutateAsync: ssoLogin, status: userSsoLoginMutationStatus } = useUserSsoLoginMutation()

	const ssoTokenQueryParameter = new URL(window.location.href).searchParams.get(SSO_TOKEN_KEY)

	const isSsoLoading =
		!isAuthenticated &&
		!!ssoTokenQueryParameter &&
		['idle', 'pending'].includes(userSsoLoginMutationStatus)

	useEffect((): void => {
		if (!isAuthenticated && !!ssoTokenQueryParameter) {
			void ssoLogin(
				{
					ssoToken: ssoTokenQueryParameter
				},
				{
					onSuccess: ({ token, refreshToken }): void => {
						setAuthTokens(token, refreshToken)
					}
				}
			)
		}
	}, [isAuthenticated, ssoTokenQueryParameter, setAuthTokens, ssoLogin])

	if (isSsoLoading) {
		return null
	}
	return children
}

export default AuthSsoWrapper
