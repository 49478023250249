import { MouseEvent, memo, useCallback, useState } from 'react'
import Input, { InputProps } from 'src/_shared/components/Input'
import EyeIcon from 'src/_shared/components/_icons/EyeIcon'
import EyeSlashedIcon from 'src/_shared/components/_icons/EyeSlashedIcon'

type InputPasswordProps = Omit<InputProps, 'endAdornment' | 'ref' | 'type'>

const InputPassword = (props: InputPasswordProps): JSX.Element => {
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

	const togglePasswordVisibility = useCallback((event: MouseEvent<HTMLDivElement>): void => {
		// Prevent default form submission behaviour.
		event.preventDefault()
		setIsPasswordVisible((isVisible): boolean => !isVisible)
	}, [])

	return (
		<Input
			{...props}
			type={isPasswordVisible ? 'text' : 'password'}
			endAdornment={
				<div className="cursor-pointer p-1" onClick={togglePasswordVisibility}>
					{isPasswordVisible ? (
						<EyeSlashedIcon className="h-6 w-6 text-typography-secondary" />
					) : (
						<EyeIcon className="h-6 w-6 text-typography-secondary" />
					)}
				</div>
			}
		/>
	)
}

const MemoisedInputPassword = memo(InputPassword)

export default MemoisedInputPassword
