import { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import ArrowDown from 'src/_shared/_old/assets/svgs/ArrowDown.svg'
import ArrowUp from 'src/_shared/_old/assets/svgs/ArrowUp.svg'
import Directions from 'src/_shared/_old/assets/svgs/Directions'
import TinyClock from 'src/_shared/_old/assets/svgs/TinyClock'
import TinyFlashCircle from 'src/_shared/_old/assets/svgs/TinyFlashCircle'
import TinyLocation from 'src/_shared/_old/assets/svgs/TinyLocation'
import QRScanner from 'src/_shared/_old/assets/svgs/scanner.svg'
import { filterCDGEAddressName, filterSPDirectionsString } from 'src/_shared/_old/utils/format'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'

import { FILTER_BAR_HEIGHT, LIVE_SESSION_HEIGHT, PowerType, TOPBAR_HEIGHT } from '../../enums'
import useHeight from '../../hooks/useHeight'
import useSubscriptionStatus from '../../hooks/useSubscriptionStatus'
import { useTouchInteraction } from '../../hooks/useTouchInteraction'
import { Coordinates } from '../../schemas/geolocation'
import { CpoLocationPair, OmniLocation } from '../../schemas/typings'
import { getConnectorCounts, getLocationDetails } from '../../utils/charging'
import { filterEvse } from '../../utils/filter'
import { shareLocationUrl } from '../../utils/redirects'
import CarparkRates from '../charging/CarparkRates'
import ChargerButton from '../charging/ChargerButton'

interface Props {
	onStopTouchProp: (bool: boolean) => void
	powerTypeFilter: PowerType | null
	showCarparkRates: () => void
	selectedLocation: OmniLocation
	cpoLocations: CpoLocationPair
	currentCoords: Coordinates
	onClose: () => void
	isAuthenticated: boolean
	isLiveSession: boolean
}
interface ChargerCountsProp {
	total: number
	available: number
	label: string
	bgColor: string
}

// SHELL Recharge hasn't been integrated yet
const CPO_SUPPORT_QR: string[] = [
	'SP Mobility',
	'Keppel Volt',
	'Charge+',
	'ChargEco',
	'MEA',
	'Sharge'
]

// Using the image height of `ArrowDown` and `ArrowUp` for some reference.
const SCROLL_ARROW_VISIBLE_THRESHOLD = 28

const ChargerCounts = ({ total, available, label, bgColor }: ChargerCountsProp) => (
	<div className="mr-12 flex font-semibold">
		<div
			className={`${bgColor} mr-[8px] h-[25px] w-[59px] rounded-[6px] text-center font-medium text-white`}
		>
			{label}
		</div>
		<span className={available === 0 ? 'text-error-300' : ''}>{available}</span>/{total}
	</div>
)

const LocationDetailsPopup = ({
	currentCoords,
	onStopTouchProp,
	showCarparkRates,
	selectedLocation,
	powerTypeFilter,
	onClose,
	cpoLocations,
	isAuthenticated,
	isLiveSession
}: Props) => {
	const { user } = useAuthContext()
	const { subscribedCpoEntities } = user ?? {}
	const isSubscription = useSubscriptionStatus(
		selectedLocation.entity_code,
		subscribedCpoEntities ?? []
	)
	const { ref: topRef, height: topHeight } = useHeight()
	const { ref: botRef, height: botHeight } = useHeight()
	const { opening, distance } = getLocationDetails(selectedLocation, currentCoords)

	const chargerListContainer = useRef<HTMLDivElement | null>(null)
	const [isAtBottom, setIsAtBottom] = useState(false)
	const [isAtTop, setIsAtTop] = useState(true)
	const [isScrollable, setIsScrollable] = useState(false)
	const LIVE_BUTTON_OFFSET = isLiveSession ? LIVE_SESSION_HEIGHT : 0

	const navigate = useRouterNavigate()

	useEffect(() => {
		const container = chargerListContainer.current
		const handleScroll = () => {
			if (container) {
				const scrollHeight = container.scrollHeight
				const scrollTop = container.scrollTop
				const clientHeight = container.clientHeight
				setIsAtTop(scrollTop <= SCROLL_ARROW_VISIBLE_THRESHOLD)
				setIsAtBottom(scrollHeight - scrollTop <= clientHeight + SCROLL_ARROW_VISIBLE_THRESHOLD)
			}
		}

		container?.addEventListener('scroll', handleScroll)

		return () => {
			container?.removeEventListener('scroll', handleScroll)
		}
	}, [])

	useEffect(() => {
		const container = chargerListContainer.current
		setIsAtBottom(false)
		if (container) {
			container.scrollTop = 0
			setIsScrollable(container.scrollHeight > container.clientHeight)
		}
	}, [topHeight, botHeight, selectedLocation])

	const sortedEvses = useMemo(() => {
		const filteredEvses = filterEvse(selectedLocation.evses, powerTypeFilter)
		return (
			filteredEvses
				// FUTURE TODO: BE should sort the connectors returned.
				// Note: Sort connectors by `connector_id` first. Currently,
				// there is no field we can use to sort connectors by their
				// "on-site/physical" order.
				.sort((a, b) => {
					const propertyA = a.physical_reference || a.evse_id
					const propertyB = b.physical_reference || b.evse_id
					return propertyA > propertyB ? 1 : propertyB > propertyA ? -1 : 0
				})
		)
	}, [selectedLocation, powerTypeFilter])
	const { onTouchStart, onTouchMove, onTouchEnd } = useTouchInteraction()

	const { totalACCount, totalDCCount, availableACCount, availableDCCount } = getConnectorCounts(
		selectedLocation.evses,
		selectedLocation.operator.name
	)

	// Set `body` `overflow` to `hidden` when the container is open
	// since the style sheet no longer has it to facilitate the new `ScreenContainer`.
	// FUTURE TODO: Rewrite Popup Container
	useEffect(() => {
		document.body.style.setProperty('overflow', 'hidden')
		return () => {
			document.body.style.setProperty('overflow', null)
		}
	}, [])

	return (
		<div
			className="animate-slide-up relative z-10 flex w-full flex-col rounded-t-lg"
			style={{
				height: window.innerHeight - (TOPBAR_HEIGHT + FILTER_BAR_HEIGHT + LIVE_BUTTON_OFFSET)
			}}
		>
			{/* heading */}
			<div
				ref={topRef}
				className="absolute flex w-full flex-col space-y-1 rounded-t-lg bg-white px-4 pb-3"
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={onTouchEnd}
				onTouchStartCapture={onTouchStart}
			>
				{/* line decoration */}
				<div className="flex w-full items-center justify-center py-2">
					<div className="h-[2.5px] w-16 rounded-full bg-grayscale-500"></div>
				</div>

				{/* close button */}
				<div className="!-mt-3 ml-auto">
					<AiOutlineClose onClick={onClose} className="text-xl text-typography-tertiary" />
				</div>

				{/* heading logo & carpark rates */}
				<div className="flex w-full flex-col items-start justify-center bg-white">
					<div className="flex w-full flex-col space-y-2">
						<div className="flex flex-row items-center justify-between text-typography-primary">
							<img
								src={selectedLocation.operator.logo}
								className="h-[45px] max-w-[90px] object-contain"
							/>
							<div className="items-baseline">
								<CarparkRates onClick={showCarparkRates} />
							</div>
						</div>
					</div>
				</div>

				{/* heading text */}
				<div className="flex flex-col space-y-2 bg-white">
					<p className="text-sm font-medium text-typography-primary">{selectedLocation.name}</p>
					<p className="text-xs text-typography-primary">
						{filterCDGEAddressName(selectedLocation.address) +
							(selectedLocation.operator.name === 'SP Mobility' &&
							selectedLocation.directions.length > 0
								? filterSPDirectionsString(selectedLocation.directions[0])
								: '')}
					</p>
					<div className="flex flex-row items-center space-x-3">
						{!!currentCoords.lat && !!currentCoords.lng && (
							<div className="flex flex-row items-center justify-center space-x-[2px]">
								<TinyLocation className="h-4 w-auto" />
								<p className="text-xs text-typography-primary">{distance}</p>
							</div>
						)}
						{opening && (
							<div className="flex flex-row items-center justify-center space-x-[2px]">
								<TinyClock className="h-4 w-auto" />
								<p className="text-xs text-typography-primary">24 Hours</p>
							</div>
						)}
						<div className="flex flex-row items-center justify-center space-x-[2px]">
							<TinyFlashCircle className="h-4 w-auto text-success-400" />
							<p className="text-xs font-semibold text-success-400">Available</p>
						</div>
						<div className="flex flex-row items-center justify-center space-x-[2px]">
							<TinyFlashCircle className="h-4 w-auto text-error-300" />
							<p className="text-xs font-semibold text-error-300">Unavailable</p>
						</div>
					</div>
				</div>

				<div className="!mt-3 flex">
					{totalACCount > 0 && (
						<ChargerCounts
							total={totalACCount}
							available={availableACCount}
							label={PowerType.AC}
							bgColor="bg-black"
						/>
					)}
					{totalDCCount > 0 && (
						<ChargerCounts
							total={totalDCCount}
							available={availableDCCount}
							label={PowerType.DC}
							bgColor="bg-grayscale-500"
						/>
					)}
				</div>
			</div>

			{/* Chargers List */}
			<div
				ref={chargerListContainer}
				style={{ marginTop: topHeight, marginBottom: botHeight }}
				className="flex w-full flex-col overflow-y-auto bg-white"
			>
				{/* Scroll-Up Directional Arrow */}
				<div
					className={`sticky top-0 flex items-center justify-center bg-white ${
						isScrollable && !isAtTop ? 'visible' : 'hidden'
					}`}
				>
					<img src={ArrowUp} />
				</div>
				{/* Chargers */}
				{sortedEvses.length !== 0 ? (
					<div
						className="flex w-full flex-col"
						onTouchStart={() => {
							onStopTouchProp(true)
						}}
						onTouchMove={() => {
							onStopTouchProp(true)
						}}
						onTouchEnd={() => {
							onStopTouchProp(false)
						}}
						onTouchStartCapture={() => {
							onStopTouchProp(true)
						}}
					>
						{sortedEvses.map(
							(evse) =>
								evse.connectors.length > 0 &&
								evse.connectors.map((connector, idx) => (
									<div key={idx} className="w-full">
										<ChargerButton
											index={idx}
											locationId={selectedLocation.uid}
											isSubscription={isSubscription}
											evse={evse}
											connector={connector}
											operatorName={selectedLocation.operator.name}
											cpoEntityCode={selectedLocation.entity_code}
											priceModifiers={connector.active_tariff?.price_modifiers ?? []}
											isAuthenticated={isAuthenticated}
										/>
									</div>
								))
						)}
					</div>
				) : (
					<div className="flex w-full items-center justify-center bg-white pb-7 pt-6">
						<p className="text-sm font-medium text-typography-secondary">No chargers found</p>
					</div>
				)}
				<div
					className={`sticky bottom-0 flex items-center justify-center bg-white ${
						isScrollable && !isAtBottom ? 'visible' : 'hidden'
					}`}
				>
					<img src={ArrowDown} />
				</div>
			</div>

			{/* Directions and Scan QR Buttons */}
			<div ref={botRef} className="absolute bottom-0 w-full bg-white px-4 py-3 outline-none">
				{!CPO_SUPPORT_QR.includes(selectedLocation.operator.name) ? (
					<>
						{/* if CPO doesn't support qr code scanning */}
						<button
							className="flex w-full flex-row items-center justify-center space-x-2 rounded-xl bg-primary-800 py-4"
							onClick={() => {
								shareLocationUrl(selectedLocation)
							}}
						>
							<Directions className="h-6 w-auto text-white" />
							<p className="text-base font-medium text-white">Directions</p>
						</button>
					</>
				) : (
					<>
						{/* if CPO does support qr code scanning */}
						<div className="flex flex-row space-x-2 px-4">
							<button
								className="border-1 flex flex-1 flex-row items-center justify-center space-x-2 rounded-md border border-primary-800 px-4 py-3 text-primary-800"
								onClick={() => {
									shareLocationUrl(selectedLocation)
								}}
							>
								<Directions className="h-6 w-auto" />
								<p>Directions</p>
							</button>

							<button
								className="flex flex-1 flex-row items-center justify-center space-x-2 rounded-md bg-primary-800 px-4 py-3 text-white"
								onClick={() => {
									navigate({
										pathname: ScreenRoutePath.CheckInQrScanner,
										search: `cpoEntityCodes=${cpoLocations.cpoEntityCode}`
									})
								}}
							>
								<img src={QRScanner} />
								<p>Scan QR Code</p>
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default LocationDetailsPopup
