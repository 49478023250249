import { CountryCode } from 'src/_shared/_old/schemas/typings'

export const FILTER_SETTINGS = {
	minDistance: 0, // km
	maxDistance: 50, // km
	minPrice: 0, // $
	maxPrice: 5 // $
}

export const LAST_APPLIED_FILTERS_KEY = 'lastAppliedFilters'

export const GOOGLE_BLUE_MARKER_COLOR = 'bg-[#4285F4]'

export const MARKER_COLORS = {
	available: '#07C203',
	unavailable: '#EB5757'
}

export const BEEP_THEME_COLORS = {
	main: '6 138 141',
	primary: '32 32 32'
}

export const BEEP_TOPBAR = {
	backgroundColor: '255 255 255',
	itemColor: '32 32 32',
	logoTheme: 'dark',
	logoHeight: '1.5rem'
}

export const LIVE_SESSION_HEIGHT: number = 10 * 6

export const TOPBAR_HEIGHT: number = 10 * 4

export const BOTTOMBAR_HEIGHT: number = 10 * 4

export const FILTER_BAR_HEIGHT = 45

export const SEARCH_BAR_HEIGHT = 50

export const HISTORY_SORT_OPTIONS = [
	{
		display: 'Recently added',
		ref: 'RECENTLY_ADDED'
	},
	{
		display: 'Total Cost: Low to High',
		ref: 'LOW_TO_HIGH'
	},
	{
		display: 'Total Cost: High to Low',
		ref: 'HIGH_TO_LOW'
	}
]

export const COUNTRY_CODE_OPTIONS: CountryCode[] = [
	{
		fullName: 'Singapore',
		shortName: 'sg',
		code: '+65',
		emoji: '🇸🇬',
		display: 'Singapore (+65)',
		emojiCodeDisplay: '🇸🇬 +65',
		emojiNameDisplay: '🇸🇬 Singapore'
	},
	{
		fullName: 'Malaysia',
		shortName: 'my',
		code: '+60',
		emoji: '🇲🇾',
		display: 'Malaysia (+60)',
		emojiCodeDisplay: '🇲🇾 +60',
		emojiNameDisplay: '🇲🇾 Malaysia'
	},
	{
		fullName: 'Thailand',
		shortName: 'th',
		code: '+66',
		emoji: '🇹🇭',
		display: 'Thailand (+66)',
		emojiCodeDisplay: '🇹🇭 +66',
		emojiNameDisplay: '🇹🇭 Thailand'
	},
	{
		fullName: 'Philippines',
		shortName: 'ph',
		code: '+63',
		emoji: '🇵🇭',
		display: 'Philippines (+63)',
		emojiCodeDisplay: '🇵🇭 +63',
		emojiNameDisplay: '🇵🇭 Philippines'
	},
	{
		fullName: 'Hong Kong',
		shortName: 'hk',
		code: '+852',
		emoji: '🇭🇰',
		display: 'Hong Kong (+852)',
		emojiCodeDisplay: '🇭🇰 +852',
		emojiNameDisplay: '🇭🇰 Hong Kong'
	}
]

export const FLEET_OPTIONS = [{ label: 'SEV', value: 'SEV' }]

export enum SortOptions {
	RECENTLY_ADDED = 'RECENTLY_ADDED',
	LOW_TO_HIGH = 'LOW_TO_HIGH',
	HIGH_TO_LOW = 'HIGH_TO_LOW'
}

export enum SessionStage {
	PARKING_REGULAR = 'PARKING_REGULAR',
	PARKING_GRACE = 'PARKING_GRACE',
	PARKING_BLACKOUT = 'PARKING_BLACKOUT',
	NOT_SPECIFIED = 'NOT_SPECIFIED'
}

export enum InternalStateEnums {
	LOADING_DATA = 'LoadingData',
	IDLE_AVAILABLE_STATE = 'IdleAvailableState',
	IDLE_PREPARING_STATE = 'IdlePreparingState',
	STARTING_STATE = 'StartingState',
	PAYING_STATE = 'PayingState',
	UNAVAILABLE_STATE = 'UnavailableState',
	CHARGING_STATE = 'ChargingState',
	FAILED_TO_START_STATE = 'FailedToStartState',
	STOPPING_STATE = 'StoppingState',
	FINISHING_STATE = 'FinishingState',
	FAILED_TO_STOP_STATE = 'FailedToStopState',
	GRACE_PERIOD_STATE = 'GracePeriodState',
	IDLING_STATE = 'IdlingState',
	BLACKOUT_STATE = 'BlackoutState',
	RECEIPT_STATE = 'ReceiptState'
}

export enum ChargingSessionState {
	PENDING = 'PENDING',
	ACTIVE = 'ACTIVE',
	STOPPED = 'STOPPED',
	COMPLETED = 'COMPLETED',
	START_FAILURE = 'START_FAILURE',
	STOP_FAILURE = 'STOP_FAILURE',
	PENDING_PAYMENT = 'PENDING_PAYMENT'
}

export enum ConnectorState {
	AVAILABLE = 'AVAILABLE',
	PREPARING = 'PREPARING',
	CHARGING = 'CHARGING',
	FINISHING = 'FINISHING',
	RESERVED = 'RESERVED',
	INOPERATIVE = 'INOPERATIVE',
	UNKNOWN = 'UNKNOWN',
	OUTOFORDER = 'OUTOFORDER',
	PLANNED = 'PLANNED',
	REMOVED = 'REMOVED',
	FAULTED = 'FAULTED'
}

export enum BadgeType {
	Error = 'error',
	Warning = 'warning',
	InfoOrange = 'orange-400'
}

export enum IconTypes {
	LOADING = 'LOADING',
	ERROR = 'ERROR',
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS'
}

export enum PaymentMethodCodes {
	BEEP = 'BEEP',
	STRIPE = 'STRIPE',
	SUBSCRIPTION = 'SUBSCRIPTION',
	FREE_OF_CHARGE = 'FREE_OF_CHARGE'
}

export enum PowerType {
	AC = 'AC',
	DC = 'DC'
}

export enum SubscriptionType {
	SUBSCRIBED = 'SUBSCRIBED',
	UNSUBSCRIBED = 'UNSUBSCRIBED'
}

export enum FilterType {
	ALL = 'ALL',
	CPO = 'CPO',
	AC = 'AC',
	DC = 'DC'
}

export enum PriceModifierType {
	DISCOUNT_FLAT = 'DISCOUNT_FLAT',
	DISCOUNT_PERCENTAGE = 'DISCOUNT_PERCENTAGE',
	MARKUP_FLAT = 'MARKUP_FLAT',
	MARKUP_PERCENTAGE = 'MARKUP_PERCENTAGE'
}

export enum CDRDimensionType {
	TIME = 'TIME',
	PARKING_TIME = 'PARKING_TIME',
	RESERVATION_TIME = 'RESERVATION_TIME',
	ENERGY = 'ENERGY'
}

export enum DayOfWeek {
	SUNDAY = 'SUNDAY',
	MONDAY = 'MONDAY',
	TUESDAY = 'TUESDAY',
	WEDNESDAY = 'WEDNESDAY',
	THURSDAY = 'THURSDAY',
	FRIDAY = 'FRIDAY',
	SATURDAY = 'SATURDAY'
}
