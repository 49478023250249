import { useCallback, useEffect, useState } from 'react'

/**
 * Counts down from a specified number of seconds.
 * @param {number} initialSeconds The number of seconds to start counting down from. If this is not provided, it defaults to `60`.
 * @returns {[number, (seconds: number) => void]} The seconds left and the function to modify that value.
 * If the function value is not provided, it defaults to `initialSeconds`.
 */
const useCountdownTimer = (initialSeconds = 60): [number, (seconds?: number) => void] => {
	const [seconds, setSeconds] = useState<number>(initialSeconds)

	const setSecondsLeft = useCallback(
		(value = initialSeconds): void => {
			setSeconds(value)
		},
		[initialSeconds]
	)

	useEffect((): (() => void) => {
		const intervalId = setInterval((): void => {
			setSeconds((secondsLeft): number => {
				if (secondsLeft > 0) {
					return secondsLeft - 1
				}
				return 0
			})
		}, 1000)
		return (): void => {
			clearInterval(intervalId)
		}
	}, [])

	return [seconds, setSecondsLeft]
}

export default useCountdownTimer
