import { BsTriangleFill } from 'react-icons/bs'
import { IoSearchSharp } from 'react-icons/io5'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'

interface Props {
	lat: number
	lng: number
	toZoomIn: (searchLocation: Coordinates) => void
}

const SEARCH_LOCATION_PIN_Z_INDEX = 30

const SearchLocationPin = (props: Props) => {
	const { lat, lng } = props
	return (
		<button
			className="absolute flex -translate-x-1/2 -translate-y-full transform flex-col items-center justify-center outline-none drop-shadow-lg focus:outline-none active:outline-none"
			ref={(buttonRef) => {
				if (buttonRef?.parentElement) {
					const parentDivElement = buttonRef.parentElement as HTMLDivElement
					parentDivElement.style.zIndex = String(SEARCH_LOCATION_PIN_Z_INDEX)
				}
			}}
			onClick={() => {
				props.toZoomIn({ lat, lng })
			}}
		>
			<div className="flex flex-row items-center justify-center rounded-lg bg-blue-400 px-2 py-2">
				<IoSearchSharp className="h-4 w-4 text-white" />
			</div>
			<div className="mt-[-2px] rotate-180">
				<BsTriangleFill className={`h-3 w-auto text-blue-400`} />
			</div>
		</button>
	)
}

export default SearchLocationPin
