import { useCallback, useMemo, useState } from 'react'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import { classNames } from 'src/_shared/utils/elements'

import EnterOtpView from './components/EnterOtpView'
import RegisterView from './components/RegistrationView'
import { AccountRegistrationScreenViewItemKey, FormFieldKey } from './enums'
import { AccountRegistrationScreenViewItem } from './types'

const AccountRegistrationScreen = (): JSX.Element => {
	const [viewItemKey, setViewItemKey] = useState<AccountRegistrationScreenViewItemKey>(
		AccountRegistrationScreenViewItemKey.RegistrationView
	)
	const [shouldSendOtp, setShouldSendOtp] = useState<boolean>(false)

	const [formValues, setFormValues] = useState<Record<FormFieldKey, string>>({
		[FormFieldKey.CountryCode]: '+65',
		[FormFieldKey.MobileNumber]: '',
		[FormFieldKey.Name]: '',
		[FormFieldKey.Password]: '',
		[FormFieldKey.ConfirmPassword]: '',
		[FormFieldKey.Email]: '',
		[FormFieldKey.LicensePlateNumber]: '',
		[FormFieldKey.AcceptTermsAndConditions]: 'false',
		[FormFieldKey.Otp]: ''
	})

	const updateView = useCallback((nextKey: AccountRegistrationScreenViewItemKey): void => {
		setViewItemKey((previousKey): AccountRegistrationScreenViewItemKey => {
			console.debug(`[AccountRegistrationScreen] Update View: "${previousKey}" > "${nextKey}"`)
			return nextKey
		})
	}, [])

	const viewItemsMap = useMemo((): Record<
		AccountRegistrationScreenViewItemKey,
		AccountRegistrationScreenViewItem
	> => {
		return {
			[AccountRegistrationScreenViewItemKey.RegistrationView]: {
				view: (
					<RegisterView
						formValues={formValues}
						setFormValues={setFormValues}
						shouldSendOtp={shouldSendOtp}
						setShouldSendOtp={setShouldSendOtp}
						onNext={(): void => {
							updateView(AccountRegistrationScreenViewItemKey.EnterOtpView)
						}}
					/>
				)
			},
			[AccountRegistrationScreenViewItemKey.EnterOtpView]: {
				view: (
					<EnterOtpView
						formValues={formValues}
						setFormValues={setFormValues}
						shouldSendOtp={shouldSendOtp}
						setShouldSendOtp={setShouldSendOtp}
					/>
				)
			}
		}
	}, [formValues, updateView, shouldSendOtp])

	const currentViewItem = viewItemsMap[viewItemKey]

	return (
		<ScreenContainer
			contentViewProps={{
				className: classNames(
					'px-5',
					viewItemKey === AccountRegistrationScreenViewItemKey.RegistrationView ? 'pt-6' : 'py-6'
				)
			}}
			hideBottomBar
		>
			{currentViewItem.view}
		</ScreenContainer>
	)
}

export default AccountRegistrationScreen
