import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import useLanguage from 'src/_shared/hooks/useLanguage'
import { parseMarkdown } from 'src/_shared/utils/markdown'
import { useStrapiTermsAndConditionsQuery } from 'src/screens/TermsAndConditionsScreen/queries'

const TermsConditionsScreen = () => {
	const locale = useLanguage()

	const { data: termsAndConditions = '', isLoading: isStrapiQueryLoading } =
		useStrapiTermsAndConditionsQuery(
			{
				locale
			},
			{
				select: (data) => {
					const termsAndConditionsData = data.attributes.translations
					if (!termsAndConditionsData) return ''

					return parseMarkdown(termsAndConditionsData)
				}
			}
		)

	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			{isStrapiQueryLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<div
					className="markdown terms-and-conditions space-y-3"
					dangerouslySetInnerHTML={{ __html: termsAndConditions }}
				/>
			)}
		</ScreenContainer>
	)
}

export default TermsConditionsScreen
