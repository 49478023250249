import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { CPO_BACKEND_URL } from '../constants/env'
import { OmniSessionStatus } from '../enums/omni'
import { OmniConnector, OmniEvse } from '../types/omni'
import { OmniLocation } from '../types/omni/location'

export const ROOT_LOCATIONS_QUERY_KEY = 'Locations'

export enum LocationsQueryKey {
	Location = 'Location',
	LocationEvse = 'LocationEvse',
	LocationEvseConnector = 'LocationEvseConnector',
	LocationEvseConnectorCurrentSession = 'LocationEvseConnectorCurrentSession',
	Locations = 'Locations'
}

interface LocationQueryParams {
	locationUid: string
}

export const useLocationQuery = <TData = OmniLocation>(
	params: LocationQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniLocation,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.Location, LocationQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Location, params],
		queryFn: async (): Promise<OmniLocation> => {
			try {
				const { locationUid } = params
				const response = await axios.get<OmniLocation>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseQueryParams extends LocationQueryParams {
	evseUid: string
}

export const useLocationEvseQuery = <TData = OmniEvse>(
	params: LocationEvseQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniEvse,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.LocationEvse, LocationEvseQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.LocationEvse, params],
		queryFn: async (): Promise<OmniEvse> => {
			try {
				const { locationUid, evseUid } = params
				const response = await axios.get<OmniEvse>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseConnectorQueryParams extends LocationEvseQueryParams {
	connectorUid: string
}

export const useLocationEvseConnectorQuery = <TData = OmniConnector>(
	params: LocationEvseConnectorQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniConnector,
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.LocationEvseConnector, LocationEvseConnectorQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.LocationEvseConnector, params],
		queryFn: async (): Promise<OmniConnector> => {
			try {
				const { locationUid, evseUid, connectorUid } = params
				const response = await axios.get<OmniConnector>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}/${connectorUid}`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}

interface LocationEvseConnectorCurrentSessionQueryParams {
	locationUid: string
	evseUid: string
	connectorUid: string
}

interface LocationEvseConnectorCurrentSessionData {
	_id: string
	uid: string
	status: OmniSessionStatus
	/**
	 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	start_date_time: string
	/**
	 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	end_date_time: string
}

export const useLocationEvseConnectorCurrentSessionQuery = <
	TData = LocationEvseConnectorCurrentSessionData | null
>(
	params: LocationEvseConnectorCurrentSessionQueryParams,
	options?: Omit<
		UseQueryOptions<
			LocationEvseConnectorCurrentSessionData | null,
			AxiosError<{ message: string }>,
			TData,
			[
				string,
				LocationsQueryKey.LocationEvseConnectorCurrentSession,
				LocationEvseConnectorCurrentSessionQueryParams
			]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [
			ROOT_LOCATIONS_QUERY_KEY,
			LocationsQueryKey.LocationEvseConnectorCurrentSession,
			params
		],
		enabled: options?.enabled === undefined || options.enabled,
		queryFn: async (): Promise<LocationEvseConnectorCurrentSessionData | null> => {
			try {
				const { locationUid, evseUid, connectorUid } = params
				const response = await axios.get<LocationEvseConnectorCurrentSessionData>(
					`${CPO_BACKEND_URL}/v3/locations/${locationUid}/${evseUid}/${connectorUid}/current-session`
				)
				return response.data
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string } | null>
				if (axiosError.response?.status === 404 && axiosError.response.data === null) {
					return null
				}
				return Promise.reject(axiosError)
			}
		}
	})
}

// Note: Maybe it would be better for CPO Backend to just return all
// locations if limit and offset are not specified.
interface FetchAllParams {
	fetchAll: true
}

interface PaginationParams {
	limit: number
	offset: number
}

type LocationsQueryParams = FetchAllParams | PaginationParams

const MAX_LOCATIONS_LIMIT = 500

export const useLocationsQuery = <TData = OmniLocation[]>(
	params: LocationsQueryParams,
	options?: Omit<
		UseQueryOptions<
			OmniLocation[],
			AxiosError<{ message: string }>,
			TData,
			[string, LocationsQueryKey.Locations, LocationsQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_LOCATIONS_QUERY_KEY, LocationsQueryKey.Locations, params],
		queryFn: async (): Promise<OmniLocation[]> => {
			try {
				// Get all locations over several calls.
				if ('fetchAll' in params) {
					const locations: OmniLocation[] = []
					let response: AxiosResponse<{
						locations: OmniLocation[]
					}> | null = null
					let offset = 0
					do {
						response = await axios.get<{ locations: OmniLocation[] }>(
							`${CPO_BACKEND_URL}/v3/locations`,
							{
								params: {
									limit: MAX_LOCATIONS_LIMIT,
									offset
								}
							}
						)
						offset += MAX_LOCATIONS_LIMIT
						locations.push(...response.data.locations)
					} while (response.data.locations.length >= MAX_LOCATIONS_LIMIT)
					return locations
				}
				// Get locations within the specified limit and offset.
				else {
					const { limit, offset } = params
					const response = await axios.get<{ locations: OmniLocation[] }>(
						`${CPO_BACKEND_URL}/v3/locations`,
						{
							params: {
								limit,
								offset
							}
						}
					)
					return response.data.locations
				}
			} catch (error) {
				const axiosError = error as AxiosError<{ message: string }>
				return Promise.reject(axiosError)
			}
		}
	})
}
