/**
 * Countries are represented in their country codes.
 */
export enum OmniCountryCode {
	Malaysia = 'MY',
	Singapore = 'SG',
	Thailand = 'TH'
}

/**
 * Countries are represented in their country codes.
 */
export enum OmniCountryCodeUnsupported {
	Australia = 'AU',
	Brunei = 'BR',
	Cambodia = 'KH',
	China = 'CN',
	HongKong = 'HK',
	Indonesia = 'ID',
	Japan = 'JP',
	Korea = 'KR',
	Laos = 'LA',
	Myanmar = 'MM',
	NewZealand = 'NZ',
	Philippines = 'PH',
	TimorLeste = 'TL',
	UnitedStates = 'US',
	Vietnam = 'VN'
}

/**
 * Country currencies are represented in their currency codes.
 */
export enum OmniCountryCurrencyCode {
	Malaysia = 'MYR',
	Singapore = 'SGD',
	Thailand = 'THB'
}

/**
 * Country currencies are represented in their currency codes.
 */
export const OmniCountryCurrencyCodeUnsupported = {
	Australia: 'AUD',
	Brunei: 'BRD',
	Cambodia: 'KHR',
	China: 'CNY',
	HongKong: 'HKD',
	Indonesia: 'IDR',
	Japan: 'JPY',
	Korea: 'KRW',
	Laos: 'LAK',
	Myanmar: 'MMK',
	NewZealand: 'NZD',
	Philippines: 'PHP',
	/**
	 * Timor Leste also uses `'USD'`.
	 */
	TimorLeste: 'USD',
	UnitedStates: 'USD',
	Vietnam: 'VND'
}

export enum OmniDayOfWeek {
	Monday = 'MONDAY',
	Tuesday = 'TUESDAY',
	Wednesday = 'WEDNESDAY',
	Thursday = 'THURSDAY',
	Friday = 'FRIDAY',
	Saturday = 'SATURDAY',
	Sunday = 'SUNDAY'
}

export enum OmniDayNumberOfWeek {
	Monday = 1,
	Tuesday = 2,
	Wednesday = 3,
	Thursday = 4,
	Friday = 5,
	Saturday = 6,
	Sunday = 7
}

export enum OmniError {
	Internal = 'internal_error',
	PaymentInsufficientFunds = 'pay_insufficient_funds',
	PaymentExpiredCard = 'pay_expired_card',
	PaymentCardExceededLimit = 'pay_card_exceeded_limit',
	PaymentCurrencyNotSupported = 'pay_currency_not_supported',
	PaymentProcessing = 'pay_processing_error',
	PaymentGenericFailure = 'pay_generic_failure',
	Unknown = 'unknown_error'
}

export enum OmniProtocol {
	Ocpi = 'OCPI',
	Plugit = 'PLUGIT',
	StarCharge = 'STARCHARGE',
	Evcs = 'EVCS',
	BbThreeOcpp = 'BB3_OCPP',
	ModQtt = 'MODQTT',
	Mock = 'MOCK',
	Omni = 'OMNI',
	Virta = 'VIRTA',
	Steve = 'STEVE',
	ChargePlus = 'CHARGEPLUS',
	ChargEco = 'CHARGECO',
	OnGriz = 'ONGRIZ'
}
