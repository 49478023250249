import { SVGProps, memo } from 'react'

const BatteryGradientIcon = ({
	fill = 'none',
	stroke = 'none',
	viewBox = '0 0 111 177',
	xmlns = 'http://www.w3.org/2000/svg',
	lightningFill = '#FFFFFF',
	gradientFrom = '#00D48E',
	gradientTo = '#00D48E',
	...props
}: SVGProps<SVGSVGElement> & {
	lightningFill?: string
	gradientFrom?: string
	gradientTo?: string
}) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			{/* Tip */}
			<path
				d="M41 5.36257C41 2.40091 43.4009 0 46.3626 0H63.7172C66.6789 0 69.0798 2.40091 69.0798 5.36257C69.0798 8.32424 66.6789 10.7251 63.7172 10.7251H46.3626C43.4009 10.7251 41 8.32424 41 5.36257Z"
				fill="url(#gradientTip)"
			/>
			{/* Body */}
			<path
				d="M0 39.24C0 26.9572 9.95719 17 22.24 17H88.76C101.043 17 111 26.9572 111 39.24V154.755C111 167.041 101.041 177 88.7552 177H22.2448C9.95936 177 0 167.041 0 154.755V39.24Z"
				fill="url(#gradientBody)"
			/>
			{/* Lightning */}
			<path
				d="M68.344 84.3723L59.1281 81.9498L63.9993 65.6997C64.2941 64.7093 63.7953 63.6662 62.8103 63.2156C61.8232 62.765 60.6367 63.0392 59.9879 63.8659L41.4411 87.4858C41.001 88.0488 40.8805 88.7755 41.1236 89.4367C41.3641 90.0975 41.9379 90.6036 42.6549 90.7924L51.8708 93.2148L46.4486 111.301C46.1493 112.3 46.6618 113.352 47.6599 113.796C47.9754 113.934 48.3066 114 48.6333 114C49.3524 114 50.0532 113.679 50.4913 113.097L69.5878 87.641C70.0122 87.0756 70.114 86.3555 69.8694 85.7052C69.6243 85.0549 69.0551 84.5597 68.3445 84.3727L68.344 84.3723Z"
				fill={lightningFill}
			/>
			<defs>
				<linearGradient
					id="gradientTip"
					x1="-21.1926"
					y1="4.0598"
					x2="21.7809"
					y2="4.0598"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
				<linearGradient
					id="gradientBody"
					x1="-85.3579"
					y1="61.435"
					x2="84.517"
					y2="61.435"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
			</defs>
		</svg>
	)
}

const MemoisedBatteryGradientIcon = memo(BatteryGradientIcon)

export default MemoisedBatteryGradientIcon
