function LocationSlash({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 135 135" fill="none">
			<path
				opacity="0.4"
				d="M106.256 28.7438L32.5687 102.431C21.9375 87.975 14.2875 69.525 19.0125 48.8812C21.9375 35.8875 29.1375 26.4937 38.025 20.3062C46.9125 14.175 57.5437 11.25 67.5 11.25C81.225 11.25 96.3 16.7625 106.256 28.7438Z"
				fill="currentColor"
			/>
			<path
				opacity="0.4"
				d="M88.7626 118.012C82.8001 123.75 75.1501 126.619 67.5001 126.619C59.8501 126.619 52.2001 123.694 46.2376 117.956C44.2126 116.044 42.1876 113.906 40.1626 111.712L112.725 39.1499C114.131 42.1874 115.2 45.3937 115.988 48.9374C122.569 77.9624 104.794 102.487 88.7626 118.012Z"
				fill="#D0D0D0"
			/>
			<path
				d="M122.456 12.5437C120.769 10.7999 118.069 10.7999 116.381 12.5437L84.4874 44.4374L80.4374 48.4874C77.3999 44.3249 72.6186 41.8499 67.4999 41.8499C58.6124 41.8499 51.3562 49.1062 51.3562 57.9937C51.3562 63.0562 53.8312 67.8374 57.9937 70.9311L12.5437 116.381C10.7999 118.069 10.7999 120.769 12.5437 122.456C13.3874 123.3 14.4562 123.75 15.5812 123.75C16.7062 123.75 17.7749 123.3 18.6187 122.456L122.456 18.6187C124.2 16.9312 124.2 14.2312 122.456 12.5437Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default LocationSlash
