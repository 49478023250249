import { SVGProps, memo } from 'react'

const TelegramIcon = ({
	fill = '#FFFFFF',
	viewBox = '0 0 512 512',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
				<path d="M2370 5113 c-379 -36 -661 -116 -980 -278 -378 -193 -717 -497 -965 -865 -104 -156 -232 -419 -294 -605 -49 -150 -89 -321 -113 -490 -17 -118 -17 -512 0 -630 42 -295 120 -553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92 309 -137 500 -185 500 -126 1002 -102 1490 71 150 53 408 183 540 271 302 202 573 480 769 788 72 113 188 353 235 486 235 662 194 1372 -115 1993 -124 250 -263 447 -458 648 -214 222 -430 379 -711 518 -296 146 -572 225 -900 255 -102 9 -333 11 -415 3z m545 -342 c634 -107 1171 -457 1523 -995 170 -258 286 -563 339 -891 24 -153 24 -497 0 -650 -108 -668 -474 -1222 -1042 -1580 -243 -153 -537 -261 -850 -312 -154 -24 -497 -24 -650 1 -497 81 -907 288 -1256 636 -345 343 -555 758 -636 1255 -24 153 -24 497 0 650 105 647 447 1183 983 1541 306 205 675 333 1054 368 108 10 418 -4 535 -23z" />
				<path d="M3635 3851 c-273 -103 -2697 -1057 -2720 -1070 -74 -43 -100 -151 -53 -221 16 -23 120 -95 333 -232 171 -109 327 -207 347 -218 63 -34 105 -27 272 46 218 95 209 94 144 27 -31 -32 -62 -71 -67 -86 -17 -46 -15 -709 3 -751 29 -68 115 -108 189 -86 34 10 76 46 225 193 l182 181 388 -192 c213 -106 398 -193 412 -192 41 1 105 35 129 69 19 27 56 216 239 1219 l216 1188 -23 47 c-40 83 -123 113 -216 78z m-139 -418 c-3 -10 -75 -405 -160 -878 -86 -473 -158 -870 -161 -882 -5 -21 -24 -13 -346 147 -211 106 -351 170 -370 170 -51 0 -95 -26 -178 -106 l-81 -77 0 84 0 84 330 330 c362 362 374 378 351 455 -25 83 -107 141 -178 124 -15 -4 -261 -108 -546 -231 -285 -123 -521 -223 -525 -221 -27 11 -272 171 -272 177 0 7 2113 840 2133 841 5 0 6 -8 3 -17z" />
			</g>
		</svg>
	)
}

const MemoisedTelegramIcon = memo(TelegramIcon)

export default MemoisedTelegramIcon
