import { PropsWithChildren } from 'react'
import { StrapiContext } from 'src/_shared/hooks/useStrapiContext'
import { useStrapiBrandQuery } from 'src/_shared/queries/strapi'

/**
 * Retrieves Brand-specific data from Strapi that will be used in the application.
 */
const StrapiBrandWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const { data: brandData = null, isLoading: isStrapiBrandLoading } = useStrapiBrandQuery({
		staleTime: Infinity
	})

	if (isStrapiBrandLoading) {
		return null
	}
	return <StrapiContext.Provider value={{ brandData }}>{children}</StrapiContext.Provider>
}

export default StrapiBrandWrapper
