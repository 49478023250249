function TinyFlashCircle({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 15 15" fill="none">
			<g opacity="0.5">
				<path
					opacity="0.4"
					d="M7.48126 13.75C10.933 13.75 13.7313 10.9518 13.7313 7.5C13.7313 4.04822 10.933 1.25 7.48126 1.25C4.02948 1.25 1.23126 4.04822 1.23126 7.5C1.23126 10.9518 4.02948 13.75 7.48126 13.75Z"
					fill="currentColor"
				/>
				<path
					d="M9.53123 7.06249H8.46873V4.58749C8.46873 4.01249 8.15623 3.89374 7.77498 4.32499L7.49998 4.63749L5.17498 7.28124C4.85623 7.64374 4.98748 7.93749 5.46873 7.93749H6.53123V10.4125C6.53123 10.9875 6.84373 11.1062 7.22498 10.675L7.49998 10.3625L9.82498 7.71874C10.1437 7.35624 10.0125 7.06249 9.53123 7.06249Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	)
}

export default TinyFlashCircle
