import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { CPO_BACKEND_URL } from 'src/_shared/constants/env'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import { OmniSession } from 'src/_shared/types/omni'

enum ChargingSessionHistoryQueryKey {
	UserChargingInfiniteSessionHistory = 'UserChargingInfiniteSessionHistory'
}

/* TData / TQueryFunction */
export interface ChargingSessionPage {
	data: OmniSession[]
	currentPage: number
	nextPage: number | null
}

/* Everything need to trigger the fetch except for TPageParam */
interface ChargingSessionQueryParams {
	sortBy?: string
	maxNumber: number
	sortDirection?: string
	dateFrom?: number
	dateTo?: number
}

interface ApiChargingSessionResponse {
	data: OmniSession[]
}

const fetchChargingSessionPage = async (
	params: ChargingSessionQueryParams,
	accessToken: string | null,
	pageNumber: number
): Promise<ChargingSessionPage> => {
	try {
		const res: ApiChargingSessionResponse = await axios.get<
			ChargingSessionQueryParams,
			ApiChargingSessionResponse
		>(`${CPO_BACKEND_URL}/v2/me/sessions`, {
			headers: { Authorization: accessToken },
			params: {
				sortBy: params.sortBy,
				limit: params.maxNumber,
				offset: pageNumber * params.maxNumber,
				sortDirection: params.sortDirection,
				dateFrom: params.dateFrom,
				dateTo: params.dateTo
			}
		})
		// if the data returned from the backend is lesser than the number of items to be specified in one page, there is no next page
		const hasNextPage: boolean = res.data.length === params.maxNumber
		const respPage: ChargingSessionPage = {
			data: res.data,
			currentPage: pageNumber,
			nextPage: hasNextPage ? pageNumber + 1 : null
		}

		return respPage
	} catch (error) {
		const axiosError = error as AxiosError<{ message: string }>
		return Promise.reject(axiosError)
	}
}

export const useChargingSessionInfiniteQuery = (
	params: ChargingSessionQueryParams
): UseInfiniteQueryResult<
	InfiniteData<ChargingSessionPage, number>,
	AxiosError<ChargingSessionQueryParams, ApiChargingSessionResponse>
> => {
	const { accessToken, isAuthenticated } = useAuthContext()

	const queryResult: UseInfiniteQueryResult<
		InfiniteData<ChargingSessionPage, number>,
		AxiosError<ChargingSessionQueryParams, ApiChargingSessionResponse>
	> = useInfiniteQuery<
		ChargingSessionPage,
		AxiosError<ChargingSessionQueryParams, ApiChargingSessionResponse>,
		InfiniteData<ChargingSessionPage, number>,
		[ChargingSessionHistoryQueryKey.UserChargingInfiniteSessionHistory, ChargingSessionQueryParams],
		number
	>({
		queryKey: [ChargingSessionHistoryQueryKey.UserChargingInfiniteSessionHistory, params],
		queryFn: async ({ pageParam }) =>
			await fetchChargingSessionPage(params, accessToken, pageParam),
		initialPageParam: 0,
		enabled: isAuthenticated,
		refetchOnWindowFocus: false,
		getNextPageParam: (lastPage) => lastPage.nextPage,
		maxPages: 0
	})

	return queryResult
}
