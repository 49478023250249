import { SVGProps, memo } from 'react'

const HourglassFilledIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 41 42',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<g clipPath="url(#clip0_2834_53168)">
				<path d="M26.3641 19.5539L29.765 16.3645C31.7821 14.4733 32.9269 11.8326 32.9269 9.06783V4.46767C32.9276 3.38755 32.4842 2.39614 31.7758 1.68992C31.0695 0.980731 30.0775 0.5381 28.9974 0.538819H12.0021C10.922 0.5381 9.93057 0.980731 9.22434 1.68984C8.51523 2.39614 8.0726 3.38747 8.07324 4.46759V9.06767C8.07324 11.8324 9.21739 14.4732 11.2345 16.3643L14.6353 19.5524C14.8233 19.7286 14.9293 19.9738 14.9293 20.2312V21.7685C14.9293 22.0259 14.8233 22.2704 14.636 22.4459L11.2345 25.6353C9.21739 27.5258 8.07324 30.1673 8.07324 32.932V37.532C8.07252 38.6122 8.51515 39.6036 9.22434 40.3099C9.93057 41.0189 10.922 41.4615 12.0021 41.4609H28.9973C30.0774 41.4615 31.0695 41.0189 31.7758 40.3099C32.4841 39.6035 32.9275 38.6122 32.9269 37.532V32.932C32.9269 30.1673 31.782 27.5257 29.765 25.6353L26.3641 22.4466C26.1768 22.2704 26.0701 22.0259 26.0701 21.7685V20.2312C26.0701 19.9738 26.1769 19.7287 26.3641 19.5539ZM29.9267 37.5322C29.926 37.7938 29.8256 38.0161 29.6548 38.189C29.482 38.3597 29.259 38.4609 28.9973 38.4616H12.0021C11.7405 38.4608 11.5181 38.3597 11.3447 38.189C11.1746 38.0161 11.0734 37.7938 11.0728 37.5322V32.9321C11.0728 30.9969 11.8741 29.1471 13.286 27.824L16.6869 24.6345C17.4793 23.8924 17.9288 22.8542 17.9288 21.7686V20.2313C17.9288 19.1449 17.4793 18.1068 16.6862 17.3646L13.286 14.1759C11.8741 12.8527 11.0728 11.0036 11.0728 9.06783V4.46767C11.0734 4.20607 11.1746 3.98372 11.3447 3.8102C11.5181 3.6402 11.7405 3.53893 12.0021 3.53829H28.9973C29.2589 3.53901 29.482 3.6402 29.6548 3.8102C29.8255 3.98372 29.926 4.20599 29.9267 4.46767V9.06775C29.9267 11.0036 29.1254 12.8527 27.7142 14.1758L24.3133 17.3646C23.5203 18.1068 23.0707 19.1448 23.0707 20.2312V21.7685C23.0707 22.8541 23.5203 23.8923 24.3133 24.6358L27.7142 27.8232C29.1255 29.147 29.9267 30.9969 29.9267 32.932V37.5322Z" />
				<path d="M20.4999 20.4144C21.3005 20.4144 21.9491 19.7653 21.9491 18.9647C22.1914 18.0015 22.7001 17.1135 23.4339 16.4264L26.8342 13.2383C27.9205 12.2202 28.5607 10.8048 28.6282 9.32324H12.3716C12.439 10.8048 13.0792 12.2202 14.165 13.2383L17.5651 16.4264C18.2996 17.1135 18.8077 18.0015 19.0501 18.9647C19.0501 19.7653 19.6993 20.4144 20.4999 20.4144Z" />
				<path d="M20.5001 21.9426C19.6995 21.9426 19.0503 22.5918 19.0503 23.3924C19.0503 24.1924 19.6995 24.8416 20.5001 24.8416C21.3007 24.8416 21.9492 24.1924 21.9492 23.3924C21.9492 22.5918 21.3007 21.9426 20.5001 21.9426Z" />
				<path d="M20.5001 27.0852C19.6995 27.0852 19.0503 27.7344 19.0503 28.5351C19.0503 29.335 19.6995 29.9842 20.5001 29.9842C21.3007 29.9842 21.9492 29.335 21.9492 28.5351C21.9492 27.7344 21.3007 27.0852 20.5001 27.0852Z" />
				<path d="M19.2112 31.6893L13.8931 37.0075H27.1061L21.788 31.6893C21.0762 30.9782 19.923 30.9782 19.2112 31.6893Z" />
			</g>
			<defs>
				<clipPath id="clip0_2834_53168">
					<rect width="40.9222" height="40.9222" transform="translate(0.0390625 0.538818)" />
				</clipPath>
			</defs>
		</svg>
	)
}

const MemoisedHourglassFilledIcon = memo(HourglassFilledIcon)

export default MemoisedHourglassFilledIcon
