import { useMemo } from 'react'

const useSubscriptionStatus = (entityCode: string, subscribedCpoEntities: string[]) => {
	const isSubscription = useMemo(() => {
		return subscribedCpoEntities.includes(entityCode)
	}, [entityCode, subscribedCpoEntities])

	return isSubscription
}

export default useSubscriptionStatus
