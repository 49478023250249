import { QueryStatus } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useLocationEvseConnectorQuery, useLocationQuery } from 'src/_shared/queries/locations'
import { useTariffQuery } from 'src/_shared/queries/tariff'
import { OmniConnector, OmniEvse, OmniTariff } from 'src/_shared/types/omni'
import { OmniLocation } from 'src/_shared/types/omni/location'

import { ChargerScreenRouteParams } from '../types'

// 10 Seconds
const DEFAULT_REFETCH_INTERVAL = 10000

type UseChargerDetailsArgs = ChargerScreenRouteParams & {
	/**
	 * If true, the `activeTariff` will be queried and polled.
	 */
	enableActiveTariffQuery?: boolean
}

interface ChargerDetails {
	location: OmniLocation | null
	/**
	 * The target `evse` that is obtained from `location`.
	 */
	evse: OmniEvse | null
	/**
	 * The target `connector` that is either obtained via query or from `location`.
	 */
	connector: OmniConnector | null
	/**
	 * The `activeTariff` that is either obtained via query or from `connector`.
	 */
	activeTariff: OmniTariff | null
	locationError: AxiosError<{ message: string }> | null
	connectorError: AxiosError<{ message: string }> | null
	locationQueryStatus: QueryStatus
	connectorQueryStatus: QueryStatus
	activeTariffQueryStatus: QueryStatus
}

/**
 * Queries for the most up-to-date information of the specified charger.
 * @param {UseChargerDetailsArgs} UseChargerDetailsArgs `{ locationUid, evseUid, connectorUid, enableActiveTariffQuery }`
 * @returns {ChargerDetails} The `location`, `evse`, `connector`, and other related query information.
 */
export const useChargerDetails = ({
	locationUid,
	evseUid,
	connectorUid,
	enableActiveTariffQuery = false
}: UseChargerDetailsArgs): ChargerDetails => {
	const {
		data: location = null,
		error: locationError,
		status: locationQueryStatus
	} = useLocationQuery(
		{ locationUid },
		{
			enabled: !!locationUid,
			staleTime: Infinity
		}
	)

	const evseFromLocation = useMemo((): OmniEvse | null => {
		const evse = location?.evses?.find((evse): boolean => {
			return evse.uid === evseUid
		})
		return evse ?? null
	}, [evseUid, location])

	const connectorFromLocationEvse = useMemo((): OmniConnector | null => {
		const connector = evseFromLocation?.connectors?.find((connector): boolean => {
			return connector.uid === connectorUid
		})
		return connector ?? null
	}, [connectorUid, evseFromLocation])

	// The `connector` from this query should be more up-to-date than the one returned via `location`.
	const {
		data: connectorFromQuery = null,
		error: connectorError,
		status: connectorQueryStatus
	} = useLocationEvseConnectorQuery(
		{ locationUid, evseUid, connectorUid },
		{
			enabled: locationQueryStatus === 'success',
			retry: false,
			refetchInterval: (query): false | number => {
				// Disable refetching if there is still no data after repeated errors.
				if (!query.state.data && query.state.errorUpdateCount > 0) {
					return false
				}
				return DEFAULT_REFETCH_INTERVAL
			},
			refetchOnWindowFocus: (query): boolean => {
				// Disable refetching if there is still no data after repeated errors.
				if (!query.state.data && query.state.errorUpdateCount > 0) {
					return false
				}
				return true
			},
			refetchIntervalInBackground: true
		}
	)

	const connector = useMemo((): OmniConnector | null => {
		return connectorFromQuery ?? connectorFromLocationEvse
	}, [connectorFromQuery, connectorFromLocationEvse])

	// The `activeTariff` from this query should be more up-to-date than the one returned via `connector`.
	const { data: activeTariffFromQuery = null, status: activeTariffQueryStatus } = useTariffQuery(
		{
			applyPriceModifier: true,
			tariffUid: connector?.active_tariff?.uid ?? ''
		},
		{
			enabled: !!connector?.active_tariff?.uid && enableActiveTariffQuery,
			refetchOnMount: 'always',
			refetchInterval: DEFAULT_REFETCH_INTERVAL,
			refetchIntervalInBackground: true
		}
	)

	const activeTariff = useMemo((): OmniTariff | null => {
		return activeTariffFromQuery ?? connector?.active_tariff ?? null
	}, [activeTariffFromQuery, connector?.active_tariff])

	return {
		location,
		evse: evseFromLocation,
		connector,
		activeTariff,
		locationError,
		connectorError,
		locationQueryStatus,
		connectorQueryStatus,
		activeTariffQueryStatus
	}
}
