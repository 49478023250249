import { useCallback, useMemo, useState } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Notice from 'src/_shared/components/Notice'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import ArrowLeftIcon from 'src/_shared/components/_icons/ArrowLeftIcon'

import EnterOtpView from './components/EnterOtpView'
import NewPasswordView from './components/NewPasswordView'
import SelectOtpMethodView from './components/SelectOtpMethodView'
import { AccountForgotPasswordScreenViewItemKey, FormFieldKey } from './enums'
import { AccountForgotPasswordScreenViewItem } from './types'

const AccountForgotPasswordScreen = (): JSX.Element => {
	const [viewItemKey, setViewItemKey] = useState<AccountForgotPasswordScreenViewItemKey>(
		AccountForgotPasswordScreenViewItemKey.SelectOtpMethodView
	)

	const [formValues, setFormValues] = useState<Record<FormFieldKey, string>>({
		[FormFieldKey.CountryCode]: '+65',
		[FormFieldKey.MobileNumber]: '',
		[FormFieldKey.Otp]: '',
		[FormFieldKey.ResetToken]: '',
		[FormFieldKey.NewPassword]: '',
		[FormFieldKey.ConfirmNewPassword]: ''
	})

	const navigate = useRouterNavigate()

	const updateView = useCallback((nextKey: AccountForgotPasswordScreenViewItemKey): void => {
		setViewItemKey((previousKey): AccountForgotPasswordScreenViewItemKey => {
			console.debug(`[AccountForgotPasswordScreen] Update View: "${previousKey}" > "${nextKey}"`)
			return nextKey
		})
	}, [])

	const viewItemsMap = useMemo((): Record<
		AccountForgotPasswordScreenViewItemKey,
		AccountForgotPasswordScreenViewItem
	> => {
		return {
			[AccountForgotPasswordScreenViewItemKey.SelectOtpMethodView]: {
				view: (
					<SelectOtpMethodView
						formValues={formValues}
						setFormValues={setFormValues}
						onNext={(): void => {
							updateView(AccountForgotPasswordScreenViewItemKey.EnterOtpView)
						}}
					/>
				)
			},
			[AccountForgotPasswordScreenViewItemKey.EnterOtpView]: {
				view: (
					<EnterOtpView
						formValues={formValues}
						setFormValues={setFormValues}
						onNext={(): void => {
							updateView(AccountForgotPasswordScreenViewItemKey.NewPasswordView)
						}}
					/>
				),
				onBack: (): void => {
					updateView(AccountForgotPasswordScreenViewItemKey.SelectOtpMethodView)
				}
			},
			[AccountForgotPasswordScreenViewItemKey.NewPasswordView]: {
				view: (
					<NewPasswordView
						formValues={formValues}
						setFormValues={setFormValues}
						onNext={(): void => {
							updateView(AccountForgotPasswordScreenViewItemKey.PostNewPasswordView)
						}}
					/>
				),
				onBack: (): void => {
					updateView(AccountForgotPasswordScreenViewItemKey.SelectOtpMethodView)
				}
			},
			[AccountForgotPasswordScreenViewItemKey.PostNewPasswordView]: {
				view: (
					<Notice
						type="success"
						header="Password has been changed successfully"
						buttonProps={{
							children: 'Back to Login',
							onClick: (): void => {
								navigate({
									pathname: ScreenRoutePath.AccountLogin,
									search: location.search
								})
							}
						}}
					/>
				),
				onBack: (): void => {
					navigate({
						pathname: ScreenRoutePath.AccountLogin,
						search: location.search
					})
				}
			}
		}
	}, [formValues, navigate, setFormValues, updateView])

	const currentViewItem = viewItemsMap[viewItemKey]

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			topBarProps={{
				leftRender: currentViewItem.onBack && (
					<TopBarButton onClick={currentViewItem.onBack}>
						<ArrowLeftIcon className="h-4" />
					</TopBarButton>
				)
			}}
			hideBottomBar
		>
			{currentViewItem.view}
		</ScreenContainer>
	)
}

export default AccountForgotPasswordScreen
