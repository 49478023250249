import { SVGProps, memo } from 'react'

const UnlockIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 23 23',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M16.2916 21.0833H6.70829C2.87496 21.0833 1.91663 20.1249 1.91663 16.2916V14.3749C1.91663 10.5416 2.87496 9.58325 6.70829 9.58325H16.2916C20.125 9.58325 21.0833 10.5416 21.0833 14.3749V16.2916C21.0833 20.1249 20.125 21.0833 16.2916 21.0833Z"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.75 9.58341V7.66675C5.75 4.49466 6.70833 1.91675 11.5 1.91675C15.8125 1.91675 17.25 3.83341 17.25 6.70841"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.5 17.7292C12.8231 17.7292 13.8958 16.6565 13.8958 15.3333C13.8958 14.0102 12.8231 12.9375 11.5 12.9375C10.1768 12.9375 9.10413 14.0102 9.10413 15.3333C9.10413 16.6565 10.1768 17.7292 11.5 17.7292Z"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedUnlockIcon = memo(UnlockIcon)

export default MemoisedUnlockIcon
