import { SVGProps, memo } from 'react'

const TouchNGoLogoImage = ({
	'aria-label': ariaLabel = "Touch 'n Go",
	role = 'img',
	viewBox = '4 4 92 92',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg aria-label={ariaLabel} role={role} viewBox={viewBox} xmlns={xmlns} {...props}>
			<title>{ariaLabel}</title>
			<path
				d="M77.0604 0.00152209H23.0487C17.0706 0.00958159 11.3396 2.40935 7.11262 6.67458C2.88561 10.9398 0.507588 16.7223 0.5 22.7541V77.2459C0.507188 83.2779 2.88506 89.0609 7.11211 93.3264C11.3392 97.592 17.0703 99.9919 23.0487 100H77.0604C83.0387 99.9923 88.7699 97.5924 92.9968 93.3268C97.2237 89.0611 99.6011 83.2779 99.6075 77.2459V22.7541C99.6011 16.7221 97.2237 10.9389 92.9968 6.67325C88.7699 2.40757 83.0387 0.00765903 77.0604 0"
				fill="white"
			/>
			<path
				d="M77.2292 3.88086H22.8839C17.97 3.88811 13.2594 5.86068 9.78442 9.36623C6.30947 12.8718 4.35369 17.6244 4.3457 22.5824V77.4077C4.35369 82.3657 6.30947 87.1183 9.78442 90.6238C13.2594 94.1294 17.97 96.102 22.8839 96.1092H77.2292C82.1433 96.1036 86.8546 94.1314 90.3294 90.6254C93.8042 87.1194 95.7588 82.3659 95.7644 77.4077V22.5824C95.7565 17.6249 93.8011 12.8728 90.3268 9.3673C86.8525 5.86183 82.1426 3.88891 77.2292 3.88086Z"
				fill="#005ABE"
			/>
			<path
				d="M39.9071 41.545H35.3005C34.556 41.5639 33.8297 41.7818 33.1959 42.1764C32.5621 42.5711 32.0435 43.1284 31.693 43.7914L32.1549 41.3303H26.5264L24.0254 55.4783H29.6614L31.2176 46.522H37.2808C37.2808 46.522 36.5064 50.9567 36.1608 52.9426C35.8151 54.9285 37.154 55.4113 37.8211 55.4113H40.769L42.6874 44.4097C42.6734 43.6651 42.3784 42.954 41.8626 42.4214C41.3468 41.8888 40.649 41.5748 39.9116 41.5435"
				fill="white"
			/>
			<path d="M22.4648 41.325L21.4082 47.3969L25.6903 41.325H22.4648Z" fill="white" />
			<path
				d="M29.1464 19.1667H10.2943L9.47461 23.8543H16.0555L13.4172 38.96H19.0939L21.7444 23.8543H28.3253L29.1464 19.1667Z"
				fill="white"
			/>
			<path
				d="M25.0892 38.9622C24.7339 38.9733 24.3808 38.9021 24.057 38.754C23.7333 38.6059 23.4475 38.3849 23.2215 38.108C22.9956 37.8311 22.8356 37.5057 22.7538 37.1566C22.672 36.8076 22.6706 36.4443 22.7497 36.0946L24.1987 27.8222C24.3555 27.0362 24.7697 26.3263 25.3747 25.8065C25.9797 25.2866 26.7403 24.9872 27.5344 24.9561H36.7763C37.1315 24.9442 37.4846 25.0149 37.8083 25.1627C38.1321 25.3105 38.4177 25.5316 38.6432 25.8087C38.8686 26.0858 39.0279 26.4115 39.1085 26.7607C39.1892 27.1099 39.1891 27.4731 39.1083 27.8222L37.6684 36.0946C37.51 36.8814 37.0944 37.5917 36.4882 38.1117C35.8819 38.6317 35.1202 38.9312 34.3251 38.9622H25.0892ZM33.0482 34.325L33.8753 29.604H28.8234L28.0023 34.325H33.0482Z"
				fill="white"
			/>
			<path
				d="M62.2815 34.3261L63.1102 29.6051H70.4261L71.2382 24.9663H61.8257C61.0306 24.9963 60.2686 25.2951 59.662 25.8146C59.0554 26.3341 58.6394 27.0442 58.4809 27.8309L57.0349 36.1033C56.9557 36.4533 56.9571 36.817 57.0392 37.1663C57.1212 37.5156 57.2817 37.8413 57.5082 38.1182C57.7346 38.3951 58.021 38.6158 58.3454 38.7634C58.6697 38.911 59.0233 38.9815 59.3789 38.9695H68.5303L69.3379 34.3337L62.2815 34.3261Z"
				fill="white"
			/>
			<path
				d="M50.7713 34.3261H44.5828L46.2175 24.9601H41.2833L39.3423 36.0958C39.2626 36.4452 39.2635 36.8084 39.3449 37.1574C39.4263 37.5064 39.586 37.8319 39.8118 38.1088C40.0375 38.3857 40.3232 38.6066 40.6469 38.7545C40.9706 38.9024 41.3237 38.9734 41.6788 38.9619H46.4907C47.2499 38.9136 47.9889 38.6956 48.6543 38.3237C49.3197 37.9518 49.8949 37.4353 50.3381 36.8116C50.3381 36.8116 50.889 38.4335 51.68 38.7914C52.7289 39.0386 53.8074 39.1334 54.8829 39.0731L57.3537 24.9556H52.4165L50.7713 34.3261Z"
				fill="white"
			/>
			<path
				d="M86.3491 25.0272H81.7455C81.0585 25.0533 80.3937 25.2795 79.8313 25.6783C79.2689 26.0772 78.8328 26.6317 78.5758 27.2751L79.901 19.668H74.7118L71.3398 38.9607H76.5351L78.0973 30.0073H83.7258C83.7258 30.0073 82.953 34.439 82.6074 36.428C82.2617 38.417 83.599 38.8952 84.251 38.8952H86.7657L88.6871 27.8934C88.7665 27.5438 88.7654 27.1804 88.6837 26.8313C88.602 26.4822 88.442 26.1567 88.216 25.8798C87.99 25.6029 87.704 25.382 87.3801 25.2342C87.0562 25.0864 86.7029 25.0156 86.3476 25.0272"
				fill="white"
			/>
			<path
				d="M78.8004 54.9805L80.2826 46.5388H71.9478L70.4671 54.9805H78.8004ZM67.8468 60.1783C67.3689 60.1923 66.8942 60.0958 66.459 59.8962C66.0238 59.6967 65.6396 59.3993 65.3357 59.0269C65.0319 58.6545 64.8165 58.2169 64.706 57.7476C64.5955 57.2783 64.5928 56.7897 64.6982 56.3192L66.6453 45.2017C66.8566 44.1416 67.4157 43.1844 68.2323 42.4842C69.0488 41.7841 70.0754 41.3819 71.1463 41.3425H82.9029C83.3809 41.3279 83.8559 41.424 84.2914 41.6234C84.7269 41.8227 85.1113 42.1202 85.4152 42.4928C85.719 42.8654 85.9342 43.3033 86.0443 43.7729C86.1543 44.2424 86.1563 44.7312 86.05 45.2017L84.1044 56.3192C83.8911 57.3771 83.3323 58.3323 82.5174 59.032C81.7025 59.7316 80.6786 60.1353 79.6094 60.1783H67.8468Z"
				fill="white"
			/>
			<path
				d="M56.4531 54.9803H48.1122L49.5898 46.5373H63.1094L64 41.3396H48.7884C47.7187 41.3801 46.6936 41.7828 45.8784 42.4829C45.0632 43.183 44.5054 44.1396 44.2949 45.1987L42.3479 56.3159C42.2416 56.786 42.2435 57.2744 42.3533 57.7437C42.4632 58.213 42.6782 58.6506 42.9817 59.023C43.2853 59.3955 43.6693 59.6928 44.1045 59.8922C44.5396 60.0916 45.0142 60.1878 45.4919 60.1734H57.2621C58.3316 60.1318 59.3562 59.7289 60.1716 59.0294C60.987 58.3299 61.5459 57.3743 61.7585 56.3159L62.9494 49.4917H57.413L56.4531 54.9803Z"
				fill="white"
			/>
			<path
				d="M46.7858 67.0134L39.4381 82.2899H34.7771L34.2247 73.0442L29.7751 82.2899H25.0884L24.1934 67.0134H27.514L28.0936 78.9958L33.8081 67.0134H37.1786L37.7582 78.9958L43.4727 67.0134H46.7858Z"
				fill="#FFDC00"
			/>
			<path d="M60.6606 82.2914H57.4199L60.6319 67.0134H63.871L60.6606 82.2914Z" fill="#FFDC00" />
			<path d="M65.9763 82.2914H62.7402L65.9522 67.0134H69.1913L65.9763 82.2914Z" fill="#FFDC00" />
			<path
				d="M88.3615 82.2931H85.7548C84.8965 82.3503 84.0358 82.2279 83.2266 81.9337C82.9797 81.79 82.7805 81.5758 82.654 81.3181C82.5275 81.0604 82.4794 80.7707 82.5157 80.4854C82.5252 80.0019 82.5869 79.5208 82.6999 79.0508L85.2281 67.0195H88.4672L87.6506 70.8193H90.784L90.1803 73.5819H87.0982L86.1775 77.939C86.1115 78.2276 86.0676 78.5209 86.0461 78.8163C86.0297 78.9334 86.0499 79.0527 86.1041 79.1576C86.1582 79.2624 86.2435 79.3476 86.348 79.4011C86.7457 79.5149 87.1596 79.5598 87.5721 79.5336H88.9411L88.3615 82.2931Z"
				fill="#FFDC00"
			/>
			<path
				d="M19.3257 75.1716H13.5599C13.6116 74.8803 13.6956 74.5957 13.8105 74.3233C13.8866 74.1266 14.0157 73.9552 14.1831 73.8285C14.3506 73.7017 14.5498 73.6247 14.7583 73.606C15.051 73.5669 15.3461 73.5491 15.6413 73.5527H18.2224C19.0646 73.5527 19.4857 73.8182 19.4857 74.3492C19.4857 74.402 19.4354 74.6767 19.3348 75.1731L19.3257 75.1716ZM22.1785 71.1601C21.3717 70.8557 20.5096 70.7289 19.6502 70.7885H15.0119C13.9444 70.7187 12.8818 70.9847 11.9705 71.55C11.2048 72.1786 10.7019 73.0753 10.5623 74.0614L9.53589 79.0308C9.46545 79.3354 9.41513 79.6055 9.38494 79.841C9.35133 80.0562 9.33366 80.2735 9.33211 80.4913C9.31888 80.7869 9.37043 81.0817 9.48305 81.3549C9.59853 81.5868 9.78262 81.7768 10.0098 81.8986C10.3216 82.0603 10.6614 82.1595 11.0105 82.191C11.5352 82.2499 12.063 82.2769 12.5909 82.2717H20.4638L21.0434 79.5304H14.4021C13.9883 79.5566 13.5732 79.5049 13.178 79.378C13.0739 79.3205 12.9894 79.2326 12.9355 79.1258C12.8816 79.019 12.8609 78.8983 12.8761 78.7795C12.8851 78.4935 12.9205 78.2091 12.9818 77.9297V77.9221H14.3508V77.9297H21.8811L22.6977 74.0507C22.8097 73.5712 22.8714 73.0812 22.8818 72.5886C22.9146 72.3056 22.8648 72.0192 22.7385 71.7643C22.6122 71.5095 22.415 71.2974 22.1709 71.154"
				fill="#FFDC00"
			/>
			<path
				d="M47.6794 79.5284C47.2055 79.5284 46.9685 79.3253 46.9685 78.9192C46.9701 78.7926 46.9965 78.6675 47.0463 78.5511C47.096 78.4348 47.1681 78.3296 47.2583 78.2415C47.3574 78.1396 47.4766 78.0598 47.6081 78.0073C47.7397 77.9549 47.8807 77.9309 48.0221 77.9369H51.5238V77.9293H52.8823L52.5427 79.533L47.6794 79.5284ZM56.9621 71.7173C56.8407 71.48 56.6463 71.2887 56.4082 71.1721C56.0934 71.022 55.7555 70.9277 55.409 70.8934C54.8974 70.8352 54.3827 70.8087 53.8679 70.8142H46.0191L45.4395 73.5768H52.0808C52.4935 73.5509 52.9074 73.5962 53.3049 73.7108C53.4091 73.7642 53.4943 73.8491 53.5484 73.9537C53.6025 74.0582 53.6229 74.1772 53.6068 74.2941C53.5854 74.5884 53.5414 74.8807 53.4755 75.1682H50.8808H48.3405C47.7111 75.1595 47.0819 75.1992 46.4584 75.287C46.0122 75.3458 45.5846 75.5045 45.2071 75.7515C44.8582 75.9992 44.5844 76.3398 44.4162 76.7353C44.1863 77.2749 44.0099 77.836 43.8894 78.4106L43.7581 79.0487C43.6449 79.5186 43.5832 79.9997 43.5739 80.4833C43.5614 80.7935 43.6198 81.1025 43.7445 81.3864C43.8656 81.624 44.06 81.8153 44.2984 81.9316C44.6136 82.0821 44.9521 82.1764 45.2992 82.2103C45.8107 82.2686 46.3254 82.295 46.8402 82.2894H55.2143L56.9531 74.0535C57.0656 73.5834 57.1273 73.1024 57.1372 72.6189C57.149 72.3086 57.0901 71.9996 56.9652 71.7158"
				fill="#FFDC00"
			/>
			<path
				d="M78.4841 75.1731H72.7183C72.7693 74.8817 72.8528 74.5971 72.9673 74.3248C73.0434 74.1283 73.1723 73.957 73.3394 73.8303C73.5066 73.7036 73.7054 73.6264 73.9137 73.6074C74.2064 73.5684 74.5015 73.5506 74.7967 73.5541H77.3777C78.22 73.5541 78.6416 73.8197 78.6426 74.3507C78.6426 74.4035 78.5898 74.6781 78.4841 75.1746V75.1731ZM81.3293 71.1616C80.5225 70.8571 79.6604 70.7304 78.8011 70.7899H74.1658C73.0983 70.7202 72.0357 70.9862 71.1244 71.5514C70.3586 72.18 69.8558 73.0768 69.7161 74.0628L68.6882 79.0323C68.6178 79.3369 68.5675 79.6069 68.5373 79.8425C68.5037 80.0576 68.4861 80.275 68.4845 80.4928C68.4712 80.7883 68.5227 81.0832 68.6354 81.3563C68.7509 81.5883 68.935 81.7783 69.1622 81.9C69.4739 82.0617 69.8138 82.161 70.1629 82.1924C70.6876 82.2513 71.2153 82.2783 71.7432 82.2732H79.6162L80.1958 79.5318H73.5545C73.1406 79.5581 72.7255 79.5064 72.3304 79.3795C72.2261 79.3221 72.1414 79.2342 72.0875 79.1274C72.0337 79.0206 72.0131 78.8998 72.0285 78.781C72.0374 78.495 72.0728 78.2105 72.1342 77.9312V77.9236H73.5032V77.9312H81.035L81.8515 74.0522C81.9637 73.5727 82.0254 73.0826 82.0357 72.5901C82.0685 72.3071 82.0186 72.0206 81.8924 71.7658C81.7661 71.5109 81.5688 71.2988 81.3248 71.1555"
				fill="#FFDC00"
			/>
			<path
				d="M102.629 4.19081H101.281V8.19888H100.952V4.19081H99.6055V3.88623H102.624L102.629 4.19081Z"
				fill="#005ABE"
			/>
			<path
				d="M107.167 8.19684H106.842V4.36212L105.259 7.01504H105.205L103.621 4.36212V8.19684H103.297V3.88086H103.7L105.227 6.43481L106.755 3.88086H107.165L107.167 8.19684Z"
				fill="#005ABE"
			/>
		</svg>
	)
}

const MemoisedTouchNGoLogoImage = memo(TouchNGoLogoImage)

export default MemoisedTouchNGoLogoImage
