import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import ReceiptDetails from 'src/_shared/components/ReceiptDetails'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

import { ChargerScreenViewItemKey } from '../enums'
import useChargerDebugLogging from '../hooks/useChargerDebugLogging'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'

type ReceiptViewProps = ChargerScreenCommonViewProps

const ReceiptView = ({ routeParams, onNext: handleNext }: ReceiptViewProps): JSX.Element => {
	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const isChargerSessionDetailsPending = chargerSessionDetailsQueryStatus === 'pending'

	const handleReceiptClose = useCallback((): void => {
		// FUTURE TODO: Replace with URL returned from `session`.
		const closeRedirectUrl =
			BRAND === Brand.Evme
				? `https://evme.io/mobile/ev-stations/charging/complete?session=${session?.uid}`
				: null
		if (closeRedirectUrl) {
			window.location.href = closeRedirectUrl
		} else {
			handleNext?.()
		}
	}, [session?.uid, handleNext])

	useChargerDebugLogging({
		connector,
		isUserChargingSession,
		session,
		viewItemKey: ChargerScreenViewItemKey.ReceiptView
	})

	return (
		<>
			<div className="flex-grow">{session && <ReceiptDetails chargingSession={session} />}</div>
			<Footer
				buttonProps={{
					children: intl.formatMessage({
						id: 'ReceiptView.ButtonTextClose',
						defaultMessage: 'Close'
					}),
					className: 'w-full',
					disabled: isChargerSessionDetailsPending,
					variant: 'ghost',
					onClick: handleReceiptClose
				}}
			/>
		</>
	)
}

export default ReceiptView
