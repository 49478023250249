import { SVGProps, memo } from 'react'

const CardIcon = ({
	fill = 'none',
	viewBox = '0 0 20 16',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M19 11.5C19 13.1569 17.6569 14.5 16 14.5L4 14.5C2.34315 14.5 1 13.1569 1 11.5L1 4C1 2.34315 2.34315 1 4 1L16 1C17.6569 1 19 2.34315 19 4L19 11.5Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.89551 9.35731C2.89551 8.98452 3.19772 8.68231 3.57051 8.68231H8.07051C8.4433 8.68231 8.74551 8.98452 8.74551 9.35731C8.74551 9.7301 8.4433 10.0323 8.07051 10.0323H3.57051C3.19772 10.0323 2.89551 9.7301 2.89551 9.35731Z"
				fill="currentColor"
			/>
			<rect x="1" y="4.85724" width="18" height="1.28571" fill="currentColor" />
		</svg>
	)
}

const MemoisedCardIcon = memo(CardIcon)

export default MemoisedCardIcon
