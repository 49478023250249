import LocationSlash from 'src/_shared/_old/assets/svgs/LocationSlash'
import ChargingStation from 'src/_shared/_old/assets/svgs/chargingstation.svg'

interface Props {
	header: string
	mainText: string
}

const NoCharger = ({ header, mainText }: Props) => {
	return (
		<div className="flex w-full flex-col items-center justify-center space-y-14 px-8 pt-[20vh]">
			<div className="relative">
				<img src={ChargingStation} className="h-32 w-auto text-typography-tertiary" />
				<LocationSlash className="absolute -top-2 right-1 h-10 w-auto text-typography-primary" />
			</div>
			<div className="flex w-full flex-col items-center justify-center space-y-2">
				<p className="text-center text-sm font-semibold text-typography-primary">{header}</p>
				<p className="text-center text-sm text-typography-secondary">{mainText}</p>
			</div>
		</div>
	)
}

export default NoCharger
