function Flash({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 32 42" fill="none">
			<path
				d="M28.383 18.3181H21.9087V3.23237C21.9087 -0.28763 20.002 -1.00001 17.6763 1.63999L16.0001 3.54666L1.81533 19.68C-0.13324 21.88 0.683903 23.6819 3.61724 23.6819H10.0915V38.7676C10.0915 42.2876 11.9982 43 14.3239 40.36L16.0001 38.4533L30.1849 22.32C32.1334 20.12 31.3163 18.3181 28.383 18.3181Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default Flash
