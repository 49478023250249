import dayjs from 'dayjs'
import { DetailedHTMLProps, HTMLAttributes, memo, useCallback, useEffect, useRef } from 'react'

import { formatPaddedTimeNumber, getTimeFromSeconds } from '../utils'

type TimeElapsedTextProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
	'ref'
> & {
	/**
	 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`
	 */
	startDateTime?: string
}

const INITIAL_TEXT_VALUE = '-'

const TimeElapsedText = ({ startDateTime, ...props }: TimeElapsedTextProps): JSX.Element => {
	const timeElapsedRef = useRef<HTMLSpanElement | null>(null)

	const updateTimeElapsedText = useCallback((): void => {
		if (timeElapsedRef.current) {
			if (startDateTime) {
				const secondsElapsed = dayjs().diff(dayjs(startDateTime), 's')

				const { hours, minutes, seconds } = getTimeFromSeconds(secondsElapsed)

				timeElapsedRef.current.innerText = ((): string => {
					// Output Format: `<hours>:<minutes>:<seconds>`
					const timeElapsedArr = [
						formatPaddedTimeNumber(hours),
						formatPaddedTimeNumber(minutes),
						formatPaddedTimeNumber(seconds)
					]

					return timeElapsedArr.join(':')
				})()
			} else {
				timeElapsedRef.current.innerText = INITIAL_TEXT_VALUE
			}
		}
	}, [startDateTime])

	useEffect((): (() => void) => {
		const intervalId = setInterval(updateTimeElapsedText, 1000)
		updateTimeElapsedText()
		return (): void => {
			clearInterval(intervalId)
		}
	}, [updateTimeElapsedText])

	return (
		<span ref={timeElapsedRef} {...props}>
			{INITIAL_TEXT_VALUE}
		</span>
	)
}

const MemoisedTimeElapsedText = memo(TimeElapsedText)

export default MemoisedTimeElapsedText
