export enum OmniTariffElementPriceComponentType {
	Energy = 'ENERGY',
	Flat = 'FLAT',
	ParkingTime = 'PARKING_TIME',
	Time = 'TIME'
}

export enum OmniTariffPriceModifierType {
	DiscountFlat = 'DISCOUNT_FLAT',
	DiscountPercentage = 'DISCOUNT_PERCENTAGE',
	MarkupFlat = 'MARKUP_FLAT',
	MarkupPercentage = 'MARKUP_PERCENTAGE'
}
