import { memo } from 'react'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'

import Notice from '../Notice'

const Error404Notice = (): JSX.Element => {
	const navigate = useRouterNavigate()
	return (
		<Notice
			type="warning"
			header={
				<div className="mb-5">
					<h1 className="text-center !text-[3.375rem] !leading-tight text-typography-primary">
						404
					</h1>
					<p className="body-3-semibold text-center text-typography-primary">Page Not Found</p>
				</div>
			}
			description="Something went wrong and the page you are looking for cannot be found."
			buttonProps={{
				children: 'Go to Homepage',
				onClick: (): void => {
					navigate({
						pathname: ScreenRoutePath.Root,
						search: location.search
					})
				}
			}}
		/>
	)
}

const MemoisedError404Notice = memo(Error404Notice)

export default MemoisedError404Notice
