import { SVGProps, memo } from 'react'

const AccountIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<g transform="matrix(1.16, 0, 0, 1.16, -1.95, -1.95)">
				<path
					d="M18.2849 19.2343C17.3329 16.688 14.8778 14.875 11.9993 14.875C9.12088 14.875 6.66584 16.688 5.7138 19.2343M18.2849 19.2343C20.3054 17.4773 21.5827 14.8878 21.5827 12C21.5827 6.70729 17.2921 2.41669 11.9993 2.41669C6.70662 2.41669 2.41602 6.70729 2.41602 12C2.41602 14.8878 3.69333 17.4773 5.7138 19.2343M18.2849 19.2343C16.6023 20.6975 14.4043 21.5834 11.9993 21.5834C9.59443 21.5834 7.39641 20.6975 5.7138 19.2343M14.8743 9.12502C14.8743 10.7128 13.5872 12 11.9993 12C10.4115 12 9.12435 10.7128 9.12435 9.12502C9.12435 7.5372 10.4115 6.25002 11.9993 6.25002C13.5872 6.25002 14.8743 7.5372 14.8743 9.12502Z"
					strokeWidth="1.5"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)
}

const MemoisedAccountIcon = memo(AccountIcon)

export default MemoisedAccountIcon
