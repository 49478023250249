import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath, useRouterLocation } from 'src/App/router/hooks'
import { classNames } from 'src/_shared/utils/elements'

import AccountFilledIcon from '../../_icons/AccountFilledIcon'
import AccountIcon from '../../_icons/AccountIcon'
import ClockFilledIcon from '../../_icons/ClockFilledIcon'
import ClockIcon from '../../_icons/ClockIcon'
import LocationPinFilledIcon from '../../_icons/LocationPinFilledIcon'
import LocationPinIcon from '../../_icons/LocationPinIcon'

interface NavigationItem {
	title: string
	pathname: ScreenRoutePath.Account | ScreenRoutePath.History | ScreenRoutePath.Map
	defaultIcon: JSX.Element
	selectedIcon: JSX.Element
}

const NAVIGATION_ITEMS: NavigationItem[] = [
	{
		title: 'Maps',
		pathname: ScreenRoutePath.Map,
		defaultIcon: <LocationPinIcon className="h-5 w-auto text-typography-secondary" />,
		selectedIcon: <LocationPinFilledIcon className="h-5 w-auto text-primary-800" />
	},
	{
		title: 'Charging History',
		pathname: ScreenRoutePath.History,
		defaultIcon: <ClockIcon className="h-5 w-5 text-typography-secondary" />,
		selectedIcon: <ClockFilledIcon className="h-5 w-5 text-primary-800" />
	},
	{
		title: 'Account',
		pathname: ScreenRoutePath.Account,
		defaultIcon: <AccountIcon className="h-5 w-5 text-typography-secondary" />,
		selectedIcon: <AccountFilledIcon className="h-5 w-5 text-primary-800" />
	}
]

export type BottomBarProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'children' | 'ref'
>

const BottomBar = ({ className, style, ...props }: BottomBarProps): JSX.Element => {
	const location = useRouterLocation()

	return (
		<div
			className={classNames(
				'sticky bottom-0 z-10 flex justify-around bg-white pt-2 shadow-[0_0_20px_7px_rgba(0,0,0,0.04)]',
				className
			)}
			style={{
				...style,
				paddingBottom: style?.paddingBottom ?? 'calc(6px + env(safe-area-inset-bottom))'
			}}
			{...props}
		>
			{NAVIGATION_ITEMS.map(
				({ title, pathname, defaultIcon, selectedIcon }, index): JSX.Element => {
					const isSelected = location.pathname.includes(pathname)
					return (
						<Link
							key={index}
							to={pathname}
							className="flex w-32 flex-col items-center justify-center no-underline"
						>
							{isSelected ? selectedIcon : defaultIcon}
							<span
								className={classNames(
									'caption-3-medium',
									isSelected ? 'text-typography-primary' : 'text-typography-secondary'
								)}
							>
								{title}
							</span>
						</Link>
					)
				}
			)}
		</div>
	)
}

const MemoisedBottomBar = memo(BottomBar)

export default MemoisedBottomBar
