import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useQueryClient } from '@tanstack/react-query'
import { memo } from 'react'
import { useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Notice from 'src/_shared/components/Notice'
import { PaymentMethodCardType } from 'src/_shared/enums/payments'
import {
	useAddPaymentMethodMutation,
	useStripeConfirmSetupIntentMutation
} from 'src/_shared/mutations/payments'
import { ROOT_PAYMENTS_QUERY_KEY } from 'src/_shared/queries/payments'

const StripeForm = (): JSX.Element => {
	const stripe = useStripe()

	const stripeElements = useElements()

	const queryClient = useQueryClient()

	const navigate = useRouterNavigate()

	const {
		isError: isAddPaymentMethodError,
		isPending: isAddPaymentMethodPending,
		isSuccess: isAddPaymentMethodSuccess,
		mutateAsync: addPaymentMethod
	} = useAddPaymentMethodMutation()

	const {
		isError: isStripeConfirmSetupIntentError,
		isPending: isStripeConfirmSetupIntentPending,
		mutateAsync: stripeConfirmSetupIntent
	} = useStripeConfirmSetupIntentMutation()

	/**
	 * Add Payment Method Steps
	 * 1. Confirm Stripe Intent: Ensures that entered payment method details are valid.
	 * 2. Add Payment Method via CPO Backend API.
	 * 3. Invalidate Payment Method Query to trigger re-fetch of the list of Payment Methods.
	 * 4. Invalidate Stripe Setup Intent Query to allow another Payment Method to be added.
	 */
	const handleAddPaymentMethod = (): void => {
		if (stripe && stripeElements) {
			// Step 1
			void stripeConfirmSetupIntent(
				{ stripe, stripeElements },
				{
					// Step 2
					onSuccess: ({ setupIntent }): void => {
						// Currently only supporting adding of credit card payment method.
						// FUTURE TO-DO: Handle other `status` scenarios.
						if (setupIntent?.status === 'succeeded') {
							void addPaymentMethod(
								{
									paymentMethod: {
										cardType: PaymentMethodCardType.Stripe,
										token: setupIntent.payment_method
									}
								},
								{
									// Steps 3 and 4
									onSettled: (): void => {
										const invalidatePaymentMethodQueries = async (): Promise<void> => {
											// Invalidates all payments-related queries.
											await queryClient.invalidateQueries({
												queryKey: [ROOT_PAYMENTS_QUERY_KEY]
											})
										}
										void invalidatePaymentMethodQueries()
									}
								}
							)
						}
					}
				}
			)
		}
	}

	const isLoading = isAddPaymentMethodPending || isStripeConfirmSetupIntentPending

	const handleReturnClick = (): void => {
		navigate(-1)
	}

	// Add Payment Method Success
	if (isAddPaymentMethodSuccess) {
		return (
			<Notice
				type="success"
				header="Payment Method Added Successfully"
				buttonProps={{ children: 'Proceed', onClick: handleReturnClick }}
			/>
		)
	}
	// Add Payment Method Error
	else if (isAddPaymentMethodError || isStripeConfirmSetupIntentError) {
		return (
			<Notice
				type="error"
				header="Oops! Something Went Wrong"
				description="Your payment method could not be added."
				buttonProps={{ children: 'Return', variant: 'secondary', onClick: handleReturnClick }}
			/>
		)
	}
	// Stripe Form
	return (
		<>
			<PaymentElement className="flex-grow" />
			<Button disabled={isLoading} loading={isLoading} onClick={handleAddPaymentMethod}>
				Confirm
			</Button>
		</>
	)
}

const MemoisedStripeForm = memo(StripeForm)

export default MemoisedStripeForm
