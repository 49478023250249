import { SVGProps, memo } from 'react'

const EmailIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 23 23',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M16.2915 19.6457H6.70817C3.83317 19.6457 1.9165 18.2082 1.9165 14.854V8.14567C1.9165 4.7915 3.83317 3.354 6.70817 3.354H16.2915C19.1665 3.354 21.0832 4.7915 21.0832 8.14567V14.854C21.0832 18.2082 19.1665 19.6457 16.2915 19.6457Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.2918 8.625L13.2922 11.0208C12.3052 11.8067 10.6856 11.8067 9.69849 11.0208L6.7085 8.625"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedEmailIcon = memo(EmailIcon)

export default MemoisedEmailIcon
