import { useMemo } from 'react'
import { PowerType } from 'src/_shared/_old/enums'
import { OmniConnector, OmniEVSE } from 'src/_shared/_old/schemas/evse'
import {
	getConnectorPowerType,
	getConnectorPowerRating,
	handleCheckAvailability
} from 'src/_shared/_old/utils/charging'

import RatingBox from './RatingBox'

interface RatingBoxesProps {
	evses: OmniEVSE[]
	operatorName: string
	powerFilter?: PowerType | null
}

type ConnectorsMap = Partial<
	Record<string, { connectors: OmniConnector[]; availableCount: number }>
>

const RatingBoxes = ({ evses, operatorName, powerFilter }: RatingBoxesProps) => {
	const { acConnectorsMap, dcConnectorsMap } = useMemo(() => {
		// AC and DC each has their own mapping of Power Rating to their Connectors.
		const acConnectorsMap: ConnectorsMap = {}
		const dcConnectorsMap: ConnectorsMap = {}
		evses.forEach((evse) => {
			evse.connectors.forEach((connector) => {
				// Every connector can have their own power rating and power type,
				// i.e. connectors within the same EVSE are not necessarily identical.
				const isAvailable = handleCheckAvailability(evse, operatorName, connector)
				const powerRating = getConnectorPowerRating(connector)
				const powerType = getConnectorPowerType(connector)
				const connectorsMap = powerType === PowerType.AC ? acConnectorsMap : dcConnectorsMap
				connectorsMap[powerRating] = {
					availableCount: (connectorsMap[powerRating]?.availableCount ?? 0) + (isAvailable ? 1 : 0),
					connectors: [...(connectorsMap[powerRating]?.connectors ?? []), connector]
				}
			})
		})
		return {
			acConnectorsMap,
			dcConnectorsMap
		}
	}, [evses, operatorName])

	return (
		<div className="flex flex-col gap-2">
			{[
				{
					powerType: PowerType.AC,
					connectorsMap: acConnectorsMap
				},
				{
					powerType: PowerType.DC,
					connectorsMap: dcConnectorsMap
				}
			].map(({ powerType, connectorsMap }) => {
				// Show the Rating Box if there is no power type filter applied
				// or if the  power type filter applied is matching.
				if (!powerFilter || powerFilter === powerType) {
					return (
						Object.entries(connectorsMap)
							// Sort by descending power rating value.
							.sort(([strPowerRatingA], [strPowerRatingB]): number => {
								const parsePowerRating = (strPowerRating: string) =>
									isNaN(Number(strPowerRating)) ? -1 : Number(strPowerRating)
								const powerRatingA = parsePowerRating(strPowerRatingA)
								const powerRatingB = parsePowerRating(strPowerRatingB)
								return powerRatingB - powerRatingA
							})
							.map(([powerRating, connectorsMap], index) => {
								return (
									<RatingBox
										key={index}
										connectors={connectorsMap?.connectors ?? []}
										availableCount={connectorsMap?.availableCount ?? 0}
										powerType={powerType}
										powerRating={powerRating}
									/>
								)
							})
					)
				}
				// Hide the rating box.
				return null
			})}
		</div>
	)
}

export default RatingBoxes
