import { AiOutlineLogin } from 'react-icons/ai'
import { BiTrash } from 'react-icons/bi'
import { HiArrowLeft, HiCheck, HiOutlineBell } from 'react-icons/hi'
import { RxCross2 } from 'react-icons/rx'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import QuestionMarkCircledIcon from 'src/_shared/_old/assets/svgs/QuestionMarkCircledIcon'
import SortArrow from 'src/_shared/_old/assets/svgs/SortArrow'
import { TOPBAR_HEIGHT } from 'src/_shared/_old/enums'
import { STRAPI_URL } from 'src/_shared/constants/env'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

interface TopbarProps {
	hide?: boolean
	title?: string
	logoTheme?: string
	showLogo?: boolean
	showBackIcon?: boolean
	showCrossIcon?: boolean
	showTrashIcon?: boolean
	showCheckIcon?: boolean
	showBellIcon?: boolean
	showSortIcon?: boolean
	showLoginIcon?: boolean
	showHelpIcon?: boolean
	backFunction?: () => void
	loginFunction?: () => void
	filterFunction?: () => void
	sortFunction?: () => void
	trashFunction?: () => void
	checkFunction?: () => void
	bellFunction?: () => void
	className?: string
}

const Topbar = (props: TopbarProps) => {
	const {
		hide,
		title,
		logoTheme,
		showLogo,
		showBackIcon,
		showCrossIcon,
		showTrashIcon,
		showBellIcon,
		showCheckIcon,
		showSortIcon,
		showLoginIcon,
		showHelpIcon,
		backFunction,
		loginFunction,
		sortFunction,
		trashFunction,
		checkFunction,
		bellFunction,
		className = ''
	} = props

	const navigate = useRouterNavigate()

	const { brandData } = useStrapiContext()

	const { logoDark, logoLight, theme } = brandData?.attributes ?? {}

	const { logo = { mode: 'light' }, ...topBarStyle } = theme?.components?.topBar ?? {}

	const finalLogoTheme = logoTheme ?? logo.mode

	function getLogoUrl() {
		if (finalLogoTheme === 'dark') {
			return logoDark?.data?.attributes.url
		} else {
			return logoLight?.data?.attributes.url
		}
	}

	if (hide) return null

	return (
		<div
			className={`absolute top-0 z-20 w-full max-w-112 ${className}`}
			style={{
				...topBarStyle,
				height: TOPBAR_HEIGHT
			}}
		>
			<div className="relative grid h-full w-full grid-cols-3 items-center">
				{/* logo / heading */}
				<div className="pointer-events-none absolute flex h-full w-full items-center justify-center">
					{showLogo ? (
						<>
							{getLogoUrl() && (
								<button
									className="pointer-events-auto"
									onClick={() => {
										navigate(ScreenRoutePath.Map)
									}}
								>
									<img
										style={{
											width: 'auto',
											height: logo.height
										}}
										src={`${STRAPI_URL}${getLogoUrl()}`}
										alt={logoDark?.data?.attributes.alternativeText ?? 'logo'}
									/>
								</button>
							)}
						</>
					) : (
						<p className="whitespace-nowrap text-lg font-medium">{title}</p>
					)}
				</div>

				{/* left icon */}
				<div className="flex h-full w-full items-center justify-start pl-5">
					{showBackIcon ? (
						<button
							onClick={() => {
								backFunction ? backFunction() : navigate(-1)
							}}
						>
							<HiArrowLeft className="h-4 w-auto" />
						</button>
					) : showCrossIcon ? (
						<button
							onClick={() => {
								backFunction ? backFunction() : navigate(-1)
							}}
						>
							<RxCross2 className="h-5 w-auto" />
						</button>
					) : null}
				</div>

				{/* empty div for grid-cols-3 */}
				<div></div>

				{/* right icon */}
				<div className="flex h-full w-full items-center justify-end pr-5">
					{showLoginIcon ? (
						<button
							data-testid="login"
							onClick={() => {
								loginFunction?.()
							}}
						>
							<AiOutlineLogin className="h-5 w-auto" />
						</button>
					) : showSortIcon ? (
						<button
							onClick={() => {
								sortFunction?.()
							}}
						>
							<SortArrow className="h-5 w-auto" />
						</button>
					) : showTrashIcon ? (
						<button
							onClick={() => {
								trashFunction?.()
							}}
						>
							<BiTrash className="h-6 w-auto" />
						</button>
					) : showCheckIcon ? (
						<button
							onClick={() => {
								checkFunction?.()
							}}
						>
							<HiCheck className="h-6 w-auto" />
						</button>
					) : showBellIcon ? (
						<button
							onClick={() => {
								bellFunction?.()
							}}
						>
							<HiOutlineBell className="h-6 w-auto text-white" />
						</button>
					) : showHelpIcon ? (
						<button
							onClick={() => {
								navigate(ScreenRoutePath.AccountHelp)
							}}
						>
							<QuestionMarkCircledIcon className="h-4 w-4" />
						</button>
					) : null}
				</div>
			</div>
		</div>
	)
}

export default Topbar
