import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { STRAPI_URL } from 'src/_shared/constants/env'
import { Language } from 'src/_shared/enums/env'
import { ROOT_STRAPI_QUERY_KEY, StrapiQueryKey, StrapiResponse } from 'src/_shared/queries/strapi'

interface StrapiTermsAndConditionsQueryParams {
	locale?: Language
}

interface StrapiTermsAndConditionsTranslationsData {
	id: number
	attributes: Partial<{
		translations: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		createdAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		updatedAt: string
		/**
		 * Format: `YYYY-MM-DDTHH:mm:ss.SSSZ`.
		 */
		publishedAt: string
		locale: Language
	}>
}

export const useStrapiTermsAndConditionsQuery = <TData = StrapiTermsAndConditionsTranslationsData,>(
	params: StrapiTermsAndConditionsQueryParams,
	options?: Omit<
		UseQueryOptions<
			StrapiTermsAndConditionsTranslationsData,
			AxiosError,
			TData,
			[string, StrapiQueryKey.StrapiTermsAndConditions, StrapiTermsAndConditionsQueryParams]
		>,
		'queryFn' | 'queryKey'
	>
) => {
	return useQuery({
		...options,
		queryKey: [ROOT_STRAPI_QUERY_KEY, StrapiQueryKey.StrapiTermsAndConditions, params],
		queryFn: async (): Promise<StrapiTermsAndConditionsTranslationsData> => {
			try {
				const { locale } = params
				const response = await axios.get<StrapiResponse<StrapiTermsAndConditionsTranslationsData>>(
					`${STRAPI_URL}/api/terms-and-conditions`,
					{
						params: {
							locale
						}
					}
				)
				const data: StrapiTermsAndConditionsTranslationsData | undefined | null = response.data.data

				if (!data) {
					console.debug('[useStrapiTermsAndConditionsQuery] No Translations Data Found')
					return {
						id: -1,
						attributes: {}
					}
				}
				return data
			} catch (error) {
				const axiosError = error as AxiosError<
					StrapiResponse<StrapiTermsAndConditionsTranslationsData>
				>
				return Promise.reject(axiosError)
			}
		}
	})
}
