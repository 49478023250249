import { SVGProps, memo } from 'react'

const ClockFilledIcon = ({
	fill = 'none',
	viewBox = '0 0 20 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M10 0C4.49 0 0 4.49 0 10C0 15.51 4.49 20 10 20C15.51 20 20 15.51 20 10C20 4.49 15.51 0 10 0ZM14.35 13.57C14.21 13.81 13.96 13.94 13.7 13.94C13.57 13.94 13.44 13.91 13.32 13.83L10.22 11.98C9.45 11.52 8.88 10.51 8.88 9.62V5.52C8.88 5.11 9.22 4.77 9.63 4.77C10.04 4.77 10.38 5.11 10.38 5.52V9.62C10.38 9.98 10.68 10.51 10.99 10.69L14.09 12.54C14.45 12.75 14.57 13.21 14.35 13.57Z"
				fill="currentColor"
			/>
		</svg>
	)
}

const MemoisedClockFilledIcon = memo(ClockFilledIcon)

export default MemoisedClockFilledIcon
