import { FiArrowUpLeft } from 'react-icons/fi'
import LocationPinFill from 'src/_shared/_old/assets/svgs/LocationPinFill'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { Place } from 'src/_shared/_old/utils/map'
import { getDistance, distanceBetween } from 'src/_shared/_old/utils/math'

interface MatchedLocationProps {
	currentCoords: Coordinates
	place: Place
	showBottomBorder: boolean
	isCurrentCoordsAvailable: boolean
}
const MatchedLocation = ({
	currentCoords,
	place,
	showBottomBorder,
	isCurrentCoordsAvailable
}: MatchedLocationProps) => {
	return (
		<>
			<div
				className={`flex w-full flex-row items-start justify-between border-primary-400/30 py-5 ${
					showBottomBorder ? 'border-b' : 'border-none'
				}`}
			>
				<div className="flex w-11/12 flex-row items-center justify-start space-x-5">
					<div className="flex w-1/6 flex-col items-center justify-center space-y-1">
						<LocationPinFill className="h-5 w-auto text-primary-800" />
						{isCurrentCoordsAvailable && (
							<p className="caption-3-normal text-typography-tertiary">
								{getDistance(
									distanceBetween(
										{
											lat: place.latitude,
											lng: place.longitude
										},
										currentCoords
									)
								)}
							</p>
						)}
					</div>
					<div className="flex w-5/6 flex-col items-start justify-center">
						<p className="body-2-normal text-left font-medium text-typography-primary">
							{place.structured_formatting.main_text}
						</p>
						<p className="caption-3-normal text-left text-typography-tertiary">
							{place.description}
						</p>
					</div>
				</div>
				<FiArrowUpLeft className="h-5 w-auto pl-5 text-typography-primary" />
			</div>
		</>
	)
}

export default MatchedLocation
