import { Link } from 'react-router-dom'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { useAuthContext } from 'src/_shared/hooks/useAuthContext'
import ProfileScreenLabelValueListItem from 'src/screens/AccountProfileScreen/components/ProfileScreenLabelValueListItem'

const AccountProfileScreen = (): JSX.Element => {
	const { user } = useAuthContext()

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			topBarProps={{
				centerRender: <h1>My Profile</h1>
			}}
			hideBottomBar
		>
			{!user ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					<div className="flex-grow">
						{/* Account Details */}
						<div className="mb-6 flex flex-col space-y-5">
							<h1 className="text-typography-primary">Account Details</h1>
							<ProfileScreenLabelValueListItem
								label="Phone Number"
								layout="vertical"
								value={user.mobileNumber ? user.mobileNumber : '-'}
							/>
							<div className="flex flex-col">
								<label className="mb-1 text-sm font-normal text-grayscale-600">Password</label>
								<div className="flex items-center justify-between">
									<input
										id="password"
										type="password"
										value="********"
										className="bg-transparent"
										disabled
									/>
									<Link
										className="body-1-normal text-typography-primary"
										to={ScreenRoutePath.AccountProfileChangePassword}
									>
										Change
									</Link>
								</div>
							</div>
						</div>
						<hr className="mb-6 border-t border-primary-400/30" />
						{/* Profile */}
						<div className="mb-8 flex flex-col space-y-4">
							<h1 className="text-typography-primary">Profile</h1>
							<ProfileScreenLabelValueListItem
								label="Name"
								layout="horizontal"
								value={user.name ? user.name : '-'}
							/>
							<ProfileScreenLabelValueListItem
								label="Email"
								layout="horizontal"
								value={user.email ? user.email : '-'}
							/>
							<ProfileScreenLabelValueListItem
								label="License Plate No."
								layout="horizontal"
								value={user.vehiclePlateNumber ? user.vehiclePlateNumber : '-'}
							/>
						</div>
					</div>
					<div className="flex flex-col space-y-2">
						<Button
							className="w-full"
							variant="ghost"
							linkProps={{ to: ScreenRoutePath.AccountProfileEdit }}
						>
							Edit Profile
						</Button>
						{/* FUTURE TODO: Pending confirmation on delete account implementation (or removal) */}
						<Button className="hidden w-full" variant="error">
							Delete Account
						</Button>
					</div>
				</>
			)}
		</ScreenContainer>
	)
}

export default AccountProfileScreen
