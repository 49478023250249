import { SVGProps, memo } from 'react'

const EvmeWarningIcon = ({
	viewBox = '0 0 61 54',
	fill = 'currentColor',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M30.5 14.1523C31.6046 14.1523 32.5 15.0478 32.5 16.1523L32.5 38.8563C32.5 39.9609 31.6046 40.8563 30.5 40.8563C29.3954 40.8563 28.5 39.9609 28.5 38.8563L28.5 16.1523C28.5 15.0478 29.3954 14.1523 30.5 14.1523Z" />
			<path d="M32.2778 44.8475C32.2778 45.808 31.4992 46.5867 30.5387 46.5867C29.5782 46.5867 28.7996 45.808 28.7996 44.8475C28.7996 43.887 29.5782 43.1084 30.5387 43.1084C31.4992 43.1084 32.2778 43.887 32.2778 44.8475Z" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.1297 1.87775C29.1831 0.040751 31.8169 0.0407472 32.8703 1.87775L60.1292 49.4102C61.1827 51.2472 59.8659 53.5435 57.7589 53.5435H3.24112C1.13415 53.5435 -0.182708 51.2472 0.870775 49.4102L28.1297 1.87775ZM5.40543 49.5435L30.5 5.78503L55.5946 49.5435H5.40543Z"
			/>
		</svg>
	)
}

const MemoisedEvmeWarningIcon = memo(EvmeWarningIcon)

export default MemoisedEvmeWarningIcon
