export enum AccountRegistrationScreenViewItemKey {
	EnterOtpView = 'EnterOtpView',
	RegistrationView = 'RegistrationView'
}

export enum FormFieldKey {
	AcceptTermsAndConditions = 'acceptTermsAndConditions',
	ConfirmPassword = 'confirmPassword',
	CountryCode = 'countryCode',
	Email = 'email',
	LicensePlateNumber = 'licensePlateNumber',
	MobileNumber = 'mobileNumber',
	Name = 'name',
	Otp = 'otp',
	Password = 'password'
}
