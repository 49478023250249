function Directions({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 25 24" fill="none">
			<path
				d="M9.37999 4.59997C8.57999 1.13997 3.36999 1.12997 2.56999 4.59997C2.09999 6.62997 3.38999 8.34997 4.50999 9.41997C5.32999 10.19 6.61999 10.19 7.43999 9.41997C8.55999 8.34997 9.83999 6.62997 9.37999 4.59997ZM6.00999 6.19997C5.45999 6.19997 5.00999 5.74997 5.00999 5.19997C5.00999 4.64997 5.44999 4.19997 5.99999 4.19997H6.00999C6.56999 4.19997 7.00999 4.64997 7.00999 5.19997C7.00999 5.74997 6.56999 6.19997 6.00999 6.19997Z"
				fill="currentColor"
			/>
			<path
				d="M22.41 16.6C21.61 13.14 16.38 13.13 15.57 16.6C15.1 18.63 16.39 20.35 17.52 21.42C18.34 22.19 19.64 22.19 20.46 21.42C21.59 20.35 22.88 18.63 22.41 16.6ZM19.03 18.2C18.48 18.2 18.03 17.75 18.03 17.2C18.03 16.65 18.47 16.2 19.02 16.2H19.03C19.58 16.2 20.03 16.65 20.03 17.2C20.03 17.75 19.58 18.2 19.03 18.2Z"
				fill="currentColor"
			/>
			<path
				d="M12.4999 19.75H9.81994C8.65994 19.75 7.64994 19.05 7.24994 17.97C6.83994 16.89 7.13994 15.7 8.00994 14.93L15.9999 7.94C16.4799 7.52 16.4899 6.95 16.3499 6.56C16.1999 6.17 15.8199 5.75 15.1799 5.75H12.4999C12.0899 5.75 11.7499 5.41 11.7499 5C11.7499 4.59 12.0899 4.25 12.4999 4.25H15.1799C16.3399 4.25 17.3499 4.95 17.7499 6.03C18.1599 7.11 17.8599 8.3 16.9899 9.07L8.99994 16.06C8.51994 16.48 8.50994 17.05 8.64994 17.44C8.79994 17.83 9.17994 18.25 9.81994 18.25H12.4999C12.9099 18.25 13.2499 18.59 13.2499 19C13.2499 19.41 12.9099 19.75 12.4999 19.75Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default Directions
