import { memo, useState } from 'react'
import { containsHtml } from 'src/_shared/utils/markdown'

import MinusIcon from '../../../_shared/components/_icons/MinusIcon'
import PlusIcon from '../../../_shared/components/_icons/PlusIcon'

interface CollapseProps {
	title: JSX.Element | string
	description: JSX.Element | string
}

const Collapse = ({ title, description }: CollapseProps): JSX.Element => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

	const handleToggleIsCollapsedClick = (): void => {
		setIsCollapsed(!isCollapsed)
	}

	const descContainsHTML = typeof description === 'string' && containsHtml(description)

	return (
		<div className="mb-4 border-primary-400/30 [&:last-child]:mb-6 [&:not(:last-child)]:border-b-[1px]">
			<div
				className="mb-3 flex cursor-pointer justify-between"
				onClick={handleToggleIsCollapsedClick}
			>
				<div className="mr-3">
					<p className="body-3-medium hyphens-auto text-typography-primary">{title}</p>
				</div>
				<div>
					<button name={isCollapsed ? 'expand' : 'collapse'}>
						{isCollapsed ? (
							<PlusIcon className="h-6 w-6 text-typography-primary" />
						) : (
							<MinusIcon className="h-6 w-6 text-typography-primary" />
						)}
					</button>
				</div>
			</div>

			{!isCollapsed &&
				(descContainsHTML ? (
					<div
						className="markdown body-2-normal mb-3 text-justify text-typography-tertiary"
						dangerouslySetInnerHTML={{ __html: description }}
					/>
				) : (
					<div className="body-2-normal mb-3 text-justify text-typography-tertiary">
						{description}
					</div>
				))}
		</div>
	)
}

const MemoisedCollapse = memo(Collapse)

export default MemoisedCollapse
