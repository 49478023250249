import { useCallback, useEffect, useMemo, useState } from 'react'
import { PowerType, FilterType, SubscriptionType } from 'src/_shared/_old/enums'
import { Filter } from 'src/_shared/_old/schemas/typings'
import { DISABLE_SUBSCRIPTION } from 'src/_shared/constants/env'

import { classNames } from '../../../../_shared/utils/elements'
import { formatDist } from '../../utils/format'
import PopupContainer from '../containers/PopupContainer'
import SlideRange from '../map/SlideRange'

interface FilterPopupProps {
	showPopup: boolean
	showDistFilter: boolean
	closePopup: () => void
	onClickApply: (filters: Filter) => void
	minDistance: number
	maxDistance: number
	filtersVal: Filter
	cpoList: string[]
	filterType?: FilterType | PowerType
}

const IS_SUBSCRIPTION_ENABLED = !DISABLE_SUBSCRIPTION

const FilterPopup = (props: FilterPopupProps) => {
	const {
		showPopup,
		showDistFilter,
		closePopup,
		onClickApply,
		minDistance,
		maxDistance,
		filtersVal,
		cpoList = [],
		filterType
	} = props

	const [stopTouchProp, setStopTouchProp] = useState<boolean>(false)

	const [powerTypeFilter, setPowerTypeFilter] = useState<PowerType | null>(null)

	const [cpoFilter, setCpoFilter] = useState<string[]>([])

	const [isSubscribedFilter, setIsSubscribedFilter] = useState<SubscriptionType | null>(null)

	const [distValue, setDistValue] = useState([minDistance * 1000, maxDistance * 1000])

	// Colours for Ripple Button
	const SELECTED_COLOUR = 'border-primary-800 bg-primary-800 text-grayscale-100'
	const UNSELECTED_COLOUR = 'border-primary-400/30 bg-white text-typography-primary'

	const distLowest = useMemo(() => {
		return (distValue[0] ?? 0) / 1000
	}, [distValue])

	const distHighest = useMemo(() => {
		return (distValue[1] ?? 0) / 1000
	}, [distValue])

	const filters = useMemo(() => {
		return {
			distLowest,
			distHighest,
			cpoFilter,
			powerTypeFilter,
			isSubscribedFilter
		}
	}, [distLowest, distHighest, cpoFilter, powerTypeFilter, isSubscribedFilter])

	const initialiseAppliedFilterValues = useCallback(() => {
		setDistValue([
			(filtersVal.distLowest || minDistance) * 1000,
			(filtersVal.distHighest || maxDistance) * 1000
		])
		setCpoFilter(filtersVal.cpoFilter)
		setPowerTypeFilter(filtersVal.powerTypeFilter)
		setIsSubscribedFilter(filtersVal.isSubscribedFilter)
	}, [filtersVal, maxDistance, minDistance])

	const handleSelectCpo = (selectedCpo: string) => {
		setCpoFilter((prevCpos) => {
			if (prevCpos.includes(selectedCpo)) {
				return prevCpos.filter((cpo) => cpo !== selectedCpo)
			} else {
				return [...prevCpos, selectedCpo]
			}
		})
	}

	const handleFilterChange = (selectedType: string, filterType: string) => {
		if (filterType === 'powerType') {
			const powerType = selectedType as PowerType
			setPowerTypeFilter((prevType) => (prevType === powerType ? null : powerType))
		} else if (filterType === 'isSubscribed') {
			const subscriptionType = selectedType as SubscriptionType
			setIsSubscribedFilter((prevType) => (prevType === subscriptionType ? null : subscriptionType))
		}
	}

	const clearFilters = () => {
		if (filterType === FilterType.CPO) {
			setCpoFilter([])
		} else {
			setDistValue([minDistance * 1000, maxDistance * 1000])
			setCpoFilter([])
			setPowerTypeFilter(null)
			setIsSubscribedFilter(null)
		}
	}

	// Note: Only called when user deliberately closes the popup.
	const handleFilterPopupClose = () => {
		initialiseAppliedFilterValues()
		closePopup()
	}

	useEffect(() => {
		initialiseAppliedFilterValues()
	}, [initialiseAppliedFilterValues])

	if (!showPopup) {
		return null
	}
	return (
		<PopupContainer
			className="!px-0"
			stopTouchProp={stopTouchProp}
			onClose={handleFilterPopupClose}
		>
			{/* line decoration */}
			<div className="flex w-full items-center justify-center py-1">
				<div className="h-[2.5px] w-16 rounded-full bg-grayscale-500"></div>
			</div>
			<div className="flex w-full flex-row items-center justify-between rounded-t-3xl px-6 py-3">
				<p className="text-base font-medium text-typography-primary">Filter</p>
				<button>
					<p
						className="text-sm text-typography-primary"
						onClick={() => {
							clearFilters()
						}}
					>
						Clear
					</p>
				</button>
			</div>

			{/* Charge Point Operators */}
			<div className="max-h-[70vh] w-full overflow-scroll px-6">
				{(filterType === FilterType.CPO || filterType === FilterType.ALL) && (
					<div className="flex w-full flex-col space-y-1 pb-2 pt-3">
						<p className="text-sm font-medium text-typography-primary">Charge Point Operator</p>
						<div className="flex w-full flex-row flex-wrap justify-start pt-1">
							{cpoList.map((cpo) => (
								<button
									key={cpo}
									className={classNames(
										`mb-[7px] mr-[7px] rounded-md border px-4 py-1`,
										cpoFilter.includes(cpo) ? SELECTED_COLOUR : UNSELECTED_COLOUR
									)}
									onClick={() => {
										handleSelectCpo(cpo)
									}}
								>
									<p className="text-base font-medium">{cpo}</p>
								</button>
							))}
						</div>
					</div>
				)}

				{filterType === FilterType.ALL && (
					<>
						{/* Subscription */}
						{IS_SUBSCRIPTION_ENABLED && (
							<div className="flex w-full flex-col space-y-1 pb-2 pt-3">
								<p className="text-sm font-medium text-typography-primary">User Type</p>
								<div className="flex w-full flex-row justify-start space-x-2 pt-1">
									<button
										className={classNames(
											`rounded-md border px-4 py-1`,
											isSubscribedFilter === SubscriptionType.SUBSCRIBED
												? SELECTED_COLOUR
												: UNSELECTED_COLOUR
										)}
										onClick={() => {
											handleFilterChange(SubscriptionType.SUBSCRIBED, 'isSubscribed')
										}}
									>
										<p className="text-base font-medium">Subscribed</p>
									</button>
									<button
										className={classNames(
											`rounded-md border px-4 py-1`,
											isSubscribedFilter === SubscriptionType.UNSUBSCRIBED
												? SELECTED_COLOUR
												: UNSELECTED_COLOUR
										)}
										onClick={() => {
											handleFilterChange(SubscriptionType.UNSUBSCRIBED, 'isSubscribed')
										}}
									>
										<p className="text-base font-medium">Non-Subscribed</p>
									</button>
								</div>
							</div>
						)}
						{/* Power Type */}
						<div className="mb-3 flex w-full flex-col space-y-1 pb-2 pt-3">
							<p className="text-sm font-medium text-typography-primary">Power Type</p>
							<div className="flex w-full flex-row justify-start space-x-2 pt-1">
								<button
									className={classNames(
										`rounded-md border px-4 py-1`,
										powerTypeFilter === PowerType.AC ? SELECTED_COLOUR : UNSELECTED_COLOUR
									)}
									onClick={() => {
										handleFilterChange(PowerType.AC, 'powerType')
									}}
								>
									<p className="text-base font-medium">AC Only</p>
								</button>
								<button
									className={classNames(
										`rounded-md border px-4 py-1`,
										powerTypeFilter === PowerType.DC ? SELECTED_COLOUR : UNSELECTED_COLOUR
									)}
									onClick={() => {
										handleFilterChange(PowerType.DC, 'powerType')
									}}
								>
									<p className="text-base font-medium">DC Only</p>
								</button>
							</div>
						</div>
						{/* Distance Filter */}
						{showDistFilter && (
							<div className="flex w-full flex-col space-y-1 pb-2 pt-3">
								<p className="text-sm font-medium text-typography-primary">Distance</p>
								<div className="flex w-full flex-col justify-center space-y-3">
									<div className="flex flex-row items-center justify-start space-x-2">
										<p className="text-sm font-medium">{formatDist(distLowest)}</p>
										<p className="text-base font-medium text-typography-tertiary">•</p>
										<p className="text-sm font-medium">{formatDist(distHighest)}</p>
									</div>
									<div
										onTouchStartCapture={() => {
											setStopTouchProp(true)
										}}
										onTouchStart={() => {
											setStopTouchProp(true)
										}}
										onTouchMove={() => {
											setStopTouchProp(true)
										}}
										onTouchEnd={() => {
											setStopTouchProp(false)
										}}
									>
										<SlideRange
											controlledValue={distValue}
											min={minDistance * 1000}
											max={maxDistance * 1000}
											minDistance={500}
											onChange={(val: number[]) => {
												setDistValue(val)
											}}
										/>
									</div>
								</div>
								<div className="flex w-full flex-row items-center justify-between pt-4">
									<p className="text-sm font-light text-typography-primary">
										{formatDist(minDistance)}
									</p>
									<p className="text-sm font-light text-typography-primary">
										{formatDist(maxDistance)}
									</p>
								</div>
							</div>
						)}
					</>
				)}
				<button
					className="flex w-full flex-row items-center justify-center rounded-xl bg-primary-800 p-4"
					onClick={() => {
						onClickApply(filters)
						closePopup()
					}}
				>
					<p className="relative z-10 text-base font-medium text-white">Apply Filter</p>
				</button>
			</div>
		</PopupContainer>
	)
}

export default FilterPopup
