import { SVGProps, memo } from 'react'

const ChevronDownIcon = ({
	fill = 'currentColor',
	stroke = 'none',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z" />
		</svg>
	)
}

const MemoisedChevronDownIcon = memo(ChevronDownIcon)

export default MemoisedChevronDownIcon
