import dayjs from 'dayjs'
import { ReactNode, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { TIME_FORMAT } from 'src/_shared/constants/env'
import { OmniSessionStatus } from 'src/_shared/enums/omni'
import { PaymentMethodCode } from 'src/_shared/enums/payments'
import { OmniSession } from 'src/_shared/types/omni'
import { getCurrencyDetails } from 'src/_shared/utils/currency'
import { getPaymentBreakdown } from 'src/_shared/utils/receipt'

import GrabPayStackedLogoImage from '../_images/GrabPayStackedLogoImage'
import TouchNGoLogoImage from '../_images/TouchNGoLogoImage'
import VisaAndMastercardStackedLogosImage from '../_images/VisaAndMasterStackedLogosImage'
import LabelValueList from './components/LabelValueList'
import { LabelValueListItemProps } from './components/LabelValueListItem'

interface ReceiptDetailsProps {
	chargingSession?: OmniSession
}

const ReceiptDetails = ({ chargingSession }: ReceiptDetailsProps): JSX.Element => {
	const intl = useIntl()

	const sessionDetailsLabelValueList = useMemo((): {
		label: string
		value: ReactNode
	}[] => {
		const formatDateValue = (dateStr: string): string => {
			return dayjs(dateStr).format(`MMM DD YYYY ${TIME_FORMAT}`)
		}
		return [
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionStatus',
					defaultMessage: 'Status'
				}),
				value: ((): JSX.Element => {
					const formattedStatus = ((): string => {
						switch (chargingSession?.status) {
							case OmniSessionStatus.Active:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusActive',
									defaultMessage: 'Active'
								})
							case OmniSessionStatus.Completed:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusCompleted',
									defaultMessage: 'Completed'
								})
							case OmniSessionStatus.Pending:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusPending',
									defaultMessage: 'Pending'
								})
							case OmniSessionStatus.PendingPayment:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusPendingPayment',
									defaultMessage: 'Pending Payment'
								})
							case OmniSessionStatus.StartFailure:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStartFailure',
									defaultMessage: 'Start Failure'
								})
							case OmniSessionStatus.StopFailure:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStopFailure',
									defaultMessage: 'Stop Failure'
								})
							case OmniSessionStatus.Stopped:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusStopped',
									defaultMessage: 'Stopped'
								})
							default:
								return intl.formatMessage({
									id: 'ReceiptDetails.LabelValueSessionStatusUnknown',
									defaultMessage: 'Unknown'
								})
						}
					})()
					return (
						<span
							className={
								chargingSession?.status === OmniSessionStatus.Completed
									? 'text-success-400'
									: 'text-alert-300'
							}
						>
							{formattedStatus}
						</span>
					)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelTransactionNumber',
					defaultMessage: 'Transaction No.'
				}),
				value: chargingSession?._id ?? '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionStart',
					defaultMessage: 'Session Start'
				}),
				value: chargingSession?.start_date_time
					? formatDateValue(chargingSession.start_date_time)
					: '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelSessionEnd',
					defaultMessage: 'Session End'
				}),
				value: ((): string => {
					if (
						!chargingSession?.start_date_time ||
						!chargingSession.end_date_time ||
						dayjs(chargingSession.start_date_time).isSameOrAfter(chargingSession.end_date_time)
					) {
						return '-'
					}
					return formatDateValue(chargingSession.end_date_time)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelDuration',
					defaultMessage: 'Duration'
				}),
				value: ((): string => {
					if (
						!chargingSession?.start_date_time ||
						!chargingSession.end_date_time ||
						dayjs(chargingSession.start_date_time).isSameOrAfter(chargingSession.end_date_time)
					) {
						return '-'
					}

					// Note: Deliberately round down seconds to `0` for calculating difference.
					const minutesElapsed = dayjs(chargingSession.end_date_time)
						.set('seconds', 0)
						.diff(dayjs(chargingSession.start_date_time).set('seconds', 0), 'minutes')

					const totalChargingTimeHr = Math.floor(minutesElapsed / 60)

					const totalChargingTimeMin = minutesElapsed % 60
					return intl.formatMessage(
						{
							id: 'ReceiptDetails.LabelValueDuration',
							defaultMessage: '{hours}h {minutes}min'
						},
						{
							hours: totalChargingTimeHr,
							minutes: totalChargingTimeMin
						}
					)
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelCpo',
					defaultMessage: 'CPO'
				}),
				value: chargingSession?.operator?.name ?? '-'
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelChargerId',
					defaultMessage: 'Charger ID'
				}),
				// Display the physical reference or fallback to ID if it is not available.
				value: ((): string => {
					const evseId = chargingSession?.evse_id
					const evseUid = chargingSession?.evse_uid
					if (evseId) {
						const evse = chargingSession.location?.evses?.find((evse): boolean => {
							return evse.evse_id === evseId || evse.uid === evseUid
						})
						if (evse?.physical_reference) {
							return evse.physical_reference
						} else if (evse?.evse_id) {
							return evse.evse_id
						}
						return evseId
					}
					return '-'
				})()
			},
			{
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelLocation',
					defaultMessage: 'Charger Location'
				}),
				value: chargingSession?.location_name ?? '-'
			}
		]
	}, [chargingSession, intl])

	const paymentDetailsLabelValueList = useMemo((): LabelValueListItemProps[] => {
		const { prefix: currencyPrefix } = getCurrencyDetails(chargingSession?.currency)

		const paymentBreakdown = getPaymentBreakdown(chargingSession)

		// Energy Costs
		const totalEnergyChargedLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReceiptDetails.LabelEnergyDelivered',
				defaultMessage: 'Energy Delivered'
			}),
			value: intl.formatMessage(
				{
					id: 'ReceiptDetails.LabelValueEnergyDelivered',
					defaultMessage: '{totalKwh} kWh @ {pricePerKwh}/kWh'
				},
				{
					totalKwh: paymentBreakdown.totalKwh.toFixed(3),
					pricePerKwh: `${currencyPrefix}${paymentBreakdown.pricePerKwh.toFixed(3)}`
				}
			)
		}

		const energyCostLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReceiptDetails.LabelEnergyCost',
				defaultMessage: 'Energy Cost'
			}),
			value: `${currencyPrefix}${paymentBreakdown.energyCost.toFixed(3)}`
		}

		// Payment Method
		const paymentMethodValue = ((): ReactNode => {
			switch (chargingSession?.payment_method_code) {
				case PaymentMethodCode.Emv:
					return (
						<div className="flex flex-row items-center space-x-2">
							<VisaAndMastercardStackedLogosImage className="h-6 w-6" />
							<p>
								<FormattedMessage id="ReceiptDetails.LabelValueCard" defaultMessage="Card" />
							</p>
						</div>
					)
				case PaymentMethodCode.Subscription:
					return (
						<span className="text-orange-400">
							<FormattedMessage
								id="ReceiptDetails.LabelValueSubscription"
								defaultMessage="Subscription"
							/>
						</span>
					)
				case PaymentMethodCode.TouchNGo:
					return (
						<div className="flex flex-row items-center space-x-2">
							<TouchNGoLogoImage className="h-6 w-6" />
							<p>
								<FormattedMessage
									id="ReceiptDetails.LabelValueTngWallet"
									defaultMessage="TnG Wallet"
								/>
							</p>
						</div>
					)
				// Beep & Stripe Shared Value
				case PaymentMethodCode.Beep:
				case PaymentMethodCode.Stripe:
					return chargingSession.payment_method_token
						? `${chargingSession.payment_method_token.cardType} [xxxx ${chargingSession.payment_method_token.lastFourDigits}]`
						: '-'
				case PaymentMethodCode.FreeOfCharge:
					return <FormattedMessage id="ReceiptDetails.LabelValueFree" defaultMessage="Free" />
				case PaymentMethodCode.GrabPay:
					return (
						<div className="flex flex-row items-center space-x-2">
							<GrabPayStackedLogoImage className="h-6 w-6" />
							<p>
								<FormattedMessage id="ReceiptDetails.LabelValueGrabPay" defaultMessage="GrabPay" />
							</p>
						</div>
					)
				case PaymentMethodCode.Postpaid:
					return (
						<FormattedMessage id="ReceiptDetails.LabelValuePostpaid" defaultMessage="Postpaid" />
					)
				case PaymentMethodCode.Unknown:
					return <FormattedMessage id="ReceiptDetails.LabelValueUnknown" defaultMessage="Unknown" />
				default:
					return '-'
			}
		})()

		const paymentMethodLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage({
				id: 'ReceiptDetails.LabelPaymentMethod',
				defaultMessage: 'Payment Method'
			}),
			value: paymentMethodValue
		}

		const minimumFee = (chargingSession?.tariff?.min_price?.incl_vat ?? 0).toFixed(3)

		const totalCostLabelValue: LabelValueListItemProps = {
			label: intl.formatMessage(
				{
					id: 'ReceiptDetails.LabelTotalCostMinFee',
					defaultMessage: 'Total Cost (Min. Fee {minimumFee})'
				},
				{
					minimumFee: `${currencyPrefix}${minimumFee}`
				}
			),
			labelClassName: 'body-2-semibold',
			containerClassName: 'border-t-[1px] border-primary-400/30 pt-4',
			value: `${currencyPrefix}${paymentBreakdown.totalCost.toFixed(3)}`,
			valueClassName: paymentBreakdown.isCostFree ? 'text-typography-tertiary line-through' : null
		}

		const conditionalLabelValueList: LabelValueListItemProps[] = []

		// For Price Per Min Sessions
		if (paymentBreakdown.hasPricePerMinCost) {
			const pricePerMinLabelValue: LabelValueListItemProps = {
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelPricePerMin',
					defaultMessage: 'Price per Min'
				}),
				value: intl.formatMessage(
					{
						id: 'ReceiptDetails.LabelValueDurationRate',
						defaultMessage: '{minutes} min @ {pricePerMin}/min'
					},
					{
						minutes: paymentBreakdown.totalChargingTime,
						pricePerMin: `${currencyPrefix}${paymentBreakdown.pricePerMinTime.toFixed(3)}`
					}
				)
			}

			const pricePerMinCostLabelValue: LabelValueListItemProps = {
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelPricePerMinCost',
					defaultMessage: 'Price per Min Cost'
				}),
				value: `${currencyPrefix}${paymentBreakdown.pricePerMinCost.toFixed(3)}`
			}

			conditionalLabelValueList.push(pricePerMinLabelValue, pricePerMinCostLabelValue)
		}

		// For Idling Sessions
		if (paymentBreakdown.hasIdleCost) {
			const timeIdledLabelValue: LabelValueListItemProps = {
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelTimeIdled',
					defaultMessage: 'Time Idled'
				}),
				value: intl.formatMessage(
					{
						id: 'ReceiptDetails.LabelValueDurationRate',
						defaultMessage: '{minutes} min @ {pricePerMin}/min'
					},
					{
						minutes: paymentBreakdown.totalIdleTime,
						pricePerMin: `${currencyPrefix}${paymentBreakdown.pricePerIdleTime.toFixed(3)}`
					}
				)
			}

			const idleCostLabelValue: LabelValueListItemProps = {
				label: intl.formatMessage({
					id: 'ReceiptDetails.LabelIdleCost',
					defaultMessage: 'Idle Cost'
				}),
				value: `${currencyPrefix}${paymentBreakdown.idleCost.toFixed(3)}`
			}

			conditionalLabelValueList.push(timeIdledLabelValue, idleCostLabelValue)
		}

		// For Non-Idling Sessions
		return [
			paymentMethodLabelValue,
			totalEnergyChargedLabelValue,
			energyCostLabelValue,
			...conditionalLabelValueList,
			totalCostLabelValue
		]
	}, [chargingSession, intl])

	return (
		<div>
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReceiptDetails.TitleSessionDetails',
					defaultMessage: 'Session Details'
				})}
				className="mb-4 border-b-[1px] border-primary-400/30"
				labelValueList={sessionDetailsLabelValueList}
			/>
			<LabelValueList
				title={intl.formatMessage({
					id: 'ReceiptDetails.TitlePaymentDetails',
					defaultMessage: 'Payment Details'
				})}
				labelValueList={paymentDetailsLabelValueList}
			/>
		</div>
	)
}

export default ReceiptDetails
