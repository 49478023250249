import { ReactNode, memo, useMemo } from 'react'
import WarningIcon from 'src/_shared/components/_icons/WarningIcon'
import ChargerTimeoutImage from 'src/_shared/components/_images/ChargerTimeoutImage'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'

import Button, { ButtonProps } from '../Button'
import CheckCircleFilledIcon from '../_icons/CheckCircleFilledIcon'
import CheckCircleGradientIcon from '../_icons/CheckCircleGradientIcon'
import ErrorFilledIcon from '../_icons/ErrorFilledIcon'
import EvmeWarningIcon from '../_icons/EvmeWarningIcon'

export interface NoticeProps {
	buttonProps?: Omit<ButtonProps, 'ref'>
	description?: ReactNode
	header?: ReactNode
	type?: 'error' | 'success' | 'timeout' | 'warning'
}

/**
 * For this component to render nicely, it should be the only child element under `ScreenContainer`.
 */
const Notice = ({ buttonProps, description, header, type }: NoticeProps): JSX.Element => {
	const icon = useMemo((): ReactNode => {
		switch (BRAND) {
			case Brand.Evme: {
				switch (type) {
					case 'success':
						return (
							<CheckCircleGradientIcon
								className="mb-6 h-24 w-24"
								gradientFrom="#CBFF31"
								gradientTo="#80FFCA"
							/>
						)
					case 'error':
					case 'timeout':
					case 'warning':
						return <EvmeWarningIcon className="mb-6 h-auto w-20 text-primary-600" />
					default:
						return null
				}
			}
			default: {
				switch (type) {
					case 'error':
						return <ErrorFilledIcon className="mb-6 h-24 w-24 text-error-300" />
					case 'success':
						return <CheckCircleFilledIcon className="mb-6 h-24 w-24 text-success-400" />
					case 'timeout':
						return <ChargerTimeoutImage className="mb-6 h-24 w-24" />
					case 'warning':
						return <WarningIcon className="mb-6 h-24 w-24 text-alert-300" />
					default:
						return null
				}
			}
		}
	}, [type])

	return (
		<>
			<div className="flex flex-grow flex-col items-center justify-center">
				{icon}
				{typeof header === 'string' ? (
					<h1 className="mb-2 text-center text-typography-primary">{header}</h1>
				) : (
					header
				)}
				{typeof description === 'string' ? (
					<p className="body-2-normal text-center text-typography-secondary">{description}</p>
				) : (
					description
				)}
			</div>
			{buttonProps && <Button {...buttonProps} />}
		</>
	)
}

const MemoisedNotice = memo(Notice)

export default MemoisedNotice
