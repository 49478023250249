import { Coordinates } from '../schemas/geolocation'

export const deg2rad = (deg: number): number => {
	return deg * (Math.PI / 180)
}

export const distanceBetween = (c1: Coordinates, c2: Coordinates) => {
	// return 12742 * Math.asin(Math.sqrt(a));
	const R = 6371 // Radius of the earth in km
	const dLat = deg2rad(c2.lat - c1.lat) // deg2rad below
	const dLon = deg2rad(c2.lng - c1.lng)
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(c1.lat)) * Math.cos(deg2rad(c2.lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
	const distance = R * c // Distance in km
	return distance
}

export const getDistance = (distanceInKm: number) => {
	if (distanceInKm < 1) {
		return `${(distanceInKm * 1000).toFixed(0)}m`
	}
	return `${distanceInKm.toFixed(1)}km`
}

export const rgbToHex = (rgb: string): string => {
	const rgbList = rgb.split(' ')
	return '#' + toHex(rgbList[0]) + toHex(rgbList[1]) + toHex(rgbList[2])
}

export const toHex = (value: string): string => {
	let intValue = parseInt(value, 10)
	if (isNaN(intValue)) return '00'
	intValue = Math.max(0, Math.min(intValue, 255))
	return (
		'0123456789ABCDEF'.charAt((intValue - (intValue % 16)) / 16) +
		'0123456789ABCDEF'.charAt(intValue % 16)
	)
}

export const convertDistanceWithoutMetric = (distanceWithMetric: string): number => {
	if (distanceWithMetric.endsWith('m')) {
		if (distanceWithMetric.endsWith('km')) {
			// need to multiply 1000 in order to make a fair comparison with those that contain m only
			return parseFloat(distanceWithMetric.slice(0, distanceWithMetric.length - 2)) * 1000
		}
		return parseFloat(distanceWithMetric.slice(0, distanceWithMetric.length - 1))
	}
	return 0
}

export const convertPowerRating = (powerRating: number): string => {
	const ratingStr = powerRating.toString()
	// possible values 11.3, 195.5 etc etc
	// 7.4 would not fulfill this criteria
	if (ratingStr.length > 3) {
		// return everything before the decimal point
		return ratingStr.split('.')[0]
	} else return ratingStr
}
