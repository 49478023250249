import { useEffect } from 'react'
import { OmniConnector, OmniSession } from 'src/_shared/types/omni'

import { ChargerScreenViewItemKey } from '../enums'

interface UseChargerDebugLoggingArgs {
	connector: OmniConnector | null
	isUserChargingSession: boolean
	session: OmniSession | null
	viewItemKey: ChargerScreenViewItemKey
}

/**
 * For Browser Debugging.
 */
const useChargerDebugLogging = ({
	connector,
	isUserChargingSession,
	session,
	viewItemKey
}: UseChargerDebugLoggingArgs): void => {
	useEffect((): void => {
		console.debug(
			`[${viewItemKey}]\n\n`,
			`Connector Status: ${connector?.status ? connector.status : '-'}\n`,
			`• Is Enabled: ${connector?.is_enabled !== false}\n`,
			`• Is Published: ${connector?.is_published !== false}\n`,
			`• Online: ${connector?.online !== false}\n\n`,
			`Session Status: ${session?.status ? session.status : '-'}\n\n`,
			`Is User's Charging Session: ${isUserChargingSession}\n\n`,
			'Connector Data:\n',
			connector,
			'\n\n',
			'Session Data:\n',
			session
		)
	}, [connector, isUserChargingSession, session, viewItemKey])
}

export default useChargerDebugLogging
