import { SVGProps, memo } from 'react'

const HourglassGradientIcon = ({
	fill = 'none',
	stroke = 'none',
	viewBox = '0 0 91 144',
	xmlns = 'http://www.w3.org/2000/svg',
	coverFill = '#000000',
	glassFill = '#F3F3F3',
	gradientFrom = '#FFFFFF',
	gradientTo = '#000000',
	...props
}: SVGProps<SVGSVGElement> & {
	coverFill?: string
	glassFill?: string
	gradientFrom?: string
	gradientTo?: string
}) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<g clipPath="url(#clip0_227_8324)">
				{/* Top Cover */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.6079 4.15908H80.3923C81.5246 4.15908 82.4719 3.22928 82.4719 2.07954C82.4719 0.947289 81.5246 0 80.3923 0H10.6079C9.45811 0 8.52832 0.947289 8.52832 2.07954C8.52832 3.22928 9.45811 4.15908 10.6079 4.15908Z"
					fill={coverFill}
					stroke={coverFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.9088 4.15909H85.0912C88.0655 4.15909 90.5 6.59354 90.5 9.56788C90.5 12.5597 88.0655 14.9942 85.0912 14.9942H5.9088C2.93446 14.9917 0.5 12.5572 0.5 9.56538C0.5 6.59105 2.93446 4.15659 5.9088 4.15659V4.15909Z"
					fill={coverFill}
					stroke={coverFill}
				/>
				{/* Bottom Cover */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.9088 139.217H85.0912C88.0655 139.217 90.5 136.782 90.5 133.793C90.5 130.818 88.0655 128.384 85.0912 128.384H5.9088C2.93446 128.384 0.5 130.818 0.5 133.793C0.5 136.785 2.93446 139.217 5.9088 139.217Z"
					fill={coverFill}
					stroke={coverFill}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.6079 139.217H80.3923C81.5246 139.217 82.4719 140.146 82.4719 141.296C82.4719 142.428 81.5246 143.376 80.3923 143.376H10.6079C9.45811 143.376 8.52832 142.428 8.52832 141.296C8.52832 140.146 9.45811 139.217 10.6079 139.217Z"
					fill={coverFill}
					stroke={coverFill}
				/>
				{/* Glass */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M82.2693 14.9917C82.4393 16.3614 82.5242 17.7636 82.5242 19.1832C82.5242 44.3651 51.5936 52.7658 48.9417 67.8749C48.3343 71.2892 48.3343 72.2689 48.9417 75.7007C51.5961 90.8098 82.5242 99.2104 82.5242 124.392C82.5242 125.812 82.4393 127.214 82.2693 128.584H8.73063C8.56067 127.214 8.46069 125.812 8.46069 124.392C8.46069 99.2104 39.4063 90.8098 42.0607 75.7007C42.6681 72.2689 42.6681 71.2892 42.0607 67.8749C39.4063 52.7658 8.46069 44.3651 8.46069 19.1832C8.46069 17.7636 8.56067 16.3614 8.73063 14.9917H82.2668H82.2693Z"
					fill={glassFill}
				/>
				{/* Inner Sand */}
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M75.0183 32.4328C70.2519 45.0925 53.6056 52.0034 49.0066 60.7765C46.5896 64.7156 46.0498 68.3998 45.4074 71.3241C43.3104 59.9992 40.8259 57.5322 37.0743 54.3379C31.1431 49.3015 24.1946 44.4676 20.1555 37.8766C22.8099 35.6621 27.4239 34.6973 30.7682 34.6973C37.8666 34.6973 51.2011 40.646 54.0255 37.1817C61.1414 28.5112 67.665 30.7607 75.0158 32.4328H75.0183Z"
					fill="url(#gradientSandTop)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M76.8606 120.861C76.9456 122.06 76.7931 123.243 76.6407 124.41H14.3595C14.2071 123.243 14.1221 122.06 14.1221 120.861C14.1221 107.409 39.7264 112.832 45.4751 78.0826C49.0243 117.091 75.391 99.108 76.8606 120.861Z"
					fill="url(#gradientSandBottom)"
				/>
			</g>
			<defs>
				<linearGradient
					id="gradientSandBottom"
					x1="-49.9906"
					y1="101.246"
					x2="76.885"
					y2="101.246"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
				<linearGradient
					id="gradientSandTop"
					x1="-35.8871"
					y1="51.0967"
					x2="75.0183"
					y2="51.0967"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
				<clipPath id="clip0_227_8324">
					<rect width="90" height="143.373" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	)
}

const MemoisedHourglassGradientIcon = memo(HourglassGradientIcon)

export default MemoisedHourglassGradientIcon
