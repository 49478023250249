import LocationSlash from 'src/_shared/_old/assets/svgs/LocationSlash'

interface Props {
	header: string
	mainText: string
}
const NoLocation = ({ header, mainText }: Props) => {
	return (
		<div className="flex w-full flex-col items-center justify-center space-y-14 px-8 pt-[20vh]">
			<LocationSlash className="h-32 w-auto text-primary-800" />
			<div className="flex w-full flex-col items-center justify-center space-y-2">
				<p className="text-center text-sm font-semibold text-typography-primary">{header}</p>
				<p className="text-center text-sm text-typography-secondary">{mainText}</p>
			</div>
		</div>
	)
}

export default NoLocation
