import { useEffect } from 'react'

import { Language } from '../enums/env'
import { useLocalStorageItem } from './useStorageItem'

const LANGUAGE_KEY = 'lang'

const isValidLanguage = (value: string | null): value is Language => {
	return !!value && Object.values<string>(Language).includes(value)
}

/**
 * Returns the currently set `lang`.
 */
const useLanguage = (): Language => {
	const [language, setLanguage] = useLocalStorageItem(LANGUAGE_KEY)

	const languageQueryParameter = new URL(window.location.href).searchParams.get(LANGUAGE_KEY)

	/**
	 * If a valid `lang` query parameter is provided,
	 * replace the current value found in `localStorage`.
	 */
	useEffect((): void => {
		if (isValidLanguage(languageQueryParameter)) {
			setLanguage(languageQueryParameter)
		}
	}, [languageQueryParameter, setLanguage])

	if (isValidLanguage(languageQueryParameter)) {
		return languageQueryParameter
	} else if (isValidLanguage(language)) {
		return language
	}
	return Language.English
}

export default useLanguage
