import { memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface ProfileScreenLabelValueListItemProps {
	label: string
	layout: 'vertical' | 'horizontal'
	value: string
	containerClassName?: string
	labelClassName?: string
}

const ProfileScreenLabelValueListItem = ({
	label,
	layout,
	value,
	containerClassName,
	labelClassName
}: ProfileScreenLabelValueListItemProps) => {
	return (
		<div
			className={classNames(
				layout === 'horizontal' ? 'flex-row gap-5' : 'mb-5 flex-col',
				'flex justify-between',
				containerClassName
			)}
		>
			<label className={classNames('mb-1 text-sm font-normal text-grayscale-600', labelClassName)}>
				{label}
			</label>
			<span className="body-2-normal text-typography-primary">{value}</span>
		</div>
	)
}
const MemoisedProfileScreenLabelValueListItem = memo(ProfileScreenLabelValueListItem)

export default MemoisedProfileScreenLabelValueListItem
