import { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import CardIcon from 'src/_shared/components/_icons/CardIcon'
import { PaymentMethod } from 'src/_shared/types/payments'

import { getImageAsset } from './utils'

interface PaymentMethodsListProps {
	paymentMethods: PaymentMethod[]
	className?: string
	listItemLeftRender?: (paymentMethod: PaymentMethod, index: number) => ReactNode
	listItemRightRender?: (paymentMethod: PaymentMethod, index: number) => ReactNode
}

const PaymentMethodsList = ({
	paymentMethods,
	className,
	listItemLeftRender,
	listItemRightRender
}: PaymentMethodsListProps): JSX.Element => {
	return (
		<div className={className}>
			{paymentMethods.length > 0 ? (
				paymentMethods.map((paymentMethod, index): JSX.Element => {
					/**
					 * Default Render: Payment Method Icon with Partial Details
					 */
					const leftElement = ((): ReactNode => {
						if (listItemLeftRender) {
							return listItemLeftRender(paymentMethod, index)
						}
						const { cardType, lastFourDigits } = paymentMethod

						const imageAsset = getImageAsset(cardType)

						const iconElement = imageAsset ? (
							<img className="max-h-6 max-w-9" src={imageAsset} />
						) : (
							<CardIcon className="max-h-6 max-w-9" />
						)

						return (
							<div className="flex items-center space-x-3">
								<div className="w-9">{iconElement}</div>
								<span className="body-2-normal text-typography-primary">**** {lastFourDigits}</span>
							</div>
						)
					})()

					/**
					 * Default Render: Empty
					 */
					const rightElement = ((): ReactNode => {
						if (listItemRightRender) {
							return listItemRightRender(paymentMethod, index)
						}
						return null
					})()

					return (
						<div key={index}>
							{/* Payment Method Item */}
							<div className="flex items-center justify-between px-1 py-3">
								{leftElement}
								{rightElement}
							</div>
							{/* Divider */}
							{index < paymentMethods.length - 1 && (
								<div className="my-2 border-b border-primary-400/30" />
							)}
						</div>
					)
				})
			) : (
				<span className="body-1-normal text-typography-tertiary">
					<FormattedMessage
						id="PaymentMethodsList.FooterTextNoPaymentMethod"
						defaultMessage="There is no payment method to select. Please add a payment method to proceed for charging."
					/>
				</span>
			)}
		</div>
	)
}

export default PaymentMethodsList
