import { SVGProps, memo } from 'react'

const PlusIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M6 12H18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M12 18V6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	)
}

const MemoisedPlusIcon = memo(PlusIcon)

export default MemoisedPlusIcon
