import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

export type ContentViewProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'ref'
>

const ContentView = ({ children, className, ...props }: ContentViewProps): JSX.Element => {
	return (
		<div className={classNames('flex flex-grow flex-col', className)} {...props}>
			{children}
		</div>
	)
}

const MemoisedContentView = memo(ContentView)

export default MemoisedContentView
