import { ChangeEvent, FormEvent, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ScreenRoutePath, useQueryParams, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Input from 'src/_shared/components/Input'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import InformationCircleIcon from 'src/_shared/components/_icons/InformationCircleIcon'
import { useLocationsByQrIdentifierMutation } from 'src/_shared/mutations/locations'

import { CheckInManualScreenQueryParams } from './types'

const INPUT_ID = 'qrIdentifier'

const CheckInManualScreen = (): JSX.Element => {
	const [inputValue, setInputValue] = useState<string>('')

	const [queryParams] = useQueryParams<CheckInManualScreenQueryParams>()

	const intl = useIntl()

	const navigate = useRouterNavigate()

	const {
		data: locationsByQrIdentifierData,
		error: locationsByQrIdentifierError,
		isError: isLocationsByQrIdentifierError,
		isSuccess: isLocationsByQrIdentifierSuccess,
		isPending: isLocationsByQrIdentifierPending,
		mutateAsync: getLocationsByQrIdentifier,
		reset: resetLocationsByQrIdentifierMutation
	} = useLocationsByQrIdentifierMutation()

	const cpoEntityCodes = useMemo((): string[] => {
		if (queryParams.cpoEntityCodes) {
			return queryParams.cpoEntityCodes
				.split(',')
				.map((entityCode) => entityCode.trim())
				.filter(Boolean)
		}
		return []
	}, [queryParams.cpoEntityCodes])

	const errorMessage = useMemo((): string | null => {
		const { locations, message: dataErrorMessage } = locationsByQrIdentifierData?.data ?? {}
		// Handle 4XX/5XX status errors thrown with messages.
		if (isLocationsByQrIdentifierError) {
			return locationsByQrIdentifierError.response?.data.message
				? locationsByQrIdentifierError.response.data.message
				: intl.formatMessage({
						id: 'CheckInManualScreen.InputErrorOops',
						defaultMessage: 'Oops! Something went wrong'
					})
		}
		// Handle 200 status with error message.
		else if (dataErrorMessage) {
			return dataErrorMessage
		}
		// No locations found.
		else if (isLocationsByQrIdentifierSuccess && (!locations || locations.length === 0)) {
			return intl.formatMessage({
				id: 'CheckInManualScreen.InputErrorInvalidConnectorId',
				defaultMessage: 'The Connector ID is invalid. Please check again.'
			})
		}
		return null
	}, [
		intl,
		isLocationsByQrIdentifierError,
		isLocationsByQrIdentifierSuccess,
		locationsByQrIdentifierError,
		locationsByQrIdentifierData?.data
	])

	const handleInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>): void => {
			const value = event.target.value.trim()
			if (errorMessage) {
				resetLocationsByQrIdentifierMutation()
			}
			setInputValue(value)
		},
		[errorMessage, resetLocationsByQrIdentifierMutation]
	)

	const handleNextClick = useCallback(
		(event: FormEvent<HTMLFormElement>): void => {
			// Prevent default form submission behaviour.
			event.preventDefault()

			if (inputValue) {
				void getLocationsByQrIdentifier(
					{
						qrIdentifier: inputValue,
						entityCodes: cpoEntityCodes.length > 0 ? cpoEntityCodes : undefined
					},
					{
						onSuccess: (response): void => {
							if (response.data.locations.length) {
								const location = response.data.locations[0]
								const evse = location.evses?.[0]
								const connector = evse?.connectors?.[0]
								if (location.entity_code && location.uid && evse?.uid && connector?.uid) {
									navigate([
										ScreenRoutePath.Charger,
										location.entity_code,
										location.uid,
										evse.uid,
										connector.uid
									])
								}
							}
						}
					}
				)
			}
		},
		[cpoEntityCodes, inputValue, getLocationsByQrIdentifier, navigate]
	)

	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			<form className="flex flex-grow flex-col" onSubmit={handleNextClick}>
				<div className="flex-grow space-y-5">
					{/* Input */}
					<Input
						id={INPUT_ID}
						name={INPUT_ID}
						label={intl.formatMessage({
							id: 'CheckInManualScreen.InputLabelConnectorId',
							defaultMessage: 'Connector ID'
						})}
						placeholder={intl.formatMessage({
							id: 'CheckInManualScreen.InputPlaceholderEnterConnectorId',
							defaultMessage: 'Please enter the Connector ID'
						})}
						description={errorMessage}
						value={inputValue}
						onChange={handleInputChange}
						error={!!errorMessage}
						disabled={isLocationsByQrIdentifierPending}
						showRequiredMark
					/>
					{/* Info-Notice */}
					<div className="flex flex-row space-x-3 rounded-lg bg-info-100 px-4 py-3">
						<div>
							<InformationCircleIcon className="h-5 w-5 text-info-300" />
						</div>
						<div>
							<p className="body-1-normal text-typography-primary">
								<FormattedMessage
									id="CheckInManualScreen.InformationDescriptionInsertConnector"
									defaultMessage="Please insert the charging connector into your EV."
								/>
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<Button
						variant="primary"
						disabled={!inputValue || isLocationsByQrIdentifierPending}
						loading={isLocationsByQrIdentifierPending}
					>
						<FormattedMessage id="CheckInManualScreen.ButtonTextNext" defaultMessage="Next" />
					</Button>
				</div>
			</form>
		</ScreenContainer>
	)
}

export default CheckInManualScreen
