import { m as motion } from 'framer-motion'
import { useState, useEffect, ReactNode, TouchEventHandler, MouseEvent } from 'react'

import useHeight from '../../hooks/useHeight'

enum PopupContainerVisibilityStatus {
	Visible,
	HiddenBySlideClose, // User manually slides drawer down.
	HiddenByTouchClose // User touches the black area.
}

interface PopupContainerProps {
	stopTouchProp?: boolean
	className?: string
	onClose?: () => void
	children?: ReactNode
}

const PopupContainer = (props: PopupContainerProps) => {
	const { stopTouchProp, className, onClose, children } = props

	const { ref: contentRef } = useHeight()

	const [popupOpacity, setPopupOpacity] = useState(1)

	const [touchStart, setTouchStart] = useState(0)

	const [touchEnd, setTouchEnd] = useState(0)

	const [touchOffset, setTouchOffset] = useState(0)

	const [prevOffset, setPrevOffset] = useState(0)

	const [minusOffset, setMinusOffset] = useState(0)

	const [popupContainerVisibilityStatus, setPopupContainerVisibilityStatus] =
		useState<PopupContainerVisibilityStatus>(PopupContainerVisibilityStatus.Visible)

	const bounceBack =
		(minusOffset + touchOffset > -100 && minusOffset + touchOffset < 0) ||
		minusOffset + touchOffset > 0

	const hide = minusOffset + touchOffset < -100

	const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
		if (!stopTouchProp) {
			setTouchEnd(0)
			setTouchOffset(0)
			setMinusOffset(prevOffset)
			const y = e.targetTouches[0].clientY
			setTouchStart(y)
		}
	}

	const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
		if (!stopTouchProp) {
			const y = e.targetTouches[0].clientY
			const touchY = touchStart - y
			if (touchY < 0) {
				setTouchOffset(touchY)
			} else {
				setTouchOffset(4 * Math.log(touchY))
			}
		}
	}

	const onTouchEnd = () => {
		if (!stopTouchProp) {
			setTouchEnd(1)
			if (bounceBack) {
				setMinusOffset(0)
				setTouchOffset(0)
				setPrevOffset(0)
			} else if (hide) {
				setPopupContainerVisibilityStatus(PopupContainerVisibilityStatus.HiddenBySlideClose)
			} else {
				setPrevOffset((prev) => prev + touchOffset)
			}
		}
	}

	const handleParentClick = () => {
		if (popupContainerVisibilityStatus === PopupContainerVisibilityStatus.Visible) {
			setPopupContainerVisibilityStatus(PopupContainerVisibilityStatus.HiddenByTouchClose)
		}
	}

	const handleBottomContentClick = (event: MouseEvent<HTMLDivElement>) => {
		// Prevent click `event` from propagating to the parent `motion.div`
		// so that clicking within the popup content does not cause it to popupContainerVisibilityStatus.
		event.stopPropagation()
	}

	// Set `body` `overflow` to `hidden` when the container is open
	// since the style sheet no longer has it to facilitate the new `ScreenContainer`.
	// FUTURE TODO: Rewrite Popup Container.
	useEffect(() => {
		document.body.style.setProperty(
			'overflow',
			popupContainerVisibilityStatus === PopupContainerVisibilityStatus.Visible ? 'hidden' : null
		)
		return () => {
			document.body.style.setProperty('overflow', null)
		}
	}, [popupContainerVisibilityStatus])

	useEffect(() => {
		if (popupContainerVisibilityStatus !== PopupContainerVisibilityStatus.Visible) {
			setPopupOpacity(0)
			setTimeout(() => {
				onClose?.()
			}, 200)
		}
	}, [popupContainerVisibilityStatus, onClose])

	return (
		<motion.div
			className="absolute left-0 z-[999] flex h-dvh w-full flex-col justify-end overflow-hidden bg-black bg-opacity-50"
			animate={{ opacity: popupOpacity }}
			onClick={handleParentClick}
			style={{
				top: window.scrollY
			}}
		>
			{/* bottom content component */}
			<motion.div
				ref={contentRef}
				className={`flex max-h-screen w-full flex-col items-start justify-center overflow-hidden rounded-t-3xl bg-white px-6 py-3 ${className}`}
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
				onTouchEnd={onTouchEnd}
				animate={{
					y: (() => {
						// Determine how to animate the popup container when closing.
						switch (popupContainerVisibilityStatus) {
							case PopupContainerVisibilityStatus.HiddenBySlideClose:
								return window.innerHeight
							case PopupContainerVisibilityStatus.HiddenByTouchClose:
								return 0
							case PopupContainerVisibilityStatus.Visible:
								return -(minusOffset + touchOffset)
						}
					})()
				}}
				transition={{
					ease: 'easeInOut',
					duration: 0.3 * touchEnd,
					damping: 20,
					stiffness: 100
				}}
				onClick={handleBottomContentClick}
			>
				{children}
			</motion.div>
		</motion.div>
	)
}

export default PopupContainer
