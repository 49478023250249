import Loader from 'src/_shared/_old/assets/images/loader.png'
import Check from 'src/_shared/_old/assets/svgs/Check'
import Danger from 'src/_shared/_old/assets/svgs/Danger'
import Forbidden from 'src/_shared/_old/assets/svgs/Forbidden'
import { IconTypes } from 'src/_shared/_old/enums'

interface Props {
	icon?: IconTypes | null
}

const baseClass = 'w-auto h-20'

const IconRenderer = ({ icon }: Props) => {
	switch (icon) {
		case IconTypes.LOADING:
			return <img src={Loader} alt="loader" className="animate-spin" />
		case IconTypes.ERROR:
			return <Forbidden className={`text-error-300 ${baseClass}`} />
		case IconTypes.WARNING:
			return <Danger className={`text-yellow-700 ${baseClass}`} />
		case IconTypes.SUCCESS:
			return <Check className={`text-typography-primary ${baseClass}`} />
		default:
			return null
	}
}

export default IconRenderer
