import { useEffect, useState } from 'react'

export default function useCurrentCoords() {
	const [currentCoords, setCurrentCoords] = useState<{ lat: number; lng: number }>({
		lat: 0,
		lng: 0
	})
	const [isCurrentCoordsAvailable, setIsCurrentCoordsAvailable] = useState(false)
	const [error, setError] = useState<string | null>(null)

	useEffect(() => {
		const onSuccess = (position: GeolocationPosition) => {
			const { latitude, longitude } = position.coords
			setCurrentCoords({
				lat: latitude,
				lng: longitude
			})
			setIsCurrentCoordsAvailable(true)
			setError(null)
		}

		const onError: PositionErrorCallback = (error) => {
			setError(error.message)
		}

		const geoId = navigator.geolocation.watchPosition(onSuccess, onError, {
			timeout: 5000,
			maximumAge: 0
		})

		return () => {
			navigator.geolocation.clearWatch(geoId)
		}
	}, [])

	return { currentCoords, error, isCurrentCoordsAvailable }
}
