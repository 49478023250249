import { useEffect, useState, useMemo, ChangeEventHandler } from 'react'
import LocationPin from 'src/_shared/_old/assets/svgs/LocationPin'
import Compass from 'src/_shared/_old/assets/svgs/compass.svg'
import PageContainer from 'src/_shared/_old/components/containers/PageContainer'
import MatchedLocation from 'src/_shared/_old/components/list/MatchedLocation'
import NoLocation from 'src/_shared/_old/components/list/NoLocation'
import Modal from 'src/_shared/_old/components/modals/Modal'
import Topbar from 'src/_shared/_old/components/nav/Topbar'
import { TOPBAR_HEIGHT } from 'src/_shared/_old/enums'
import { useDebounce } from 'src/_shared/_old/hooks/useDebounce'
import { Coordinates } from 'src/_shared/_old/schemas/geolocation'
import { Place, search } from 'src/_shared/_old/utils/map'
import { distanceBetween } from 'src/_shared/_old/utils/math'

import { LAST_LOCATION_DROPPED_PIN_NAME } from './MapScreen'

// currentCoords: it would be the coordinates that would be passed from listview
// currentCoords: if passed from map view, the coordinates would be the actual currentCoordinates
interface SearchChargerScreenProps {
	actualCoords?: Coordinates
	currentCoords: Coordinates
	map: google.maps.Map | null
	maps: typeof google.maps | null
	isCurrentCoordsAvailable: boolean
	onReturn?: () => void
	onChooseMatchedLocation?: (selectedCoords?: Coordinates, name?: string) => void
	backFunction?: () => void
	onClickLoc?: (coords?: Coordinates, name?: string) => void
}

const SearchChargerScreen = (props: SearchChargerScreenProps) => {
	const [searchLocation, setSearchLocation] = useState<string>('')
	const [showLocationModal, setShowLocationModal] = useState(false)
	const [matchedLocations, setMatchedLocations] = useState<Place[]>([])
	const searchNearbyChargersHandler = (chosenCoords?: Coordinates, name?: string) => {
		// behaviour 1: bring user back to the list view screen
		if (props.onChooseMatchedLocation && props.onReturn) {
			if (name) {
				props.onChooseMatchedLocation(chosenCoords, name)
				// if POI is chosen, we would save it as searchLocationName
				const encodedCoords = `${encodeURIComponent(
					chosenCoords?.lat ?? ''
				)}-${encodeURIComponent(chosenCoords?.lng ?? '')}`
				window.history.pushState(
					{ lat: chosenCoords?.lat, lng: chosenCoords?.lng },
					'',
					`/search/${encodedCoords}`
				)
				localStorage.setItem(LAST_LOCATION_DROPPED_PIN_NAME, name)
			} else {
				// when search nearby chargers are being clicked
				// this would inevitably clear the location pin and go back to current location state
				localStorage.removeItem(LAST_LOCATION_DROPPED_PIN_NAME)
				window.history.pushState({}, '', '/map')
				props.onChooseMatchedLocation(chosenCoords)
			}
			props.onReturn()
		}

		// behaviour 2: bring user back to map screen
		// if user has clicked on a given poi
		if (props.onClickLoc && name) {
			props.onClickLoc(chosenCoords, name)
			return
		}

		// if user has current location
		if (props.onClickLoc && props.actualCoords) {
			localStorage.removeItem(LAST_LOCATION_DROPPED_PIN_NAME)
			window.history.pushState({}, '', '/map')
			props.onClickLoc(props.actualCoords)
			return
		}
	}

	const inputHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
		setSearchLocation(event.target.value)
	}

	const debouncedSearchLocation = useDebounce(searchLocation, 500)

	useEffect(() => {
		const retrieveSearchResults = async (searchLocation: string) => {
			if (props.map && props.maps) {
				const searchLocationResults = await search(props.map, props.maps, searchLocation)
				setMatchedLocations(searchLocationResults)
			}
		}

		if (debouncedSearchLocation.length > 0) {
			void retrieveSearchResults(debouncedSearchLocation)
		}
	}, [debouncedSearchLocation, props.map, props.maps])

	const sortedMatchedLocations = useMemo(() => {
		const sorted = [...matchedLocations]
		sorted.sort((a, b) => {
			const ACoordinates: Coordinates = {
				lat: a.latitude,
				lng: a.longitude
			}
			const BCoordinates: Coordinates = {
				lat: b.latitude,
				lng: b.longitude
			}

			return (
				distanceBetween(ACoordinates, props.currentCoords) -
				distanceBetween(BCoordinates, props.currentCoords)
			)
		})

		return sorted
	}, [matchedLocations, props.currentCoords])

	return (
		<>
			<PageContainer scroll={false} className="relative">
				<Topbar
					title="Charging Locations"
					showBackIcon
					backFunction={props.backFunction ? props.backFunction : () => props.onReturn?.()}
				/>
				{showLocationModal && (
					<Modal
						text={'Please enable location access on your browser and refresh this page afterwards.'}
						buttonType="CLOSE"
						buttonTitle="Close"
						showModal={showLocationModal}
						onCloseModal={() => {
							setShowLocationModal(false)
						}}
					/>
				)}
				<div
					className={`mb-2 w-full flex-col items-center justify-center space-y-2 px-2`}
					style={{ marginTop: TOPBAR_HEIGHT }}
				>
					{/* Search Bar */}
					<div
						className={`border-1.5 z-20 flex w-full items-center justify-center space-x-3 rounded-xl border border-primary-400/30 px-3 py-3`}
					>
						<LocationPin className="h-5 w-auto text-typography-tertiary" />
						<input
							placeholder="Find Your Chargers Here"
							className="w-full bg-white text-left text-typography-tertiary outline-none placeholder:font-light focus:bg-white focus:outline-none"
							autoFocus={true}
							onChange={inputHandler}
						></input>
					</div>

					{/* Search New Charger Button */}
					<button
						className="flex w-full items-center justify-start space-x-2 bg-[#F3FCFB] px-4 py-2"
						onClick={
							props.actualCoords?.lat && props.actualCoords.lng
								? () => {
										searchNearbyChargersHandler(props.actualCoords)
									}
								: () => {
										setShowLocationModal(true)
									}
						}
					>
						<img src={Compass} className="h-6 w-6 rounded-full bg-primary-800 p-0.5" />
						<span className="body-1-normal text-typography-primary underline underline-offset-2">
							Search nearby stations
						</span>
					</button>

					{/* Rendering New Chargers */}
					{sortedMatchedLocations.length > 0 ? (
						sortedMatchedLocations.map((loc, index) => (
							<button
								key={index}
								className="flex w-full flex-row items-center justify-center px-5 hover:bg-grayscale-300 focus:bg-grayscale-300"
								onClick={() => {
									searchNearbyChargersHandler(
										{
											lat: loc.latitude,
											lng: loc.longitude
										},
										loc.description
									)
								}}
							>
								<MatchedLocation
									place={loc}
									showBottomBorder={index !== sortedMatchedLocations.length - 1}
									currentCoords={props.currentCoords}
									isCurrentCoordsAvailable={props.isCurrentCoordsAvailable}
								/>
							</button>
						))
					) : (
						<NoLocation
							header="We didn't recognize that location"
							mainText="Please enter a valid location address"
						/>
					)}
				</div>
			</PageContainer>
		</>
	)
}

export default SearchChargerScreen
