import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import plugitLogo from './plugitLogo.png'

const PlugitLogoImage = ({
	alt = 'Plugit',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={plugitLogo} {...props} />
}

const MemoisedPlugitLogoImage = memo(PlugitLogoImage)

export default MemoisedPlugitLogoImage
