import { SVGProps, memo } from 'react'

const ShieldSecurityIcon = ({
	fill = 'currentColor',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path d="M12 22.76C10.91 22.76 9.83002 22.44 8.98002 21.81L4.68002 18.6C3.54002 17.75 2.65002 15.98 2.65002 14.56V7.12C2.65002 5.58 3.78002 3.94 5.23002 3.4L10.22 1.53C11.21 1.16 12.77 1.16 13.76 1.53L18.76 3.4C20.21 3.94 21.34 5.58 21.34 7.12V14.55C21.34 15.97 20.45 17.74 19.31 18.59L15.01 21.8C14.17 22.44 13.09 22.76 12 22.76ZM10.75 2.94L5.76002 4.81C4.90002 5.13 4.15002 6.21 4.15002 7.13V14.56C4.15002 15.51 4.82002 16.84 5.57002 17.4L9.87002 20.61C11.02 21.47 12.97 21.47 14.12 20.61L18.42 17.4C19.18 16.83 19.84 15.5 19.84 14.56V7.12C19.84 6.21 19.09 5.13 18.23 4.8L13.24 2.93C12.58 2.69 11.42 2.69 10.75 2.94Z" />
			<path d="M12 13.25C10.48 13.25 9.25 12.02 9.25 10.5C9.25 8.98 10.48 7.75 12 7.75C13.52 7.75 14.75 8.98 14.75 10.5C14.75 12.02 13.52 13.25 12 13.25ZM12 9.25C11.31 9.25 10.75 9.81 10.75 10.5C10.75 11.19 11.31 11.75 12 11.75C12.69 11.75 13.25 11.19 13.25 10.5C13.25 9.81 12.69 9.25 12 9.25Z" />
			<path d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V12.5C11.25 12.09 11.59 11.75 12 11.75C12.41 11.75 12.75 12.09 12.75 12.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z" />
		</svg>
	)
}

const MemoisedShieldSecurityIcon = memo(ShieldSecurityIcon)

export default MemoisedShieldSecurityIcon
