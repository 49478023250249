import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import useCheckInCpos from 'src/_shared/hooks/useCheckInCpos'
import { classNames } from 'src/_shared/utils/elements'

import { CpoData } from './types'

const CheckInScreen = (): JSX.Element => {
	const [selectedCpo, setSelectedCpo] = useState<CpoData | null>(null)

	const navigate = useRouterNavigate()

	const { cpos, isCpoDataLoading } = useCheckInCpos()

	const handleSelectCpo = (cpo: CpoData) => (): void => {
		setSelectedCpo(cpo)
	}

	const handleScanQrCodeClick = (): void => {
		if (selectedCpo?.entityCode) {
			navigate({
				pathname: ScreenRoutePath.CheckInQrScanner,
				search: `cpoEntityCodes=${selectedCpo.entityCode}`
			})
		}
	}

	const handleEnterConnectorIdClick = (): void => {
		if (selectedCpo?.entityCode) {
			navigate({
				pathname: ScreenRoutePath.CheckInManual,
				search: `cpoEntityCodes=${selectedCpo.entityCode}`
			})
		}
	}

	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			{isCpoDataLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<>
					{/* Grid List of CPOs */}
					<div className="flex-grow">
						<h1 className="mb-2 text-typography-primary">
							<FormattedMessage
								id="CheckInScreen.HeaderSelectCpo"
								defaultMessage="Select Charge Point Operator"
							/>
						</h1>
						<p className="body-2-normal mb-5 text-typography-primary">
							<FormattedMessage
								id="CheckInScreen.DescriptionSelectCpoScanQrCode"
								defaultMessage="Please select the preferred charge point operator to scan the QR Code."
							/>
						</p>
						<div className="grid grid-cols-3 gap-x-2 gap-y-2">
							{cpos.map((cpo, index): JSX.Element => {
								const { name, entityCode, imageUrl } = cpo
								const isSelected = selectedCpo?.entityCode === entityCode
								return (
									<div
										key={index}
										className={classNames(
											'flex h-24 cursor-pointer flex-col items-center justify-center gap-y-2 rounded-2xl border-2 p-3',
											isSelected ? 'border-success-400' : 'border-primary-400/30'
										)}
										onClick={handleSelectCpo(cpo)}
									>
										<div className="flex h-7 flex-col justify-center">
											<img className="max-h-7 max-w-20" src={imageUrl} />
										</div>
										<div className="w-full">
											<p className="body-1-semibold truncate text-center text-typography-primary">
												{name}
											</p>
										</div>
									</div>
								)
							})}
						</div>
					</div>
					{/* Buttons */}
					<div className="flex flex-col space-y-4">
						<Button variant="primary" disabled={!selectedCpo} onClick={handleScanQrCodeClick}>
							<FormattedMessage
								id="CheckInScreen.ButtonTextScanQrCode"
								defaultMessage="Scan QR Code"
							/>
						</Button>
						<Button variant="ghost" disabled={!selectedCpo} onClick={handleEnterConnectorIdClick}>
							<FormattedMessage
								id="CheckInScreen.ButtonTextEnterConnectorId"
								defaultMessage="Enter Connector ID"
							/>
						</Button>
					</div>
				</>
			)}
		</ScreenContainer>
	)
}

export default CheckInScreen
