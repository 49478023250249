import { m as motion } from 'framer-motion'
import { ReactNode, useEffect } from 'react'
import { useTouchInteraction } from 'src/_shared/_old/hooks/useTouchInteraction'

interface P {
	stopTouchProp: boolean
	onClose: () => void
	children: ReactNode
}

export default function MapBottomContainer({ onClose, children, stopTouchProp }: P) {
	const { onTouchStart, onTouchMove, onTouchEnd, touchOffset, minusOffset, touchEnd } =
		useTouchInteraction(onClose)

	useEffect(() => {
		const handleTouchEnd = () => {
			if (!stopTouchProp) onTouchEnd()
		}

		window.addEventListener('touchend', handleTouchEnd)

		return () => {
			window.removeEventListener('touchend', handleTouchEnd)
		}
	}, [onTouchEnd, stopTouchProp])

	return (
		<>
			{!(touchOffset < 0) && (
				<div
					className="absolute bottom-0 w-full bg-white"
					style={{
						height: 50
					}}
				/>
			)}
			<motion.div
				className="pointer-events-none absolute bottom-0 z-10 flex w-full flex-col items-start justify-center overflow-hidden"
				onTouchStart={(event) => {
					if (!stopTouchProp) {
						onTouchStart(event)
					}
				}}
				onTouchMove={(event) => {
					if (!stopTouchProp) {
						onTouchMove(event)
					}
				}}
				onTouchEnd={() => {
					if (!stopTouchProp) {
						onTouchEnd()
					}
				}}
				animate={{
					y: -(minusOffset + touchOffset)
				}}
				transition={{
					// ease: [0.47, 1.64, 0.41, 0.8],
					ease: 'easeInOut',
					duration: 0.3 * touchEnd,
					damping: 20,
					stiffness: 100
				}}
			>
				{children}
			</motion.div>
		</>
	)
}
