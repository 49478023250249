import { TouchEventHandler, useState } from 'react'

// custom hook here to allow gesture controls for mobile devices
export const useTouchInteraction = (handleClose?: () => void) => {
	const [touchStart, setTouchStart] = useState(0)
	const [touchEnd, setTouchEnd] = useState(0)
	const [touchOffset, setTouchOffset] = useState(0)
	const [prevOffset, setPrevOffset] = useState(0)
	const [minusOffset, setMinusOffset] = useState(0)

	const hide = minusOffset + touchOffset < -100
	const bounceBack =
		(minusOffset + touchOffset > -100 && minusOffset + touchOffset < 0) ||
		minusOffset + touchOffset > 0

	// function triggered whenever the user starts interaction with dom element
	const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
		// reset all offset values to it's previous state
		setTouchEnd(0)
		setTouchOffset(0)
		setMinusOffset(prevOffset)
		const y = e.targetTouches[0].clientY
		setTouchStart(y)
	}

	// function triggered whenever the user holds onto the dom element
	const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
		// calculates the offset by taking the current y with the incoming event's y value
		const y = e.targetTouches[0].clientY
		const touchY = touchStart - y
		if (touchY < 0) {
			setTouchOffset(touchY)
		} else {
			setTouchOffset(4 * Math.log(touchY))
		}
	}

	// function triggered whenever the user ends interaction with dom element
	const onTouchEnd = () => {
		setTouchEnd(1)
		if (bounceBack) {
			/* top to bottom: when offset induced by the user is too much 
      (e.g: user holds the header div of the popup and drags it all the 
      way down near the bottom of the viewport height)
      */
			setTouchOffset(0)
		} else if (hide) {
			// top to bottom: when offset induced by the user is enough to hide the popup
			setMinusOffset(0)
			setTouchOffset(0)
			setPrevOffset(0)
			handleClose?.()
		} else {
			setPrevOffset((prev) => prev + touchOffset)
		}
	}

	return {
		onTouchStart,
		onTouchMove,
		onTouchEnd,
		touchOffset,
		minusOffset,
		prevOffset,
		touchEnd
	}
}
