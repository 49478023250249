import { SVGProps, memo } from 'react'

const ChevronRightIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	strokeWidth = '2',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
		</svg>
	)
}

const MemoisedChevronRightIcon = memo(ChevronRightIcon)

export default MemoisedChevronRightIcon
