import { CSSProperties, PropsWithChildren, memo, useEffect } from 'react'
import { classNames } from 'src/_shared/utils/elements'

type ModalProps = PropsWithChildren<{
	className?: string
	open?: boolean
	style?: CSSProperties
	onClose?: () => void
}>

const Modal = ({ children, className, open, style, onClose }: ModalProps): JSX.Element | null => {
	const handleParentClick = (): void => {
		onClose?.()
	}

	/**
	 * Disable scrolling in `body` when the `Modal` is visible.
	 */
	useEffect((): (() => void) => {
		document.body.style.setProperty('overflow', open ? 'hidden' : null)
		return () => {
			document.body.style.setProperty('overflow', null)
		}
	}, [open])

	if (!open) {
		return null
	}
	return (
		<div
			className={classNames('modal', className)}
			style={{
				...style,
				top: style?.top ?? window.scrollY
			}}
			onClick={handleParentClick}
		>
			{children}
		</div>
	)
}

const MemoisedModal = memo(Modal)

export default MemoisedModal
