import { DetailedHTMLProps, HTMLAttributes, MouseEvent, memo } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { classNames } from 'src/_shared/utils/elements'

import Spinner from '../Spinner'

export type ButtonProps = DetailedHTMLProps<
	HTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	disabled?: boolean
	/**
	 * Wraps the `button` element with the `react-router-dom` `Link` component.
	 */
	linkProps?: Omit<LinkProps, 'children'>
	loading?: boolean
	/**
	 * `Button` text is wrapped inside of a `span` element.
	 */
	textProps?: Omit<
		DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
		'children' | 'ref'
	>
	variant?: 'dashed' | 'error' | 'ghost' | 'primary' | 'secondary'
}

const Button = ({
	children,
	className,
	disabled,
	linkProps: buttonLinkProps,
	loading,
	textProps: buttonTextProps,
	variant = 'primary',
	...buttonProps
}: ButtonProps): JSX.Element => {
	const buttonElement = (
		<button
			{...buttonProps}
			onClick={!disabled ? buttonProps.onClick : undefined}
			className={classNames(
				// Base Classes
				'btn body-2-semibold',
				// `disabled` Class
				disabled ? 'disabled' : null,
				// `variant` Class
				variant,
				className
			)}
		>
			<span
				{...buttonTextProps}
				className={classNames(
					// Base Classes
					'flex flex-row items-center',
					((): string => {
						if (!disabled) {
							// `variant` Classes
							switch (variant) {
								case 'dashed':
									return 'text-typography-primary'
								case 'error':
									return 'text-error-300'
								case 'secondary':
									return 'text-secondary-400'
								case 'ghost':
									return 'text-typography-primary'
								case 'primary':
									return 'text-button-primary-content'
							}
						}
						// `disabled` Classes
						return 'text-typography-primary/40'
					})(),
					buttonTextProps?.className
				)}
			>
				{children}
			</span>
			<Spinner
				className={classNames(
					// Base Classes
					'h-4 w-4 duration-150 ease-in-out',
					// Loading
					loading ? 'ml-2' : null
				)}
				style={
					// Override the default `h-12 w-12` in `Spinner`
					!loading
						? {
								height: 0,
								width: 0
							}
						: undefined
				}
			/>
		</button>
	)

	if (buttonLinkProps && !disabled) {
		const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>): void => {
			// If the button has an `onClick` handler, stop the anchor link redirection.
			if (buttonLinkProps.onClick) {
				event.preventDefault()
				buttonLinkProps.onClick(event)
			}
		}
		return (
			<Link
				{...buttonLinkProps}
				className={classNames('no-underline', buttonLinkProps.className)}
				onClick={handleLinkClick}
			>
				{buttonElement}
			</Link>
		)
	}
	return buttonElement
}

const MemoisedButton = memo(Button)

export default MemoisedButton
