import { SVGProps, memo } from 'react'

const ArrowLeftIcon = ({
	fill = 'currentColor',
	stroke = 'currentColor',
	strokeWidth = '0',
	viewBox = '0 0 20 20',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill={fill}
			stroke={stroke}
			strokeWidth={strokeWidth}
			viewBox={viewBox}
			xmlns={xmlns}
			{...props}
		>
			<path
				fillRule="evenodd"
				d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

const MemoisedArrowLeftIcon = memo(ArrowLeftIcon)

export default MemoisedArrowLeftIcon
