import { DetailedHTMLProps, HTMLAttributes, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

type SpinnerProps = Omit<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
	'children'
>

const Spinner = ({ className, ...props }: SpinnerProps): JSX.Element => {
	return (
		<div {...props} className={classNames('h-12 w-12 animate-spin text-primary-400', className)}>
			<svg fill="none" viewBox="-12 -12 224 224" xmlns="http://www.w3.org/2000/svg">
				<g strokeWidth="32">
					<path stroke="url(#spinnerFirstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
					<path stroke="url(#spinnerSecondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
					<path stroke="currentColor" d="M 4 100 A 96 96 0 0 1 4 98" />
				</g>
				<defs>
					<linearGradient id="spinnerFirstHalf">
						<stop offset="0%" stopOpacity="1" stopColor="currentColor" />
						<stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
					</linearGradient>
					<linearGradient id="spinnerSecondHalf">
						<stop offset="0%" stopOpacity="0" stopColor="currentColor" />
						<stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	)
}

const MemoisedSpinner = memo(Spinner)

export default MemoisedSpinner
