import { SVGProps, memo } from 'react'

const VoltalityLogoIcon = ({
	viewBox = '250 240 340 120',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox={viewBox} xmlns={xmlns} {...props}>
			{/* Voltality */}
			<g>
				{/* V */}
				<path
					fill="#006B8C"
					d="M266.1,281.4l-3.6,12.6c-0.2,0.7,0.4,1.3,1.1,1.1l6.5-1.8l-3.4,15.7c-0.1,0.5,0.6,0.7,0.8,0.3l11.1-22.6 c0.3-0.7-0.3-1.5-1-1.3l-5.9,1.6l1.6-5.6l10.3,0c2,0,3.2,2.1,2.4,3.8l-13.7,30.3c-1,1.9-3.7,1.9-4.7,0l-13.7-30.3 c-0.9-1.7,0.4-3.8,2.4-3.8C256.3,281.4,266.1,281.4,266.1,281.4z"
				/>
				{/* O */}
				<path
					fill="#006B8C"
					d="M367.4,313.1v1.1c0,1.5-1.2,2.6-2.6,2.6h-15.4c-1.8,0-3.5-0.4-5-1.2c-2.3-1.1-4.1-2.9-5.2-5.2 c-0.8-1.5-1.2-3.2-1.2-5v-21.6c0-1.5,1.2-2.6,2.6-2.6h1.1c1.5,0,2.6,1.2,2.6,2.6v22.2c0.3,2.3,2.1,4.1,4.4,4.4h16 C366.2,310.5,367.4,311.7,367.4,313.1z"
				/>
				{/* L */}
				<path
					fill="#006B8C"
					d="M314,281.3h-1h-6.5h-1c-9.8,0-17.8,8-17.8,17.8c0,9.8,8,17.8,17.8,17.8h1h6.5h1c9.8,0,17.8-8,17.8-17.8 C331.8,289.2,323.9,281.3,314,281.3z M314,310.5h-7.5v0h-1c-6.3,0-11.5-5.1-11.5-11.5c0-6.3,5.1-11.5,11.5-11.5h7.5v0h1 c6.3,0,11.5,5.1,11.5,11.5C325.5,305.4,320.4,310.5,314,310.5z"
				/>
				{/* T */}
				<path
					fill="#006B8C"
					d="M398.3,283.9v1.1c0,1.5-1.2,2.6-2.6,2.6h-12v26.6c0,1.5-1.2,2.6-2.6,2.6h-1.1c-1.5,0-2.6-1.2-2.6-2.6v-26.6 h-12c-1.5,0-2.6-1.2-2.6-2.6v-1.1c0-1.5,1.2-2.6,2.6-2.6h30.3C397.1,281.3,398.3,282.5,398.3,283.9z"
				/>
				{/* A */}
				<linearGradient
					id="SVGID_7_"
					gradientUnits="userSpaceOnUse"
					x1="257.3876"
					y1="398.6533"
					x2="507.9406"
					y2="242.6616"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_7_)' }}
					d="M410.3,292.2l10.4,23.3c0.4,0.9,1.4,1.4,2.3,1.4l1.3,0c2,0,3.3-2.1,2.4-3.8l-14-30.4c-1-1.9-3.7-1.9-4.7,0 l-14,30.4c-0.9,1.8,0.4,3.8,2.4,3.8h1.3c1,0,1.9-0.6,2.4-1.4L410.3,292.2z"
				/>
				{/* L */}
				<linearGradient
					id="SVGID_2_"
					gradientUnits="userSpaceOnUse"
					x1="265.6302"
					y1="411.8925"
					x2="516.1832"
					y2="255.9008"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_2_)' }}
					d="M461.5,313.1v1.1c0,1.5-1.2,2.6-2.6,2.6h-15.4c-1.8,0-3.5-0.4-5-1.2c-2.3-1.1-4.1-2.9-5.2-5.2 c-0.8-1.5-1.2-3.2-1.2-5v-21.6c0-1.5,1.2-2.6,2.6-2.6h1.1c1.5,0,2.6,1.2,2.6,2.6v22.2c0.3,2.3,2.1,4.1,4.4,4.4h16 C460.4,310.5,461.5,311.7,461.5,313.1z"
				/>
				{/* I */}
				<linearGradient
					id="SVGID_1_"
					gradientUnits="userSpaceOnUse"
					x1="272.541"
					y1="422.4284"
					x2="523.0923"
					y2="266.4378"
					gradientTransform="matrix(1 -8.915303e-03 8.915303e-03 1 -2.5475 4.2144)"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_1_)' }}
					d="M467.6,314.2l0-30.3c0-1.5,1.2-2.6,2.6-2.6l1.1,0c1.5,0,2.6,1.2,2.6,2.6l0,30.3c0,1.5-1.2,2.6-2.6,2.6l-1.1,0 C468.8,316.9,467.6,315.7,467.6,314.2z"
				/>
				{/* T */}
				<linearGradient
					id="SVGID_6_"
					gradientUnits="userSpaceOnUse"
					x1="278.404"
					y1="431.0939"
					x2="529.0398"
					y2="275.0507"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_6_)' }}
					d="M500.7,314.3l0.2-26.5l12,0c1.5,0,2.6-1.2,2.7-2.6l0-1.1c0-1.5-1.2-2.6-2.6-2.7l-30.2,0 c-1.5,0-2.6,1.2-2.7,2.6l0,1.1c0,1.5,1.2,2.6,2.6,2.7l12,0l-0.2,26.5c0,1.5,1.2,2.6,2.6,2.7l1.1,0 C499.5,316.9,500.7,315.8,500.7,314.3z"
				/>
				{/* Y */}
				<linearGradient
					id="SVGID_8_"
					gradientUnits="userSpaceOnUse"
					x1="291.3056"
					y1="448.1678"
					x2="541.209"
					y2="292.5806"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_8_)' }}
					d="M536.7,294l9.1-11.5c0.5-0.6,1.3-1,2.1-1l1.3,0c2.2,0,3.4,2.5,2.1,4.3l-11.4,14.5l-0.1,14.1 c0,1.5-1.2,2.6-2.7,2.6l-1.1,0c-1.5,0-2.6-1.2-2.6-2.7l0.1-14l-0.8-1.1l0,0l-10.4-13.6c-1.3-1.7-0.1-4.2,2.1-4.2l1.3,0 c0.8,0,1.6,0.4,2.1,1L536.7,294l9.1-11.5c0.5-0.6,1.3-1,2.1-1"
				/>
			</g>
			{/* Rings */}
			<g>
				{/* Inner Ring */}
				<linearGradient
					id="SVGID_3_"
					gradientUnits="userSpaceOnUse"
					x1="391.7391"
					y1="338.688"
					x2="596.3627"
					y2="284.1512"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_3_)' }}
					d="M538.6,333.6c-0.6-1.7-2.3-2.9-4.2-2.9c-2,0-3.7,1.3-4.2,3.1c-7.1-0.7-13.9-3.6-19.4-8.3 c-0.6-0.5-1.5-0.4-2,0.1c-0.5,0.6-0.4,1.5,0.1,2c6,5.2,13.4,8.3,21.3,9c0.6,1.7,2.2,3,4.2,3c2,0,3.7-1.4,4.3-3.2 c18.2-2.6,32.2-18.2,32.2-37c0-20.6-16.8-37.4-37.4-37.4c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c19.1,0,34.6,15.5,34.6,34.6 C568.1,316.7,555.3,331.1,538.6,333.6z"
				/>
				{/* Middle Ring */}
				<linearGradient
					id="SVGID_5_"
					gradientUnits="userSpaceOnUse"
					x1="392.5578"
					y1="341.7598"
					x2="597.1814"
					y2="287.2231"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_5_)' }}
					d="M582.6,293.1c0-2.2-1.7-4.1-3.8-4.4c-3.3-14.3-13.3-26.4-27.2-32.3c-0.7-0.3-1.5,0-1.8,0.7 c-0.3,0.7,0,1.5,0.7,1.8c13,5.5,22.4,16.8,25.5,30.2c-1.4,0.8-2.4,2.2-2.4,3.9c0,2.1,1.5,3.9,3.5,4.4c0,0.6,0,1.2,0,1.8 c0,24.1-19.6,43.7-43.7,43.7c-4.9,0-9.7-0.8-14.3-2.4c-0.7-0.2-1.5,0.1-1.8,0.9c-0.2,0.7,0.1,1.5,0.9,1.8 c4.9,1.7,10,2.5,15.2,2.5c25.6,0,46.5-20.8,46.5-46.5c0-0.7,0-1.4-0.1-2.1C581.5,296.5,582.6,295,582.6,293.1z"
				/>
				{/* Outer Ring */}
				<linearGradient
					id="SVGID_4_"
					gradientUnits="userSpaceOnUse"
					x1="391.0825"
					y1="336.2242"
					x2="595.7061"
					y2="281.6874"
				>
					<stop offset="0" style={{ stopColor: '#00974A' }} />
					<stop offset="0.1309" style={{ stopColor: '#039A58' }} />
					<stop offset="0.3763" style={{ stopColor: '#09A27B' }} />
					<stop offset="0.7067" style={{ stopColor: '#14AFB5' }} />
					<stop offset="1" style={{ stopColor: '#1FBBED' }} />
				</linearGradient>
				<path
					style={{ fill: 'url(#SVGID_4_)' }}
					d="M533.5,244.5c-6.9,0-13.6,1.3-19.9,3.7c-0.8-0.9-2-1.4-3.2-1.4c-2.5,0-4.5,2-4.5,4.5c0,0.2,0,0.4,0.1,0.6 c-5.7,3.3-10.8,7.6-15,12.8c-0.5,0.6-0.4,1.5,0.2,1.9c0.6,0.5,1.5,0.4,1.9-0.2c4-4.9,8.8-8.9,14.1-12.1c0.8,0.8,1.9,1.4,3.2,1.4 c2.5,0,4.5-2,4.5-4.5c0-0.2,0-0.4-0.1-0.6c5.9-2.3,12.2-3.5,18.7-3.5c28.7,0,52,23.3,52,52s-23.3,52-52,52 c-11.8,0-22.8-3.8-32.1-11.1c-0.6-0.5-1.5-0.4-1.9,0.2c-0.5,0.6-0.4,1.5,0.2,1.9c9.7,7.6,21.4,11.7,33.8,11.7 c30.2,0,54.8-24.6,54.8-54.8S563.7,244.5,533.5,244.5z"
				/>
			</g>
			{/* Powered By Beep */}
			<g>
				{/* Powered */}
				<g>
					<path
						fill="#7E7E7E"
						d="M268.5,340.2h-1.4v-9.3h3c2.4,0,3.2,1.4,3.2,2.9c0,1.5-0.8,2.9-3.2,2.9h-1.6V340.2z M268.5,335.4h1.7 c1.4,0,1.7-0.9,1.7-1.6s-0.3-1.6-1.7-1.6h-1.7V335.4z"
					/>
					<path
						fill="#7E7E7E"
						d="M279.8,335.5c0-2.6,0.6-4.8,3.6-4.8s3.6,2.2,3.6,4.8s-0.6,4.8-3.6,4.8S279.8,338.2,279.8,335.5z M281.2,335.5c0,2.2,0.4,3.5,2.2,3.5s2.2-1.3,2.2-3.5c0-2.2-0.4-3.5-2.2-3.5S281.2,333.3,281.2,335.5z"
					/>
					<path
						fill="#7E7E7E"
						d="M297.9,330.9h1.7l1.6,7.4l1.2-7.4h1.5l-1.8,9.5h-1.7l-1.7-7.8l-1.7,7.8h-1.7l-1.8-9.5h1.5l1.2,7.4 L297.9,330.9z"
					/>
					<path fill="#7E7E7E" d="M315.9,336H312v2.9h4.3v1.3h-5.7v-9.3h5.7v1.3H312v2.6h3.9V336z" />
					<path
						fill="#7E7E7E"
						d="M324.7,340.2h-1.4v-9.3h3c2.4,0,3.2,1.1,3.2,2.8c0,1.2-0.4,2.1-1.5,2.5l2,4.1h-1.6l-1.7-3.8h-1.9V340.2z M324.7,335.1h1.8c1.3,0,1.5-0.7,1.5-1.5c0-0.8-0.2-1.5-1.5-1.5h-1.8V335.1z"
					/>
					<path fill="#7E7E7E" d="M341.9,336H338v2.9h4.3v1.3h-5.7v-9.3h5.7v1.3H338v2.6h3.9V336z" />
					<path
						fill="#7E7E7E"
						d="M349.2,340.2v-9.3h2.8c3.1,0,4,2,4,4.7c0,2.7-0.9,4.7-4,4.7H349.2z M350.6,338.9h1.4c1.9,0,2.5-1.4,2.5-3.4 c0-2-0.6-3.4-2.5-3.4h-1.4V338.9z"
					/>
				</g>
				{/* By */}
				<g>
					<path
						fill="#7E7E7E"
						d="M371,330.9h3.1c2.3,0,3,1.1,3,2.6c0,0.8-0.4,1.5-1.1,1.9c0.9,0.3,1.4,1.1,1.4,2.2c0,1.5-0.8,2.7-3.2,2.7 H371V330.9z M374.2,332.1h-1.8v2.6h1.8c1.1,0,1.4-0.7,1.4-1.3C375.6,332.9,375.4,332.1,374.2,332.1z M372.4,338.9h1.9 c1.3,0,1.6-0.7,1.6-1.5c0-1-0.4-1.5-1.6-1.5h-1.9V338.9z"
					/>
					<path
						fill="#7E7E7E"
						d="M388.1,336.5v3.7h-1.4v-3.7l-3.2-5.6h1.6l2.3,4.4l2.3-4.4h1.6L388.1,336.5z"
					/>
				</g>
				{/* Beep */}
				<g>
					<path
						fill="#7E7E7E"
						d="M405.8,330.9h3.1c2.3,0,3,1.1,3,2.6c0,0.8-0.4,1.5-1.1,1.9c0.9,0.3,1.4,1.1,1.4,2.2c0,1.5-0.8,2.7-3.2,2.7 h-3.2V330.9z M408.9,332.1h-1.8v2.6h1.8c1.1,0,1.4-0.7,1.4-1.3C410.4,332.9,410.2,332.1,408.9,332.1z M407.2,338.9h1.9 c1.3,0,1.6-0.7,1.6-1.5c0-1-0.4-1.5-1.6-1.5h-1.9V338.9z"
					/>
					<path
						fill="#7E7E7E"
						d="M424.2,336h-3.9v2.9h4.3v1.3h-5.7v-9.3h5.7v1.3h-4.3v2.6h3.9V336z"
					/>
					<path fill="#7E7E7E" d="M436.9,336H433v2.9h4.3v1.3h-5.7v-9.3h5.7v1.3H433v2.6h3.9V336z" />
					<path
						fill="#7E7E7E"
						d="M445.6,340.2h-1.4v-9.3h3c2.4,0,3.2,1.4,3.2,2.9c0,1.5-0.8,2.9-3.2,2.9h-1.6V340.2z M445.6,335.4h1.7 c1.4,0,1.7-0.9,1.7-1.6s-0.3-1.6-1.7-1.6h-1.7V335.4z"
					/>
				</g>
			</g>
		</svg>
	)
}

const MemoisedVoltalityLogoIcon = memo(VoltalityLogoIcon)

export default MemoisedVoltalityLogoIcon
