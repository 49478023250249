import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { AuthContext } from 'src/_shared/hooks/useAuthContext'
import { useLocalStorageItem, useSessionStorageItem } from 'src/_shared/hooks/useStorageItem'
import { useUserTokenRefreshMutation } from 'src/_shared/mutations/auth'
import { useUserInfoQuery } from 'src/_shared/queries/user'

const ACCESS_TOKEN_KEY = 'accessToken'

const REFRESH_TOKEN_KEY = 'refreshToken'

/**
 * Manages the storage and renewal of the `accessToken` and `refreshToken`.
 * This wrapper is only used in `default` and `sso` App Modes.
 */
const AuthWrapper = ({ children }: PropsWithChildren): JSX.Element | null => {
	const [localStorageAccessToken, setLocalStorageAccessToken] =
		useLocalStorageItem(ACCESS_TOKEN_KEY)

	const [localStorageRefreshToken, setLocalStorageRefreshToken] =
		useLocalStorageItem(REFRESH_TOKEN_KEY)

	const [sessionStorageAccessToken, setSessionStorageAccessToken] =
		useSessionStorageItem(ACCESS_TOKEN_KEY)

	const [sessionStorageRefreshToken, setSessionStorageRefreshToken] =
		useSessionStorageItem(REFRESH_TOKEN_KEY)

	const queryClient = useQueryClient()

	/**
	 * Access and Refresh Tokens stored in either `localStorage` or `sessionStorage`.
	 */
	const tokens = useMemo((): {
		storageType?: 'local' | 'session'
		accessToken: string | null
		refreshToken: string | null
	} => {
		if (!!localStorageAccessToken && !!localStorageRefreshToken) {
			return {
				storageType: 'local',
				accessToken: localStorageAccessToken,
				refreshToken: localStorageRefreshToken
			}
		} else if (!!sessionStorageAccessToken && !!sessionStorageRefreshToken) {
			return {
				storageType: 'session',
				accessToken: sessionStorageAccessToken,
				refreshToken: sessionStorageRefreshToken
			}
		}
		return { accessToken: null, refreshToken: null }
	}, [
		localStorageAccessToken,
		localStorageRefreshToken,
		sessionStorageAccessToken,
		sessionStorageRefreshToken
	])

	const clearAuthTokens = useCallback((): void => {
		// Clear `localStorage`
		setLocalStorageAccessToken(null)
		setLocalStorageRefreshToken(null)
		// Clear `sessionStorage`
		setSessionStorageAccessToken(null)
		setSessionStorageRefreshToken(null)
	}, [
		setLocalStorageAccessToken,
		setLocalStorageRefreshToken,
		setSessionStorageAccessToken,
		setSessionStorageRefreshToken
	])

	const { mutateAsync: refresh } = useUserTokenRefreshMutation({
		onError: (): void => {
			clearAuthTokens()
		},
		retry: false
	})

	const setAuthTokens = useCallback(
		(
			newAccessToken: string,
			newRefreshToken: string,
			storageType: 'local' | 'session' = 'session'
		): void => {
			if (storageType === 'local') {
				setLocalStorageAccessToken(newAccessToken)
				setLocalStorageRefreshToken(newRefreshToken)
			} else {
				setSessionStorageAccessToken(newAccessToken)
				setSessionStorageRefreshToken(newRefreshToken)
			}
		},
		[
			setLocalStorageAccessToken,
			setLocalStorageRefreshToken,
			setSessionStorageAccessToken,
			setSessionStorageRefreshToken
		]
	)

	/**
	 * Attempts to refresh expired `accessToken` with the stored `refreshToken`.
	 * @returns {Promise<boolean>} If `true`, then the refresh was successful and the
	 * new `accessToken` has been stored.
	 */
	const refreshAuthTokens = useCallback(async (): Promise<boolean> => {
		if (tokens.refreshToken) {
			const response = await refresh({ refreshToken: tokens.refreshToken })
			if (response.data.token) {
				setAuthTokens(response.data.token, tokens.refreshToken, tokens.storageType)
				return true
			}
		}
		return false
	}, [tokens, refresh, setAuthTokens])

	const handleRetryOnError = useCallback(
		(failureLimit = 3) =>
			(failureCount: number, error: Error): boolean => {
				const axiosError = error as AxiosError<{ message: string }>
				switch (axiosError.response?.status) {
					// Unauthorised; Attempt to refresh token.
					case 401: {
						void refreshAuthTokens()
						return false
					}
					// Retry depending on configured `failureLimit`.
					default:
						return failureCount < failureLimit
				}
			},
		[refreshAuthTokens]
	)

	const { data: user = null } = useUserInfoQuery(
		{ accessToken: tokens.accessToken ?? '' },
		{
			enabled: !!tokens.accessToken && !!tokens.refreshToken,
			staleTime: Infinity,
			retry: handleRetryOnError()
		}
	)

	/**
	 * Add default auth error retry handler to all mutations and queries.
	 */
	useEffect((): void => {
		const { mutations: mutationsOptions, queries: queriesOptions } = queryClient.getDefaultOptions()
		queryClient.setDefaultOptions({
			mutations: {
				...mutationsOptions,
				retry: tokens.refreshToken ? handleRetryOnError(0) : undefined
			},
			queries: {
				...queriesOptions,
				retry: tokens.refreshToken ? handleRetryOnError() : undefined
			}
		})
	}, [queryClient, tokens.refreshToken, handleRetryOnError])

	return (
		<AuthContext.Provider
			value={{
				accessToken: tokens.accessToken,
				isAuthenticated: !!tokens.accessToken,
				user,
				clearAuthTokens,
				setAuthTokens
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthWrapper
