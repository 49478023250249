import { useMemo } from 'react'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { STRAPI_URL } from 'src/_shared/constants/env'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

const SplashScreenView = (): JSX.Element => {
	const { brandData } = useStrapiContext()

	const { logoDark } = brandData?.attributes ?? {}

	const logoUrl = useMemo((): string | null => {
		if (STRAPI_URL) {
			return STRAPI_URL + logoDark?.data?.attributes.url
		}
		return null
	}, [logoDark])

	return (
		<ScreenContainer
			contentViewProps={{ className: 'items-center justify-center' }}
			hideTopBar
			hideBottomBar
		>
			{logoUrl ? (
				<img
					src={logoUrl}
					alt={logoDark?.data?.attributes.alternativeText ?? ''}
					className="h-7 w-auto"
				/>
			) : (
				<Spinner />
			)}
		</ScreenContainer>
	)
}

export default SplashScreenView
