import { CSSProperties, memo, ReactNode } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface TagProps {
	value: ReactNode
	/**
	 * Additional classes to be added to `Tag`.
	 */
	className?: string
	/**
	 * Default styling for `Tag` can be overriden with the `style` prop.
	 */
	style?: CSSProperties
}

const Tag = ({ value, className = '', style }: TagProps) => {
	return (
		<span
			className={classNames(
				'caption-2-medium rounded-xl bg-black px-1.5 py-0.5 text-white',
				className
			)}
			style={style}
		>
			{value}
		</span>
	)
}

const MemoisedTag = memo(Tag)

export default MemoisedTag
