import { OmniDayOfWeek } from 'src/_shared/enums/omni'

import chargingAnimationData from './chargingAnimationData.json'
import spinnerAnimationData from './spinnerAnimationData.json'

/**
 * 24 Hour Clock Format. This is used to convert the returned blackout times
 * into `TIME_FORMAT`. This should not be used to display the time.
 */
export const BLACKOUT_PERIOD_TIME_FORMAT = 'HH:mm'

export const DAY_NUMBER_TO_STRING: Record<number, OmniDayOfWeek> = {
	[1]: OmniDayOfWeek.Monday,
	[2]: OmniDayOfWeek.Tuesday,
	[3]: OmniDayOfWeek.Wednesday,
	[4]: OmniDayOfWeek.Thursday,
	[5]: OmniDayOfWeek.Friday,
	[6]: OmniDayOfWeek.Saturday,
	[7]: OmniDayOfWeek.Sunday
}

export const LOTTIE_CHARGING_ANIMATION_DATA = chargingAnimationData

export const LOTTIE_SPINNER_ANIMATION_DATA = spinnerAnimationData
