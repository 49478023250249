import { SVGProps, memo } from 'react'

const SearchIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 24 24',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

const MemoisedSearchIcon = memo(SearchIcon)

export default MemoisedSearchIcon
