function TinyClock({ className }: { className: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 15 15" fill="none">
			<g opacity="0.5">
				<path
					opacity="0.4"
					d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z"
					fill="#292D32"
				/>
				<path
					d="M9.81877 9.95635C9.73752 9.95635 9.65627 9.9376 9.58127 9.8876L7.64377 8.73135C7.16252 8.44385 6.80627 7.8126 6.80627 7.25635V4.69385C6.80627 4.4376 7.01877 4.2251 7.27502 4.2251C7.53127 4.2251 7.74377 4.4376 7.74377 4.69385V7.25635C7.74377 7.48135 7.93127 7.8126 8.12502 7.9251L10.0625 9.08135C10.2875 9.2126 10.3563 9.5001 10.225 9.7251C10.1313 9.8751 9.97502 9.95635 9.81877 9.95635Z"
					fill="#292D32"
				/>
			</g>
		</svg>
	)
}

export default TinyClock
