import { DetailedHTMLProps, ImgHTMLAttributes, memo } from 'react'

import chargerTimeoutImage from './chargerTimeoutImage.png'

const ChargerTimeoutImage = ({
	alt = 'Charger Timeout',
	...props
}: Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src'
>): JSX.Element => {
	return <img alt={alt} src={chargerTimeoutImage} {...props} />
}

const MemoisedChargerTimeoutImage = memo(ChargerTimeoutImage)

export default MemoisedChargerTimeoutImage
