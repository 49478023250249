import { SVGProps, memo } from 'react'

const CheckCircleGradientIcon = ({
	fill = 'none',
	stroke = 'none',
	viewBox = '0 0 117 117',
	xmlns = 'http://www.w3.org/2000/svg',
	gradientFrom = '#FFFFFF',
	gradientTo = '#000000',
	...props
}: SVGProps<SVGSVGElement> & {
	gradientFrom?: string
	gradientTo?: string
}) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<circle
				cx="58.5"
				cy="58.5"
				r="55.5"
				fill="transparent"
				stroke="url(#circleGradient)"
				strokeWidth="6"
			/>
			<path
				d="M58 10.5C31.8275 10.5 10.5 31.8275 10.5 58C10.5 84.1725 31.8275 105.5 58 105.5C84.1725 105.5 105.5 84.1725 105.5 58C105.5 31.8275 84.1725 10.5 58 10.5ZM80.705 47.075L53.7725 74.0075C53.1075 74.6725 52.205 75.0525 51.255 75.0525C50.305 75.0525 49.4025 74.6725 48.7375 74.0075L35.295 60.565C33.9175 59.1875 33.9175 56.9075 35.295 55.53C36.6725 54.1525 38.9525 54.1525 40.33 55.53L51.255 66.455L75.67 42.04C77.0475 40.6625 79.3275 40.6625 80.705 42.04C82.0825 43.4175 82.0825 45.65 80.705 47.075Z"
				fill="url(#pathGradient)"
			/>
			<defs>
				<linearGradient
					id="circleGradient"
					x1="-119.516"
					y1="58.5"
					x2="117"
					y2="58.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
				<linearGradient
					id="pathGradient"
					x1="-86.543"
					y1="58"
					x2="105.5"
					y2="58"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={gradientFrom} />
					<stop offset="1" stopColor={gradientTo} />
				</linearGradient>
			</defs>
		</svg>
	)
}

const MemoisedCheckCircleGradientIcon = memo(CheckCircleGradientIcon)

export default MemoisedCheckCircleGradientIcon
