import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import VoltalityLogoIcon from 'src/_shared/components/_icons/VoltalityLogoIcon'
import PlugitLogoImage from 'src/_shared/components/_images/PlugitLogoImage'

interface TransientFooterProps {
	className?: string
}

const TransientFooter = ({ className }: TransientFooterProps): JSX.Element => {
	return (
		<div className={className}>
			<div className="mb-1">
				<p className="caption-3-normal text-center text-typography-secondary">
					<FormattedMessage id="TransientFooter.FooterTextPoweredBy" defaultMessage="Powered By" />
				</p>
			</div>
			<div className="flex flex-row items-center justify-center space-x-5">
				<div className="flex w-24 justify-end">
					<PlugitLogoImage className="h-7" />
				</div>
				<div className="mt-1 h-7 border-r border-primary-400/30" />
				<div className="flex w-24 justify-start">
					<VoltalityLogoIcon className="mt-1 h-8" />
				</div>
			</div>
		</div>
	)
}

const MemoisedTransientFooter = memo(TransientFooter)

export default MemoisedTransientFooter
