export enum OmniConnectorFormat {
	Cable = 'CABLE',
	Socket = 'SOCKET'
}

export enum OmniConnectorPowerType {
	AcOnePhase = 'AC_1_PHASE',
	AcTwoPhase = 'AC_2_PHASE',
	AcTwoPhaseSplit = 'AC_2_PHASE_SPLIT',
	AcThreePhase = 'AC_3_PHASE',
	Dc = 'DC'
}

export enum OmniConnectorPrivilegeStatus {
	Private = 'PRIVATE',
	Privileged = 'PRIVILEGED',
	Public = 'PUBLIC'
}

export enum OmniConnectorStatus {
	Available = 'AVAILABLE',
	Blocked = 'BLOCKED',
	Charging = 'CHARGING',
	Finishing = 'FINISHING',
	Inoperative = 'INOPERATIVE',
	OutOfOrder = 'OUTOFORDER',
	Planned = 'PLANNED',
	Preparing = 'PREPARING',
	Removed = 'REMOVED',
	Reserved = 'RESERVED',
	Unknown = 'UNKNOWN'
}
