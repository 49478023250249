interface P {
	onClick?: () => void
}

export default function CarparkRates(props: P) {
	const { onClick } = props

	return (
		<>
			<button
				className="flex flex-row items-center justify-center space-x-2"
				onClick={() => {
					onClick?.()
				}}
			>
				<div className="flex h-5 w-5 items-center justify-center rounded-md bg-primary-800">
					<p className="text-sm font-bold text-white">P</p>
				</div>
				<div className="flex items-center justify-center border-b-[0.5px] border-primary-800">
					<p className="text-sm text-primary-800">Car Park Rates</p>
				</div>
			</button>
		</>
	)
}
