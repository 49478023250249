import { SVGProps, memo } from 'react'

const TelephoneIcon = ({
	fill = 'none',
	stroke = 'currentColor',
	viewBox = '0 0 23 23',
	xmlns = 'http://www.w3.org/2000/svg',
	...props
}: SVGProps<SVGSVGElement>) => {
	return (
		<svg fill={fill} stroke={stroke} viewBox={viewBox} xmlns={xmlns} {...props}>
			<path
				d="M21.0546 17.5663C21.0546 17.9113 20.9779 18.2659 20.815 18.6109C20.6521 18.9559 20.4413 19.2817 20.1634 19.5884C19.6938 20.1059 19.1763 20.4797 18.5917 20.7192C18.0167 20.9588 17.3938 21.0834 16.7229 21.0834C15.7454 21.0834 14.7009 20.8534 13.5988 20.3838C12.4967 19.9142 11.3946 19.2817 10.3021 18.4863C9.20002 17.6813 8.15544 16.7901 7.15877 15.803C6.17169 14.8063 5.28044 13.7617 4.48502 12.6692C3.69919 11.5767 3.06669 10.4842 2.60669 9.40133C2.14669 8.30883 1.91669 7.26425 1.91669 6.26758C1.91669 5.61591 2.03169 4.993 2.26169 4.418C2.49169 3.83341 2.85585 3.29675 3.36377 2.81758C3.9771 2.21383 4.64794 1.91675 5.3571 1.91675C5.62544 1.91675 5.89377 1.97425 6.13335 2.08925C6.38252 2.20425 6.60294 2.37675 6.77544 2.62591L8.99877 5.75967C9.17127 5.99925 9.29585 6.21966 9.3821 6.4305C9.46835 6.63175 9.51627 6.833 9.51627 7.01508C9.51627 7.24508 9.44919 7.47508 9.31502 7.6955C9.19044 7.91591 9.00835 8.14591 8.77835 8.37591L8.05002 9.133C7.9446 9.23841 7.89669 9.363 7.89669 9.51633C7.89669 9.593 7.90627 9.66008 7.92544 9.73675C7.95419 9.81341 7.98294 9.87091 8.0021 9.92841C8.1746 10.2447 8.47169 10.6567 8.89335 11.1551C9.3246 11.6534 9.7846 12.1613 10.2829 12.6692C10.8004 13.1772 11.2988 13.6467 11.8067 14.078C12.305 14.4997 12.7171 14.7872 13.0429 14.9597C13.0909 14.9788 13.1484 15.0076 13.2154 15.0363C13.2921 15.0651 13.3688 15.0747 13.455 15.0747C13.6179 15.0747 13.7425 15.0172 13.8479 14.9117L14.5763 14.193C14.8159 13.9534 15.0459 13.7713 15.2663 13.6563C15.4867 13.5222 15.7071 13.4551 15.9467 13.4551C16.1288 13.4551 16.3204 13.4934 16.5313 13.5797C16.7421 13.6659 16.9625 13.7905 17.2021 13.9534L20.3742 16.2055C20.6234 16.378 20.7959 16.5792 20.9013 16.8188C20.9971 17.0584 21.0546 17.298 21.0546 17.5663Z"
				strokeWidth="1.5"
				strokeMiterlimit="10"
			/>
		</svg>
	)
}

const MemoisedTelephoneIcon = memo(TelephoneIcon)

export default MemoisedTelephoneIcon
