import dayjs from 'dayjs'
import { OmniSession } from 'src/_shared/types/omni'

import { FormattedSession } from './types'

/**
 * Business Decision: For LOW_TO_HIGH and HIGH_TO_LOW sort functions, there isn't any real benefit
 * to the user without the headers at the top.
 * This function would be useful in the future as the individual headers can act as the keys
 * (whichever that we set it to be).
 */
const splitSessionsIntoGroups = (
	formattedChargingSessions: OmniSession[]
	// sortOption: SortOptions
): Record<string, OmniSession[]> => {
	return formattedChargingSessions.reduce(
		(accumulatorMapping: Record<string, OmniSession[]>, formattedSession: OmniSession) => {
			// key of each group would either be 'start_date_time' or 'session_uid'
			const key = dayjs(formattedSession.start_date_time).format('ddd MMM DD YYYY')
			if (key in accumulatorMapping) {
				accumulatorMapping[key] = [...accumulatorMapping[key], formattedSession]
			} else {
				accumulatorMapping[key] = [formattedSession]
			}
			return accumulatorMapping
		},
		{}
	)
}

const convertRecordsWithKeysToArray = (
	records: Record<string, OmniSession[]>
): FormattedSession[] => {
	const sessionsArrayWithKeys: FormattedSession[] = Object.entries(records).map(
		([key, sessions]) => ({
			key,
			sessions
		})
	)
	return sessionsArrayWithKeys
}

export const sessionFormatter = (formattedChargingSessions: OmniSession[]): FormattedSession[] => {
	// Split Sessions into the Key Value Records
	const grouppedSessions: Record<string, OmniSession[]> =
		splitSessionsIntoGroups(formattedChargingSessions)
	// Convert this Record into an array
	const grouppedSessionsArr: FormattedSession[] = convertRecordsWithKeysToArray(grouppedSessions)

	return grouppedSessionsArr
}
