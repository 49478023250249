import { OmniLocation } from 'src/_shared/_old/schemas/typings'

export const shareLocationUrl = (location: OmniLocation): void => {
	const lat = parseFloat(location.coordinates.latitude)
	const lng = parseFloat(location.coordinates.longitude)

	const baseUrl = `https://www.google.com/maps/dir/?api=1&`
	// lat long for directions seems to be more reliable
	// than address as some CPOs don't have proper addresses for their locations
	const url = `${baseUrl}destination=${lat},${lng}`

	window.open(url, '_blank')
}
