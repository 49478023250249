import { DetailedHTMLProps, SelectHTMLAttributes, memo, useMemo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

import ChevronDownIcon from '../_icons/ChevronDownIcon'

export interface SelectOption<T> {
	label: string
	dropdownLabel?: string
	value: T
}

export type SelectProps<T> = {
	options: SelectOption<T>[]
} & Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'children'>

const Select = <T extends number | string>({
	value,
	options,
	disabled,
	...selectProps
}: SelectProps<T>): JSX.Element => {
	const selectedOption = useMemo((): SelectOption<T> | null => {
		const option = options.find((option): boolean => {
			return option.value === value
		})
		return option ?? null
	}, [options, value])

	return (
		<div className="relative">
			{/* Hidden Select */}
			{/* This will get clicked on and show the default select dropdown. */}
			<select
				{...selectProps}
				className={classNames(
					// Base Classes
					'absolute z-10 h-full w-full opacity-0',
					// `disabled` Classes
					disabled ? 'cursor-not-allowed' : 'cursor-pointer'
				)}
				value={selectedOption?.value}
				disabled={disabled}
			>
				{options.map(({ dropdownLabel, label, value }, index): JSX.Element => {
					return <option key={index} label={dropdownLabel ?? label} value={value} />
				})}
			</select>
			{/* Select Display */}
			<div
				className={classNames(
					// Base Classes
					'flex flex-row items-center space-x-0.5',
					selectProps.className
				)}
			>
				<p
					className={classNames(
						// Base Classes
						'text-nowrap',
						// `disabled` Classes
						disabled ? 'text-typography-tertiary' : 'text-typography-primary'
					)}
				>
					{selectedOption?.label}
				</p>
				<ChevronDownIcon className="h-5 w-5 text-typography-secondary" />
			</div>
		</div>
	)
}

const MemoisedSelect = memo(Select)

export default MemoisedSelect
