export enum ChargerScreenViewItemKey {
	ChargingView = 'ChargingView',
	ErrorView = 'ErrorView',
	PaymentMethodsView = 'PaymentMethodsView',
	PreChargingView = 'PreChargingView',
	PostChargingView = 'PostChargingView',
	ReceiptView = 'ReceiptView',
	TransientEmailView = 'TransientEmailView',
	TransientPaymentMethodsView = 'TransientPaymentMethodsView'
}
