import { ReactNode, memo } from 'react'
import Skeleton from 'src/_shared/components/Skeleton'
import { classNames } from 'src/_shared/utils/elements'

export interface DetailsRowItem {
	label: ReactNode
	value: ReactNode
}

interface DetailsRowProps {
	className?: string
	/**
	 * Ideally `itemList.length === 3`.
	 */
	itemList: DetailsRowItem[]
	loading?: boolean
}

const GRID_COLS = 3

const DetailsRow = ({ className, itemList, loading }: DetailsRowProps): JSX.Element => {
	return (
		<div className={classNames('grid w-full p-2.5', `grid-cols-${GRID_COLS}`, className)}>
			{itemList.map(({ label, value }, index): JSX.Element => {
				const hasRightBorder =
					itemList.length > 1 && (index + 1) % Math.min(itemList.length, GRID_COLS) > 0
				return (
					<div
						key={index}
						className={classNames(hasRightBorder ? 'border-r border-primary-400/30' : null)}
					>
						<Skeleton loading={loading}>
							<div className="flex flex-col pl-2.5">
								<div className="mb-0.5 text-typography-secondary/60">
									<p className="caption-3-normal truncate">{label}</p>
								</div>
								<div>
									<p className="body-2-medium truncate text-typography-primary">{value}</p>
								</div>
							</div>
						</Skeleton>
					</div>
				)
			})}
		</div>
	)
}

const MemoisedDetailsRow = memo(DetailsRow)

export default MemoisedDetailsRow
