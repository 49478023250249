import { OmniCountryCode, OmniCountryCodeUnsupported } from '../enums/omni'

export const COUNTRIES: {
	name: string
	isoCode: OmniCountryCode | OmniCountryCodeUnsupported
	countryCode: string
	emoji: string
}[] = [
	{
		name: 'Singapore',
		isoCode: OmniCountryCode.Singapore,
		countryCode: '+65',
		emoji: '🇸🇬'
	},
	{
		name: 'Thailand',
		isoCode: OmniCountryCode.Thailand,
		countryCode: '+66',
		emoji: '🇹🇭'
	},
	{
		name: 'Malaysia',
		isoCode: OmniCountryCode.Malaysia,
		countryCode: '+60',
		emoji: '🇲🇾'
	},
	{
		name: 'Hong Kong',
		isoCode: OmniCountryCodeUnsupported.HongKong,
		countryCode: '+852',
		emoji: '🇭🇰'
	},
	{
		name: 'Philippines',
		isoCode: OmniCountryCodeUnsupported.Philippines,
		countryCode: '+63',
		emoji: '🇵🇭'
	}
]
